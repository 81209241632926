/* tslint:disable */
import {
  StreetAddress
} from '../index';

declare var Object: any;
export interface ContactInfoInterface {
  "id"?: number;
  "contactdetailsType"?: string;
  "contactdetailsId": number;
  "line1"?: string;
  "line2"?: string;
  "city"?: string;
  "state"?: string;
  "zip"?: string;
  "email"?: string;
  "phone"?: string;
  "fax"?: string;
  "active"?: boolean;
  contactdetails?: any;
  addressdetails?: StreetAddress[];
}

export class ContactInfo implements ContactInfoInterface {
  "id": number;
  "contactdetailsType": string;
  "contactdetailsId": number;
  "line1": string;
  "line2": string;
  "city": string;
  "state": string;
  "zip": string;
  "email": string;
  "phone": string;
  "fax": string;
  "active": boolean;
  contactdetails: any;
  addressdetails: StreetAddress[];
  constructor(data?: ContactInfoInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ContactInfo`.
   */
  public static getModelName() {
    return "ContactInfo";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ContactInfo for dynamic purposes.
  **/
  public static factory(data: ContactInfoInterface): ContactInfo{
    return new ContactInfo(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ContactInfo',
      plural: 'ContactInfos',
      path: 'ContactInfos',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'number'
        },
        "contactdetailsType": {
          name: 'contactdetailsType',
          type: 'string'
        },
        "contactdetailsId": {
          name: 'contactdetailsId',
          type: 'number'
        },
        "line1": {
          name: 'line1',
          type: 'string'
        },
        "line2": {
          name: 'line2',
          type: 'string'
        },
        "city": {
          name: 'city',
          type: 'string'
        },
        "state": {
          name: 'state',
          type: 'string'
        },
        "zip": {
          name: 'zip',
          type: 'string'
        },
        "email": {
          name: 'email',
          type: 'string'
        },
        "phone": {
          name: 'phone',
          type: 'string'
        },
        "fax": {
          name: 'fax',
          type: 'string'
        },
        "active": {
          name: 'active',
          type: 'boolean',
          default: true
        },
      },
      relations: {
        contactdetails: {
          name: 'contactdetails',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
                  keyFrom: 'contactdetailsId',
          keyTo: 'id'
        },
        addressdetails: {
          name: 'addressdetails',
          type: 'StreetAddress[]',
          model: 'StreetAddress',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'addressparentdetailsId'
        },
      }
    }
  }
}
