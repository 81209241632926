/* tslint:disable */
import {
  Parcel,
  Office,
  ContactInfo,
  Company,
  Invoice,
  Payment,
  Operation,
  Service,
  Flag,
  CoordinatePoint,
  Client,
  Employee
} from '../index';

declare var Object: any;
export interface JobInterface {
  "mask"?: string;
  "addedbyuser": number;
  "clientid": number;
  "companyid"?: number;
  "billinginfoid": number;
  "contactid": number;
  "due"?: Date;
  "completed"?: Date;
  "jobyear"?: number;
  "fieldworkcomplete"?: Date;
  "closed"?: boolean;
  "hasbeeninvoiced"?: boolean;
  "numberofinvoices"?: number;
  "invoiceIds"?: Array<any>;
  "managerIds"?: Array<any>;
  "comments"?: string;
  "paymentposted": boolean;
  "managingoffice": number;
  "parcelIds"?: Array<any>;
  "paymentIds"?: Array<any>;
  "id"?: number;
  "created"?: Date;
  "updated"?: Date;
  parcels?: Parcel[];
  office?: Office;
  contactinfo?: ContactInfo;
  billinginfo?: ContactInfo;
  company?: Company;
  invoices?: Invoice[];
  payments?: Payment[];
  operations?: Operation[];
  services?: Service[];
  flags?: Flag[];
  coordinatedetails?: CoordinatePoint[];
  client?: Client;
  managers?: Employee[];
}

export class Job implements JobInterface {
  "mask": string;
  "addedbyuser": number;
  "clientid": number;
  "companyid": number;
  "billinginfoid": number;
  "contactid": number;
  "due": Date;
  "completed": Date;
  "jobyear": number;
  "fieldworkcomplete": Date;
  "closed": boolean;
  "hasbeeninvoiced": boolean;
  "numberofinvoices": number;
  "invoiceIds": Array<any>;
  "managerIds": Array<any>;
  "comments": string;
  "paymentposted": boolean;
  "managingoffice": number;
  "parcelIds": Array<any>;
  "paymentIds": Array<any>;
  "id": number;
  "created": Date;
  "updated": Date;
  parcels: Parcel[];
  office: Office;
  contactinfo: ContactInfo;
  billinginfo: ContactInfo;
  company: Company;
  invoices: Invoice[];
  payments: Payment[];
  operations: Operation[];
  services: Service[];
  flags: Flag[];
  coordinatedetails: CoordinatePoint[];
  client: Client;
  managers: Employee[];
  constructor(data?: JobInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Job`.
   */
  public static getModelName() {
    return "Job";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Job for dynamic purposes.
  **/
  public static factory(data: JobInterface): Job{
    return new Job(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Job',
      plural: 'Jobs',
      path: 'Jobs',
      idName: 'id',
      properties: {
        "mask": {
          name: 'mask',
          type: 'string',
          default: 'null'
        },
        "addedbyuser": {
          name: 'addedbyuser',
          type: 'number'
        },
        "clientid": {
          name: 'clientid',
          type: 'number'
        },
        "companyid": {
          name: 'companyid',
          type: 'number'
        },
        "billinginfoid": {
          name: 'billinginfoid',
          type: 'number'
        },
        "contactid": {
          name: 'contactid',
          type: 'number'
        },
        "due": {
          name: 'due',
          type: 'Date'
        },
        "completed": {
          name: 'completed',
          type: 'Date'
        },
        "jobyear": {
          name: 'jobyear',
          type: 'number'
        },
        "fieldworkcomplete": {
          name: 'fieldworkcomplete',
          type: 'Date'
        },
        "closed": {
          name: 'closed',
          type: 'boolean',
          default: false
        },
        "hasbeeninvoiced": {
          name: 'hasbeeninvoiced',
          type: 'boolean',
          default: false
        },
        "numberofinvoices": {
          name: 'numberofinvoices',
          type: 'number',
          default: 0
        },
        "invoiceIds": {
          name: 'invoiceIds',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "managerIds": {
          name: 'managerIds',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "comments": {
          name: 'comments',
          type: 'string'
        },
        "paymentposted": {
          name: 'paymentposted',
          type: 'boolean',
          default: false
        },
        "managingoffice": {
          name: 'managingoffice',
          type: 'number'
        },
        "parcelIds": {
          name: 'parcelIds',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "paymentIds": {
          name: 'paymentIds',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "created": {
          name: 'created',
          type: 'Date'
        },
        "updated": {
          name: 'updated',
          type: 'Date'
        },
      },
      relations: {
        parcels: {
          name: 'parcels',
          type: 'Parcel[]',
          model: 'Parcel',
          relationType: 'referencesMany',
                  keyFrom: 'parcelIds',
          keyTo: 'id'
        },
        office: {
          name: 'office',
          type: 'Office',
          model: 'Office',
          relationType: 'belongsTo',
                  keyFrom: 'managingoffice',
          keyTo: 'id'
        },
        contactinfo: {
          name: 'contactinfo',
          type: 'ContactInfo',
          model: 'ContactInfo',
          relationType: 'belongsTo',
                  keyFrom: 'contactid',
          keyTo: 'id'
        },
        billinginfo: {
          name: 'billinginfo',
          type: 'ContactInfo',
          model: 'ContactInfo',
          relationType: 'belongsTo',
                  keyFrom: 'billinginfoid',
          keyTo: 'id'
        },
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyid',
          keyTo: 'id'
        },
        invoices: {
          name: 'invoices',
          type: 'Invoice[]',
          model: 'Invoice',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'jobid'
        },
        payments: {
          name: 'payments',
          type: 'Payment[]',
          model: 'Payment',
          relationType: 'referencesMany',
                  keyFrom: 'paymentIds',
          keyTo: 'id'
        },
        operations: {
          name: 'operations',
          type: 'Operation[]',
          model: 'Operation',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'jobid'
        },
        services: {
          name: 'services',
          type: 'Service[]',
          model: 'Service',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'jobid'
        },
        flags: {
          name: 'flags',
          type: 'Flag[]',
          model: 'Flag',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'jobid'
        },
        coordinatedetails: {
          name: 'coordinatedetails',
          type: 'CoordinatePoint[]',
          model: 'CoordinatePoint',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'coordinateparentdetailsId'
        },
        client: {
          name: 'client',
          type: 'Client',
          model: 'Client',
          relationType: 'belongsTo',
                  keyFrom: 'clientid',
          keyTo: 'id'
        },
        managers: {
          name: 'managers',
          type: 'Employee[]',
          model: 'Employee',
          relationType: 'referencesMany',
                  keyFrom: 'managerIds',
          keyTo: 'id'
        },
      }
    }
  }
}
