/* tslint:disable */
import {
  Task
} from '../index';

declare var Object: any;
export interface ServiceInterface {
  "id"?: number;
  "taskid": number;
  "jobid": number;
  "customratetype"?: number;
  "iscustom"?: boolean;
  "customrate"?: number;
  "notes"?: string;
  "taxable"?: boolean;
  "orderNumber"?: number;
  taskdetails?: Task;
}

export class Service implements ServiceInterface {
  "id": number;
  "taskid": number;
  "jobid": number;
  "customratetype": number;
  "iscustom": boolean;
  "customrate": number;
  "notes": string;
  "taxable": boolean;
  "orderNumber": number;
  taskdetails: Task;
  constructor(data?: ServiceInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Service`.
   */
  public static getModelName() {
    return "Service";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Service for dynamic purposes.
  **/
  public static factory(data: ServiceInterface): Service{
    return new Service(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Service',
      plural: 'Services',
      path: 'Services',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'number'
        },
        "taskid": {
          name: 'taskid',
          type: 'number'
        },
        "jobid": {
          name: 'jobid',
          type: 'number'
        },
        "customratetype": {
          name: 'customratetype',
          type: 'number'
        },
        "iscustom": {
          name: 'iscustom',
          type: 'boolean',
          default: false
        },
        "customrate": {
          name: 'customrate',
          type: 'number'
        },
        "notes": {
          name: 'notes',
          type: 'string',
          default: 'null'
        },
        "taxable": {
          name: 'taxable',
          type: 'boolean',
          default: true
        },
        "orderNumber": {
          name: 'orderNumber',
          type: 'number'
        },
      },
      relations: {
        taskdetails: {
          name: 'taskdetails',
          type: 'Task',
          model: 'Task',
          relationType: 'hasOne',
                  keyFrom: 'taskid',
          keyTo: 'id'
        },
      }
    }
  }
}
