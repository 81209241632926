/* tslint:disable */
import {
  Position,
  Operation
} from '../index';

declare var Object: any;
export interface StageInterface {
  "id": number;
  "name": string;
  "desc"?: string;
  "operatorIds"?: Array<any>;
  "watcherIds"?: Array<any>;
  "stageid"?: number;
  operators?: Position[];
  watchers?: Position[];
  operations?: Operation;
}

export class Stage implements StageInterface {
  "id": number;
  "name": string;
  "desc": string;
  "operatorIds": Array<any>;
  "watcherIds": Array<any>;
  "stageid": number;
  operators: Position[];
  watchers: Position[];
  operations: Operation;
  constructor(data?: StageInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Stage`.
   */
  public static getModelName() {
    return "Stage";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Stage for dynamic purposes.
  **/
  public static factory(data: StageInterface): Stage{
    return new Stage(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Stage',
      plural: 'Stages',
      path: 'Stages',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'number'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "desc": {
          name: 'desc',
          type: 'string'
        },
        "operatorIds": {
          name: 'operatorIds',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "watcherIds": {
          name: 'watcherIds',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "stageid": {
          name: 'stageid',
          type: 'number'
        },
      },
      relations: {
        operators: {
          name: 'operators',
          type: 'Position[]',
          model: 'Position',
          relationType: 'referencesMany',
                  keyFrom: 'operatorIds',
          keyTo: 'id'
        },
        watchers: {
          name: 'watchers',
          type: 'Position[]',
          model: 'Position',
          relationType: 'referencesMany',
                  keyFrom: 'watcherIds',
          keyTo: 'id'
        },
        operations: {
          name: 'operations',
          type: 'Operation',
          model: 'Operation',
          relationType: 'belongsTo',
                  keyFrom: 'stageid',
          keyTo: 'id'
        },
      }
    }
  }
}
