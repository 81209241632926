import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { EmployeeApi, ClientApi } from '../shared/sdk/services/index';

import { DataServiceService } from '../services/data-service.service';
import { EnvService } from './env.service';

@Injectable({
  providedIn: 'root'
})
export class AuthguardService implements CanActivate, CanActivateChild {

  constructor(
    public employeeApi: EmployeeApi,
    public clientApi: ClientApi,
    public router: Router,
    public dataService: DataServiceService,
    public env: EnvService
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> | boolean {
    // console.log('authguard canActivate called');
    return this.checkUserAuth(next, state);
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> | boolean {
    // console.log('authguard canActivateChild called');
    return this.checkUserAuth(next, state);
  }

  private checkUserAuth(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return new Promise((resolve, reject) => {
      if (this.employeeApi.isAuthenticated() || this.clientApi.isAuthenticated()) {
        if (state.url.includes('verify')) {
          // User is trying to access the verify page, need to determine the next action
          resolve(this.verifyPageAccessed(state));
        } else if (this.dataService.user === null) {
          // console.log('from user refresh');
          if (this.env.HOST_NAME.includes('localhost') && this.env.NODE_ENV === 'development' && localStorage.getItem('$crdnl_dev_employee')) { // for local dev
              let body = JSON.parse(localStorage.getItem('$crdnl_dev_employee'));
            this.dataService.employeeLogin(body).toPromise().then(() => {
              this.router.navigateByUrl('/overview/user-gridview'); // always redirect to overview
              resolve(true);
            });
          } else {
            this.router.navigateByUrl('/redirect?destination=/login');
            resolve(false);
          }
        } else {
          resolve(true);
        }
      } else {
        if (state.url.includes('verify')) {
          // User is trying to access the verify page, need to determine the next action
          resolve(this.verifyPageAccessed(state));
        } else {
          // console.log('User failed authguard check, redirecting...');
          this.router.navigateByUrl('/redirect?destination=/login');
        }
        // return false;
        resolve(false);
      }
    });
  }

  private verifyPageAccessed(state: RouterStateSnapshot): boolean {
    switch (state.root.queryParams.a) {
      case 'reset-password':
        // console.log('User attempting to access reset-password');
        return true;
        break;
      default:
        return false;
        break;
    }
    return true;
  }
}
