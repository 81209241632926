/* tslint:disable */
import {
  Job,
  Invoice
} from '../index';

declare var Object: any;
export interface PaymentInterface {
  "paymentamount": number;
  "comment"?: string;
  "made"?: Date;
  "posted"?: Date;
  "clientid": number;
  "jobIds"?: Array<any>;
  "invoiceIds"?: Array<any>;
  "rolledback"?: boolean;
  "type"?: string;
  "stripechargeids"?: Array<any>;
  "id"?: number;
  "created"?: Date;
  "updatedAt"?: Date;
  jobs?: Job[];
  invoices?: Invoice[];
}

export class Payment implements PaymentInterface {
  "paymentamount": number;
  "comment": string;
  "made": Date;
  "posted": Date;
  "clientid": number;
  "jobIds": Array<any>;
  "invoiceIds": Array<any>;
  "rolledback": boolean;
  "type": string;
  "stripechargeids": Array<any>;
  "id": number;
  "created": Date;
  "updatedAt": Date;
  jobs: Job[];
  invoices: Invoice[];
  constructor(data?: PaymentInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Payment`.
   */
  public static getModelName() {
    return "Payment";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Payment for dynamic purposes.
  **/
  public static factory(data: PaymentInterface): Payment{
    return new Payment(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Payment',
      plural: 'Payments',
      path: 'Payments',
      idName: 'id',
      properties: {
        "paymentamount": {
          name: 'paymentamount',
          type: 'number'
        },
        "comment": {
          name: 'comment',
          type: 'string'
        },
        "made": {
          name: 'made',
          type: 'Date'
        },
        "posted": {
          name: 'posted',
          type: 'Date'
        },
        "clientid": {
          name: 'clientid',
          type: 'number'
        },
        "jobIds": {
          name: 'jobIds',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "invoiceIds": {
          name: 'invoiceIds',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "rolledback": {
          name: 'rolledback',
          type: 'boolean',
          default: false
        },
        "type": {
          name: 'type',
          type: 'string',
          default: 'null'
        },
        "stripechargeids": {
          name: 'stripechargeids',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "created": {
          name: 'created',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        jobs: {
          name: 'jobs',
          type: 'Job[]',
          model: 'Job',
          relationType: 'referencesMany',
                  keyFrom: 'jobIds',
          keyTo: 'id'
        },
        invoices: {
          name: 'invoices',
          type: 'Invoice[]',
          model: 'Invoice',
          relationType: 'referencesMany',
                  keyFrom: 'invoiceIds',
          keyTo: 'id'
        },
      }
    }
  }
}
