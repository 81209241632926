
import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable()
export class CustomAlert {

  private errorObj: any;

  constructor(
    public alertCtrl: AlertController
  ) {
  }

	/**
   *Custom PresentAlert function to replace AlertController.
   *
   * @param {string} [header='Error!']
   * @param {string} [subHeader='']
   * @param {string} [message='Unknown Error. Please report to us at cardinal@tamucc.edu']
   * @param {*} [buttons=['OK']]
   */
  async presentAlert(header = 'Alert!', subHeader = '', message = 'Unknown Error. Please report to us at cardinal@tamucc.edu', buttons: any = ['OK']) {
    const alert = await this.alertCtrl.create({
      header: header,
      subHeader: subHeader,
      message: message,
      buttons: buttons //array
    });
    return await alert.present();
  }

  /**
 *For generic insufficient permission alert.
 *
 * @param {string} [permissionName] - Name of the permission
 */
  async presentInsufficientPermissionAlert(permissionName: string = null) {
    let subHeader = permissionName === null ? "" : "Permission: " + permissionName;
    const alert = await this.alertCtrl.create({
      header: "Insufficient Permissions",
      subHeader: subHeader,
      message: "You do not have permission to perform this action. <br> Contact your manager. ",
      buttons: ['OK']
    });
    return await alert.present();
  }
}