/* tslint:disable */
import {
  Polygon
} from '../index';

declare var Object: any;
export interface ParcelInterface {
  "layer": string;
  "recordid"?: number;
  "fulladdress"?: string;
  "legaldescription"?: string;
  "nameonaddress"?: string;
  "parceltaxid"?: string;
  "polygonid"?: number;
  "modifiedorcustom"?: boolean;
  "id"?: number;
  "created"?: Date;
  "updated"?: Date;
  polygon?: Polygon;
}

export class Parcel implements ParcelInterface {
  "layer": string;
  "recordid": number;
  "fulladdress": string;
  "legaldescription": string;
  "nameonaddress": string;
  "parceltaxid": string;
  "polygonid": number;
  "modifiedorcustom": boolean;
  "id": number;
  "created": Date;
  "updated": Date;
  polygon: Polygon;
  constructor(data?: ParcelInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Parcel`.
   */
  public static getModelName() {
    return "Parcel";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Parcel for dynamic purposes.
  **/
  public static factory(data: ParcelInterface): Parcel{
    return new Parcel(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Parcel',
      plural: 'Parcels',
      path: 'Parcels',
      idName: 'id',
      properties: {
        "layer": {
          name: 'layer',
          type: 'string'
        },
        "recordid": {
          name: 'recordid',
          type: 'number'
        },
        "fulladdress": {
          name: 'fulladdress',
          type: 'string'
        },
        "legaldescription": {
          name: 'legaldescription',
          type: 'string'
        },
        "nameonaddress": {
          name: 'nameonaddress',
          type: 'string'
        },
        "parceltaxid": {
          name: 'parceltaxid',
          type: 'string'
        },
        "polygonid": {
          name: 'polygonid',
          type: 'number'
        },
        "modifiedorcustom": {
          name: 'modifiedorcustom',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "created": {
          name: 'created',
          type: 'Date'
        },
        "updated": {
          name: 'updated',
          type: 'Date'
        },
      },
      relations: {
        polygon: {
          name: 'polygon',
          type: 'Polygon',
          model: 'Polygon',
          relationType: 'belongsTo',
                  keyFrom: 'polygonid',
          keyTo: 'id'
        },
      }
    }
  }
}
