/* tslint:disable */
import {
  Parcel
} from '../index';

declare var Object: any;
export interface StreetAddressInterface {
  "addressparentdetailsId": number;
  "addressparentdetailsType"?: string;
  "number"?: string;
  "prefix"?: string;
  "street"?: string;
  "type"?: string;
  "city"?: string;
  "state"?: string;
  "zip"?: string;
  "parentlayer"?: string;
  "parcelid"?: number;
  "id"?: number;
  addressdetails?: any;
  parcel?: Parcel;
}

export class StreetAddress implements StreetAddressInterface {
  "addressparentdetailsId": number;
  "addressparentdetailsType": string;
  "number": string;
  "prefix": string;
  "street": string;
  "type": string;
  "city": string;
  "state": string;
  "zip": string;
  "parentlayer": string;
  "parcelid": number;
  "id": number;
  addressdetails: any;
  parcel: Parcel;
  constructor(data?: StreetAddressInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `StreetAddress`.
   */
  public static getModelName() {
    return "StreetAddress";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of StreetAddress for dynamic purposes.
  **/
  public static factory(data: StreetAddressInterface): StreetAddress{
    return new StreetAddress(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'StreetAddress',
      plural: 'StreetAddresses',
      path: 'StreetAddresses',
      idName: 'id',
      properties: {
        "addressparentdetailsId": {
          name: 'addressparentdetailsId',
          type: 'number'
        },
        "addressparentdetailsType": {
          name: 'addressparentdetailsType',
          type: 'string'
        },
        "number": {
          name: 'number',
          type: 'string'
        },
        "prefix": {
          name: 'prefix',
          type: 'string'
        },
        "street": {
          name: 'street',
          type: 'string'
        },
        "type": {
          name: 'type',
          type: 'string'
        },
        "city": {
          name: 'city',
          type: 'string'
        },
        "state": {
          name: 'state',
          type: 'string'
        },
        "zip": {
          name: 'zip',
          type: 'string'
        },
        "parentlayer": {
          name: 'parentlayer',
          type: 'string'
        },
        "parcelid": {
          name: 'parcelid',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        addressdetails: {
          name: 'addressdetails',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
                  keyFrom: 'addressparentdetailsId',
          keyTo: 'id'
        },
        parcel: {
          name: 'parcel',
          type: 'Parcel',
          model: 'Parcel',
          relationType: 'belongsTo',
                  keyFrom: 'parcelid',
          keyTo: 'id'
        },
      }
    }
  }
}
