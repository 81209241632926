/* tslint:disable */
/**
* @module SDKModule
* @author Jonathan Casarrubias <t:@johncasarrubias> <gh:jonathan-casarrubias>
* @license MIT 2016 Jonathan Casarrubias
* @version 2.1.0
* @description
* The SDKModule is a generated Software Development Kit automatically built by
* the LoopBack SDK Builder open source module.
*
* The SDKModule provides Angular 2 >= RC.5 support, which means that NgModules
* can import this Software Development Kit as follows:
*
*
* APP Route Module Context
* ============================================================================
* import { NgModule }       from '@angular/core';
* import { BrowserModule }  from '@angular/platform-browser';
* // App Root 
* import { AppComponent }   from './app.component';
* // Feature Modules
* import { SDK[Browser|Node|Native]Module } from './shared/sdk/sdk.module';
* // Import Routing
* import { routing }        from './app.routing';
* @NgModule({
*  imports: [
*    BrowserModule,
*    routing,
*    SDK[Browser|Node|Native]Module.forRoot()
*  ],
*  declarations: [ AppComponent ],
*  bootstrap:    [ AppComponent ]
* })
* export class AppModule { }
*
**/
import { ErrorHandler } from './services/core/error.service';
import { LoopBackAuth } from './services/core/auth.service';
import { LoggerService } from './services/custom/logger.service';
import { SDKModels } from './services/custom/SDKModels';
import { InternalStorage, SDKStorage } from './storage/storage.swaps';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CookieBrowser } from './storage/cookie.browser';
import { StorageBrowser } from './storage/storage.browser';
import { StreetAddressApi } from './services/custom/StreetAddress';
import { ParcelApi } from './services/custom/Parcel';
import { ContactInfoApi } from './services/custom/ContactInfo';
import { CompanyApi } from './services/custom/Company';
import { BillingInfoApi } from './services/custom/BillingInfo';
import { PositionApi } from './services/custom/Position';
import { PermissionsApi } from './services/custom/Permissions';
import { StageApi } from './services/custom/Stage';
import { TaskApi } from './services/custom/Task';
import { OfficeApi } from './services/custom/Office';
import { JobApi } from './services/custom/Job';
import { BadgeApi } from './services/custom/Badge';
import { InvoiceApi } from './services/custom/Invoice';
import { PaymentApi } from './services/custom/Payment';
import { MultiAccessTokenApi } from './services/custom/MultiAccessToken';
import { OperationApi } from './services/custom/Operation';
import { ServiceApi } from './services/custom/Service';
import { FlagApi } from './services/custom/Flag';
import { LayerApi } from './services/custom/Layer';
import { PolygonApi } from './services/custom/Polygon';
import { CoordinatePointApi } from './services/custom/CoordinatePoint';
import { LineApi } from './services/custom/Line';
import { DeliverableApi } from './services/custom/Deliverable';
import { AzureApi } from './services/custom/Azure';
import { CardinalconfigApi } from './services/custom/Cardinalconfig';
import { ClientCompanyApi } from './services/custom/ClientCompany';
import { JobParcelApi } from './services/custom/JobParcel';
import { ClientApi } from './services/custom/Client';
import { EmployeeApi } from './services/custom/Employee';
/**
* @module SDKBrowserModule
* @description
* This module should be imported when building a Web Application in the following scenarios:
*
*  1.- Regular web application
*  2.- Angular universal application (Browser Portion)
*  3.- Progressive applications (Angular Mobile, Ionic, WebViews, etc)
**/
@NgModule({
  imports:      [ CommonModule, HttpClientModule ],
  declarations: [ ],
  exports:      [ ],
  providers:    [
    ErrorHandler
  ]
})
export class SDKBrowserModule {
  static forRoot(internalStorageProvider: any = {
    provide: InternalStorage,
    useClass: CookieBrowser
  }): ModuleWithProviders {
    return {
      ngModule  : SDKBrowserModule,
      providers : [
        LoopBackAuth,
        LoggerService,
        SDKModels,
        StreetAddressApi,
        ParcelApi,
        ContactInfoApi,
        CompanyApi,
        BillingInfoApi,
        PositionApi,
        PermissionsApi,
        StageApi,
        TaskApi,
        OfficeApi,
        JobApi,
        BadgeApi,
        InvoiceApi,
        PaymentApi,
        MultiAccessTokenApi,
        OperationApi,
        ServiceApi,
        FlagApi,
        LayerApi,
        PolygonApi,
        CoordinatePointApi,
        LineApi,
        DeliverableApi,
        AzureApi,
        CardinalconfigApi,
        ClientCompanyApi,
        JobParcelApi,
        ClientApi,
        EmployeeApi,
        internalStorageProvider,
        { provide: SDKStorage, useClass: StorageBrowser }
      ]
    };
  }
}
/**
* Have Fun!!!
* - Jon
**/
export * from './models/index';
export * from './services/index';
export * from './lb.config';
export * from './storage/storage.swaps';
export { CookieBrowser } from './storage/cookie.browser';
export { StorageBrowser } from './storage/storage.browser';

