import * as _ from 'underscore';

export class SearchTable {
    constructor() {

    }

    searchAll(collection: Array<any>, searchValue: string): any {
        if (!collection) {
            return collection;
        }

        return _.filter(collection, (object) => {
            return JSON.stringify(object).toLowerCase().includes(searchValue.toLowerCase()) ? true : false;
        });
    }
}
