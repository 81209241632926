import {DataServiceService} from '../services/data-service.service';
import { Observable } from 'rxjs';
import { take, mergeMap } from 'rxjs/operators';
import * as _ from 'underscore';
import { Injectable } from '@angular/core';

@Injectable()
export class JobAdvancedSearch {
    dataService: DataServiceService;
    readonly limit: number = 50;
    readonly delimiter: string = '148';
    readonly textArrayCases = [`[${this.delimiter}]`, `,${this.delimiter}]`, `[${this.delimiter},`, `,${this.delimiter},`];

    constructor(dataSerivce: DataServiceService) {
        this.dataService = dataSerivce;
    }

    /**
     * (Builds search query, we have this as a separate function so that the programmer can build the query first
     * before having to add a subscription)
     *
     * @param {string} searchOption - from a dropdown list, see JobDatatableComponent.html to see example
     * @param {string} searchValue - What is typed in a search bar
     * @returns {*} Returns the query as an object that can be used for dataservice calls (client returns an array for 
     * first and/or lastname)
     * @memberof JobAdvancedSearch
     */
    buildSearchQuery(searchOption: string, searchValue: string): any {
        switch (searchOption) {
            case 'mask':
                const query = {mask: {ilike: `%${searchValue}%`}};
                return query;
            case 'client':
                return searchValue.split(' ');
            case 'address':
                return {where: {fulladdress: {ilike: `%${searchValue}%`}}, fields: ['id'], limit: this.limit};
            case 'parcelId':
                return {where: {parceltaxid: {ilike: `${searchValue}%`}}, limit: this.limit, fields: ['id']};
            default:
                return null;
        }
    }

    /**
     * (Maps to the subscription call that will return the search results. All will end with the same subscription from
     * dataservice.getAllJobs())
     *
     * @param {*} [skip=null] - for infinite scrolling if data is already loaded
     * @param {*} [query=null] - search query to use
     * @param {*} [searchOption=null] - used to determine which case to take, see JobDatatableComponent.html to see the cases
     * @returns {Observable<any>}
     * @memberof JobAdvancedSearch
     */
    searchForJobs(skip: any = null, query: any = null, searchOption: any = null): Observable<any> {
        switch (searchOption) {
            case 'mask':
                return this.searchJobsUsingJob(skip, query);
            case 'client':
                return this.searchJobsUsingClient(skip, query);
            case 'address':
                return this.searchJobsUsingParcel(skip, query);
            case 'manager':
                return this.searchJobsUsingEmployee(skip, query);
            case 'parcelId':
                return this.searchJobsUsingParcel(skip, query);
            default:
                return this.searchJobsUsingJob(skip, query);
        }
    }

    /**
     * (Final subscription call, all searchJobsUsing* call this function last)
     *
     * @param {*} [skip=null] - for infinite scrolling, you can skip some jobs
     * @param {*} [query=null] - search query ex. {id: 8}
     * @returns {Observable<any>} - subscribe to this to get an array of jobs that include: client, parcels, office, managers, operations
     * @memberof JobAdvancedSearch
     */
    searchJobsUsingJob(skip: any = null, query: any = null): Observable<any> {
        return this.dataService.getAllJobs(skip ? skip : 0, query ? query : {});
    }

    /**
     * (Pre-filering jobs by getting a list of clients based on a query. Will only get jobs based on the client ids
     * that are returned)
     *
     * @param {*} [skip=null] - for inifinite scrolling or skipping a number of jobs
     * @param {*} [query=null] -search query ex. {id: 8}
     * @returns {Observable<any>} - subscribe to this, calls dataservice.getAllJobs after getting client ids to search for
     * @memberof JobAdvancedSearch
     */
    searchJobsUsingClient(skip: any = null, query: any = null): Observable<any> {
        let clients = null;
        return this.dataService.searchClientByName(query[0], query.length === 2 ? query[1] : '').pipe(
            mergeMap((searchedClients: any) => {
                clients = searchedClients && searchedClients.length > 0 ? searchedClients : null;
                const queryByClientId = {or: _.map(clients, (client) => {
                    return {clientid: client.id};
                })};
                return this.searchJobsUsingJob(skip ? skip : 0, queryByClientId);
            })
        );
    }

    /**
     * (Pre-filtering jobs by getting a list of parcels based on a programmer defined query. Will only get jobs based on the
     * parcel ids that are returned)
     *
     * @param {*} [skip=null] - for inifinite scrolling or skipping a number of jobs
     * @param {*} [query=null] -search query ex. {id: 8}
     * @returns {Observable<any>} - subscribe to this, get's a list of jobs based on the result of a parcel search
     * @memberof JobAdvancedSearch
     */
    searchJobsUsingParcel(skip: any = null, query: any = null): Observable<any> {
        return this.dataService.getParcelIds(query).pipe(
            mergeMap((parcelIds => {
                return this.searchForJobs(skip, {or: _.map(parcelIds, (parcel) => {
                    return {or: _.map(this.textArrayCases, (textCase) => {
                        return {parcelIds: textCase.replace(this.delimiter, parcel.id)};
                    })};
                })});
            }))
        );
    }

    /**
     * (Pre-filtering jobs by getting a list of employees based on a programmer defined query. Will only get employees based
     * on the employee ids returned)
     *
     * @param {*} [skip=null] - for inifinite scrolling or skipping a number of jobs
     * @param {*} [query=null] -search query ex. {id: 8}
     * @returns {Observable<any>}
     * @memberof JobAdvancedSearch
     */
    searchJobsUsingEmployee(skip: any = null, query: any = null): Observable<any> {
        return this.dataService.searchEmployees(query).pipe(
            mergeMap((employees) => {
                return this.searchForJobs(skip, {or: _.map(employees, (employee) => {
                    return {or: _.map(this.textArrayCases, (textCase) => {
                        return {managerIds: textCase.replace(this.delimiter, employee.id)};
                    })};
                })});
            })
        );
    }
}
