import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { NavController, PopoverController } from '@ionic/angular';
import { Router } from '@angular/router';

import { CreateClientPage } from "../../pages/create-client/create-client.page";
import { CreateJobPage } from '../../pages/create-job/create-job.page';

import { DataServiceService } from "../../services/data-service.service";

@Component({
  selector: 'app-newitems-popover',
  templateUrl: './newitems-popover.component.html',
  styleUrls: ['./newitems-popover.component.scss']
})
export class NewitemsPopoverComponent implements OnInit {

  constructor(
    public navCtrl: NavController,
    public popoverCtrl: PopoverController,
    public router: Router
  ) { }

  ngOnInit() {
    // console.log(this.navCtrl)
  }

  addClient() {
    // console.log("Create client called")
    this.navCtrl.navigateForward("/create-client");
    this.dismissPopover();
  }

  createJob() {
    // console.log("Create job called")
    this.navCtrl.navigateForward("/create-job");
    this.dismissPopover();

  }

  submitFeedback() {
    this.navCtrl.navigateForward("/feedback");
    this.dismissPopover();
  }

  dismissPopover() {
    this.popoverCtrl.getTop().then((top) => {
      top.dismiss();
    });
  }



}
