/* tslint:disable */
import {
  Company,
  Client
} from '../index';

declare var Object: any;
export interface ClientCompanyInterface {
  "id"?: number;
  "clientid": number;
  "companyid"?: number;
  "contactinfoid"?: number;
  "stripecustomerid"?: string;
  company?: Company;
  client?: Client;
}

export class ClientCompany implements ClientCompanyInterface {
  "id": number;
  "clientid": number;
  "companyid": number;
  "contactinfoid": number;
  "stripecustomerid": string;
  company: Company;
  client: Client;
  constructor(data?: ClientCompanyInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ClientCompany`.
   */
  public static getModelName() {
    return "ClientCompany";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ClientCompany for dynamic purposes.
  **/
  public static factory(data: ClientCompanyInterface): ClientCompany{
    return new ClientCompany(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ClientCompany',
      plural: 'ClientCompany',
      path: 'ClientCompany',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'number'
        },
        "clientid": {
          name: 'clientid',
          type: 'number'
        },
        "companyid": {
          name: 'companyid',
          type: 'number',
          default: 0
        },
        "contactinfoid": {
          name: 'contactinfoid',
          type: 'number'
        },
        "stripecustomerid": {
          name: 'stripecustomerid',
          type: 'string'
        },
      },
      relations: {
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyid',
          keyTo: 'id'
        },
        client: {
          name: 'client',
          type: 'Client',
          model: 'Client',
          relationType: 'belongsTo',
                  keyFrom: 'clientid',
          keyTo: 'id'
        },
      }
    }
  }
}
