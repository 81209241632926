import { Injectable } from '@angular/core';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import _ from 'underscore';
import accounting from 'accounting';
import { computeHeightAndMargins } from '@fullcalendar/core';
import * as moment from 'moment';


export class PdfExporter {
    table = new Array();
    header = {};
    footer = {};
    styles = {
        title: {
            fontSize: 20,
            bold: true
        },
        header: {
            fontSize: 16,
            bold: true,
        },
        smallText: {
            fontSize: 10
        },
        smallFooterText: {
            fontSize: 10
        },
        defaultTable: {
            margin: [0, 5, 0, 15]
        },
        tableHeader: {
            bold: true,
            fontSize: 13,
            color: 'black'
        },
        smallTableHeader: {
            bold: true,
            fontSize: 10,
            color: 'black'
        },
        smallTableFont: {
            fontSize: 8,
            color: 'black'
        }
    };
    info = {
        title: '',
        author: '',
        subject: '',
        keywords: '',
        creator: '',  // Default is 'pdfmake'
        producer: '', // Default is 'pdfmake'
        creationDate: '', // Add automatically by pdfmake when the file is generated
        modDate: ''
    };
    fileName: string = 'CardinalPdfFile';

    constructor(
    ) {
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
    }

    /**
     * (Converts number into string and setting commas by the thousands place)
     *
     * @param {*} input - a string or number
     * @returns
     * @memberof PdfExporter
     */
    numberWithCommas(input: any) {
        return input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    /**
     * (Add decimals to an integer to a defined decimal places)
     *
     * @param {number} input
     * @param {number} [decimalPlaces = 2]
     * @returns
     * @memberof PdfExporter
     */
    addDecimals(input: number, decimalPlaces: number = 2) {
        return input.toFixed(decimalPlaces);
    }

    /**
     * (Formats a date object into mm/dd/yyyy)
     *
     * @param {Date} [date=new Date()]
     * @returns
     * @memberof PdfExporter
     */
    monthDayYearFormat(date: Date = new Date()) {
        return ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ?
            date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear();
    }

    /**
     * (Checks if input is null or undefined), returns true if it is null or undefined
     *
     * @param {*} input - can be anything
     * @returns {boolean}
     * @memberof PdfExporter
     */
    checkIfNullOrUndefined(input: any): boolean {
        return input === null || input === undefined;
    }

    /**
     * (Returns a label and field while keeping space between them.)
     *
     * @param {string} label
     * @param {string} text
     * @param {Array<number>} [labelMargin=[0, 5, 0, 0]]
     * @param {Array<number>} [textMargin=[5, 0, 0, 0]]
     * @returns
     * @memberof PdfExporter
     */
    addLabelAndField(label: string, text: string, labelMargin: Array<number> = [0, 5, 0, 0], textMargin: Array<number> = [5, 5, 0, 0]) {
        const columns = {
            columns: [
                {
                    text: label,
                    width: 'auto',
                    margin: labelMargin
                },
                {
                    text: text,
                    width: '*',
                    margin: textMargin
                }
            ]
        };

        return columns;
    }

    /**
     * (Returns a label and field while keeping space between them. Adds new lines
     * and moves text to those lines when the text field is too large.)
     *
     * @param {string} label
     * @param {string} text
     * @param {number} labelEnds
     * @param {number} pageWidth
     * @param {number} [threshold=70]
     * @param {Array<number>} [labelMargin=[0, 5, 0, 0]]
     * @param {Array<number>} [textMargin=[5, 5, 0, 0]]
     * @returns {*}
     * @memberof PdfExporter
     */
    addLabelAndLargeField(label: string, text: string, labelEnds: number, pageWidth: number, threshold: number = 70,
        labelMargin: Array<number> = [0, 5, 0, 0], textMargin: Array<number> = [5, 5, 0, 0]): any {
        // Not going to use regex to solve this problem because we want to give the programmer
        // the ability to modify the threshold for the text
        const chunkSize = Math.ceil(text.length / threshold) === 0 ? 1 : Math.ceil(text.length / threshold);
        let position = 0;

        return _.map(new Array(chunkSize), (chunk) => {
            chunk = text.substr(position, threshold);

            if (position === 0) {
                position += threshold + 1;
                return {
                    stack: [
                        {
                            columns: [
                                {
                                    text: label,
                                    width: 'auto',
                                    margin: labelMargin
                                },
                                {
                                    text: chunk,
                                    width: '*',
                                    margin: textMargin
                                }
                            ]
                        },
                        this.addHorizontalLine(labelEnds, pageWidth)
                    ]
                };
            } else {
                position += threshold + 1;
                return {
                    stack: [
                        {
                            text: chunk,
                            width: '*',
                            margin: textMargin
                        },
                        this.addHorizontalLine(0, pageWidth)
                    ]
                };
            }
        });
    }

    /**
     * (Returns a canvas that of a horizontal line)
     *
     * @param {number} x1 - position to start the line on the x-axis
     * @param {number} x2 - position to end the line on the x-axis
     * @param {number} [y1=0] - position to start the line on the y-axis
     * @param {number} [y2=0] - position to end the line on the y-axis
     * @param {number} [lineWidth=1] - The thickness of the line
     * @returns
     * @memberof PdfExporter
     */
    addHorizontalLine(x1: number, x2: number, y1: number = 0, y2: number = 0, lineWidth: number = 1) {
        const canvas = {
            canvas: [
                {
                    type: 'line',
                    x1: x1,
                    y1: y1,
                    x2: x2,
                    y2: y2,
                    lineWidth: lineWidth
                }
            ]
        };

        return canvas;
    }

    /**
     * (This function returns all parcel data as a stack for pdf files)
     *
     * @param {Array<any>} parcels
     * @param {number} pageWidth
     * @returns {Array<any>}
     * @memberof PdfExporter
     */
    stackParcels(parcels: Array<any>, pageWidth: number): Array<any> {
        let result = [];
        for (let i = 0; i < parcels.length; i++) {
            let content = {
                stack: [
                    this.addLabelAndField('Parcel Tax ID:', `${parcels[i].parceltaxid}`),
                    this.addHorizontalLine(75, pageWidth),
                    this.addLabelAndField('Parcel Layer:', `${parcels[i].layer}`),
                    this.addHorizontalLine(75, pageWidth),
                    this.addLabelAndField('Full Address:', `${parcels[i].fulladdress}`),
                    this.addHorizontalLine(75, pageWidth),
                    this.addLabelAndLargeField('Legal Description:', `${parcels[i].legaldescription}`, 100, pageWidth, 60)
                ],
                margin: [0, 0, 0, 0]
            };

            if (i !== 0) {
                content.margin = [0, 15, 0, 0];
            }
            result.push(content);
        }

        return result;
    }

    /**
     * (Add parcels information in one line and keeps spacing between multiple parcels)
     *
     * @param {Array<any>} parcels
     * @param {Array<number>} [margins]
     * @returns {Array<any>}
     * @memberof PdfExporter
     */
    oneLineParcels(parcels: Array<any>, margins?: Array<number>): Array<any> {
        return _.map(parcels, (parcel) => {
            const legal = this.checkString(parcel.legaldescription);

            const legalCol = {
                columns: [
                    {
                        text: 'Parcel Description: ',
                        width: 110,
                        margin: [0, 0, 0, 0]
                    },
                    {
                        text: legal,
                        width: '*',
                        margin: [5, 0, 0, 0]
                    }
                ]
            };

            return {
                stack: [
                    legalCol,
                    this.addLabelAndField('Address:', this.checkString(parcel.fulladdress), [0, 0, 0, 0], [5, 0, 0, 0]),
                    this.addLabelAndField('Tax ID:', this.checkString(parcel.parceltaxid), [0, 0, 0, 0], [5, 0, 0, 0])
                ],
                margin: margins
            };
        });
    }

    /**
     * (Formats all service data for pdf files)
     *
     * @param {Array<string>} input
     * @param {number} pageWidth
     * @param {number} [numberOfColumns=2]
     * @returns {*}
     * @memberof PdfExporter
     */
    createULColumns(input: Array<string>, pageWidth: number, numberOfColumns: number = 2): any {
        const content = _.map(input, (text) => {
            return {
                text: text
            };
        });
        const columns = _.map(_.chunk(content, Math.ceil(content.length / numberOfColumns)), (column) => {
            return {
                ul: column,
                width: '*'
            };
        });

        return {
            columns: columns,
            margin: [0, 5, 0, 0]
        };
    }

    /**
     *
     * (Can use this function to set the filename)
     *
     * @param {string} fileName - name of the pdf file to download, set this before generating the file
     * @memberof PdfExporter
     * @param {boolean} [replaceSpaceWithUnderscore = true] - flag that replaces the filename spaces with _
     * @returns {void}
     */
    setFileName(fileName: string, replaceSpaceWithUnderscore: boolean = true): void {
        if (replaceSpaceWithUnderscore) {
            this.fileName = fileName.replace(/ /g, '_');
        } else {
            this.fileName = fileName;
        }
    }

    /**
     * (This is used to set the pdf documents metadata)
     *
     * @param {string} title - the title of the document
     * @param {string} [author='Cardinal'] - the name of the author
     * @param {string} [subject] - the subject of the document
     * @param {string} [keywords] - keywords associated with the document
     * @param {string} [creator] - the creator of the document (default is ‘pdfmake’)
     * @param {string} [producer] - the producer of the document (default is ‘pdfmake’)
     * @param {string} [creationDate] - the date the document was created (added automatically by pdfmake)
     * @param {string} [modDate] - the date the document was last modified
     * @memberof PdfExporter
     * @returns {void}
     */
    setDocumentMetaData(title: string, author: string = 'Cardinal', subject?: string, keywords?: string,
        creator?: string, producer?: string, creationDate?: string, modDate?: string): void {
        this.info.title = title;
        this.info.author = author;
        this.info.subject = subject;
        this.info.subject = keywords;
        this.info.creator = creator;
        this.info.producer = producer;
        this.info.creationDate = creationDate;
        this.info.modDate = modDate;
    }

    /**
     * (Generates the pdf for packet details.)
     *
     * @param {*} jobData
     * @param {*} addressData
     * @param {*} clientData
     * @param {*} parcels
     * @param {*} contactInfoData
     * @param {*} services
     * @param {*} latestInvoice
     * @memberof PdfExporter
     */
    generatePacketDetails(jobData: any, addressData: any, clientData: any, parcels: any, contactInfoData: any, services: any,
        latestInvoice: any, managingOffice: any): void {
        console.log('Exporting pdf ...');
        const pageWidth = 475;

        const documentDefinition = {
            info: this.info,
            footer: function (currentPage, pageCount) {
                // margin: [left, top, right, bottom]
                return {
                    columns: [
                        {
                            text: moment().format('MMMM DD, YYYY, hh:mm A'),
                            alignment: 'left',
                            style: 'smallFooterText'
                        },
                        {
                            text: `Packet Details`,
                            alignment: 'center',
                            style: 'smallFooterText'
                        },
                        {
                            text: `Page ${currentPage.toString() + ' of ' + pageCount}`,
                            alignment: 'right',
                            style: 'smallFooterText'
                        }
                    ],
                    margin: [60, 35]
                };
            },
            content: [
                {
                    columns: [
                        {
                            text: 'Packet Details',
                            style: 'title',
                            alignment: 'left'
                        },
                        {
                            stack: [
                                {
                                    text: `Job # ${jobData.mask}`,
                                    style: 'title',
                                    alignment: 'right'
                                },
                                {
                                    text: managingOffice.name,
                                    style: 'smallText',
                                    alignment: 'right'
                                }
                            ]
                        }
                    ]
                },
                {
                    text: 'Client:',
                    style: 'header',
                    margin: [0, 5, 0, 0]
                },
                {
                    columns: [
                        {
                            stack: [
                                this.addLabelAndField('Due By:', `${this.monthDayYearFormat(new Date(jobData.due))}`),
                                this.addHorizontalLine(45, pageWidth * 0.75 - 5),
                                this.addLabelAndField('Bill To:', `${clientData.firstname} ${clientData.lastname}`),
                                this.addHorizontalLine(40, pageWidth * 0.75 - 5),
                                this.addLabelAndField(
                                    'Address:', `${this.checkString(addressData.line1, false, false)} ` +
                                    (this.checkString(addressData.line2, false, true))
                                ),
                                this.addHorizontalLine(50, pageWidth * 0.75 - 5),
                                this.addLabelAndField('City State Zip:', `${addressData.city}, ${addressData.state} ${addressData.zip}`),
                                this.addHorizontalLine(80, pageWidth * 0.75 - 5)
                            ],
                            width: '*',
                        },
                        {
                            stack: [
                                this.addLabelAndField('Ph. #:', contactInfoData.phone ? `${contactInfoData.phone}` : ''),
                                this.addHorizontalLine(35, 130),
                                this.addLabelAndField('Ph. #:', ``),
                                this.addHorizontalLine(35, 130),
                                this.addLabelAndField('Ph. #:', ``),
                                this.addHorizontalLine(35, 130),
                                this.addLabelAndField('FAX: ', contactInfoData.fax ? `${contactInfoData.fax}` : ''),
                                this.addHorizontalLine(30, 130)
                            ],
                            width: 'auto'
                        }
                    ]
                },
                this.addLabelAndField('Email:', `${contactInfoData.email.startsWith('needsanunique') ? '' : contactInfoData.email}`),
                this.addHorizontalLine(35, pageWidth),
                {
                    text: 'Billing:',
                    style: 'header',
                    margin: [0, 15, 0, 0]
                },
                this.addLabelAndField('Company:', `${latestInvoice.companyName}`),
                this.addHorizontalLine(50, pageWidth),
                {
                    columns: [
                        {
                            stack: [
                                this.addLabelAndField('Invoice Issued:', `${latestInvoice.invoiceIssued}`),
                                this.addHorizontalLine(80, pageWidth * 0.5 - 5),
                                this.addLabelAndField('Total Amount:', `${latestInvoice.totalAmount}`),
                                this.addHorizontalLine(80, pageWidth * 0.5 - 5),
                            ],
                            width: '*'
                        },
                        {
                            stack: [
                                this.addLabelAndField('Lastest Payment:', `${latestInvoice.paymentMadeDate}`),
                                this.addHorizontalLine(100, pageWidth * 0.5),
                                this.addLabelAndField('Remaining Amount:', `${latestInvoice.remainingAmount}`),
                                this.addHorizontalLine(110, pageWidth * 0.5),
                            ],
                            width: '*'
                        }
                    ]
                },
                this.addLabelAndField('Flags:', `${latestInvoice.flags}`),
                this.addHorizontalLine(35, pageWidth),
                this.addLabelAndLargeField('Comments:', `${this.checkIfNullOrUndefined(jobData.comments) ?
                    '' : jobData.comments}`, 65, pageWidth, 60),
                {
                    text: 'Service(s):',
                    style: 'header',
                    margin: [0, 15, 0, 0]
                },
                this.createULColumns(_.map(services, (service) => service.taskdetails.name), pageWidth),
                {
                    text: 'Parcel(s):',
                    style: 'header',
                    margin: [0, 15, 0, 0]
                },
                this.stackParcels(parcels, pageWidth)
            ],
            styles: this.styles,
            pageMargins: [60, 60]
        };
        pdfMake.createPdf(documentDefinition).download(this.fileName);
    }

    /**
     * (Generates a pdf for sales tax report and downlads the document to the users device)
     *
     * @param {Array<any>} [table=this.table]
     * @param {*} headerContent
     * @memberof PdfExporter
     */
    generateAccountsReceivablesReport(table: Array<any> = this.table, headerContent: any) {
        const documentDefinition = {
            info: this.info,
            footer: function (currentPage, pageCount) {
                // margin: [left, top, right, bottom]
                return {
                    columns: [
                        {
                            text: moment(new Date()).format('MMMM D, YYYY, LT'),
                            alignment: 'left',
                            style: 'smallFooterText'
                        },
                        {
                            text: `Aged Accounts Receivables`,
                            alignment: 'center',
                            style: 'smallFooterText'
                        },
                        {
                            text: `Page ${currentPage.toString() + ' of ' + pageCount}`,
                            alignment: 'right',
                            style: 'smallFooterText'
                        }
                    ],
                    margin: [60, 35]
                };
            },
            content: [
                headerContent,
                table
            ],
            styles: this.styles,
            pageMargins: [60, 60],
            pageOrientation: 'landscape'
        };
        pdfMake.createPdf(documentDefinition).download(this.fileName);
    }

    /**
     *
     *
     * @param {Array<any>} [table=this.table]
     * @param {string} officeName
     * @param {string} startDate
     * @param {string} endDate
     * @memberof PdfExporter
     * @returns {void}
     */
    generateSalesTaxReport(table: Array<any> = this.table, officeName: string,
        startDate: string, endDate: string): void {
        const documentDefinition = {
            info: this.info,
            footer: function (currentPage, pageCount) {
                // margin: [left, top, right, bottom]
                return {
                    columns: [
                        {
                            text: moment(new Date()).format('MMMM D, YYYY, LT'),
                            alignment: 'left',
                            style: 'smallFooterText'
                        },
                        {
                            text: `Sales Tax Report`,
                            alignment: 'center',
                            style: 'smallFooterText'
                        },
                        {
                            text: `Page ${currentPage.toString() + ' of ' + pageCount}`,
                            alignment: 'right',
                            style: 'smallFooterText'
                        }
                    ],
                    margin: [60, 35]
                };
            },
            content: [
                {
                    columns: [
                        {
                            text: `Sales Tax Report\n${startDate} to ${endDate}`,
                            style: 'header',
                            alignment: 'left'
                        },
                        {
                            text: `${officeName}\n`,
                            style: 'smallText',
                            alignment: 'right'
                        }
                    ]
                },
                table
            ],
            styles: this.styles,
            pageMargins: [60, 60],
            pageOrientation: 'landscape'
        };
        pdfMake.createPdf(documentDefinition).download(this.fileName);
    }

        /**
     * Generate pdf file for depositReport
     *
     * @param {Array<any>} [table=this.table]
     * @param {string} officeName
     * @memberof PdfExporter
     * @returns {void}
     */
    generatePaymentReport(table: Array<any> = this.table, officeName: string): void {
        const documentDefinition = {
            info: this.info,
            footer: function (currentPage, pageCount) {
                // margin: [left, top, right, bottom]
                return {
                    columns: [
                        {
                            text: moment(new Date()).format('MMMM D, YYYY, LT'),
                            alignment: 'left',
                            style: 'smallFooterText'
                        },
                        {
                            text: `Deposit Report`,
                            alignment: 'center',
                            style: 'smallFooterText'
                        },
                        {
                            text: `Page ${currentPage.toString() + ' of ' + pageCount}`,
                            alignment: 'right',
                            style: 'smallFooterText'
                        }
                    ],
                    margin: [60, 35]
                };
            },
            content: [
                {
                    columns: [
                        {
                            text: `Payment Report\n`,
                            style: 'header',
                            alignment: 'left'
                        },
                        {
                            text: `${officeName}\n`,
                            style: 'smallText',
                            alignment: 'right'
                        }
                    ]
                },
                table
            ],
            styles: this.styles,
            pageMargins: [60, 60],
            pageOrientation: 'landscape'
        };
        pdfMake.createPdf(documentDefinition).download(this.fileName);
    }
    /**
     * Generate pdf file for depositReport
     *
     * @param {Array<any>} [table=this.table]
     * @param {string} officeName
     * @memberof PdfExporter
     * @returns {void}
     */
    generateDepositReport(table: Array<any> = this.table, officeName: string): void {
        const documentDefinition = {
            info: this.info,
            footer: function (currentPage, pageCount) {
                // margin: [left, top, right, bottom]
                return {
                    columns: [
                        {
                            text: moment(new Date()).format('MMMM D, YYYY, LT'),
                            alignment: 'left',
                            style: 'smallFooterText'
                        },
                        {
                            text: `Deposit Report`,
                            alignment: 'center',
                            style: 'smallFooterText'
                        },
                        {
                            text: `Page ${currentPage.toString() + ' of ' + pageCount}`,
                            alignment: 'right',
                            style: 'smallFooterText'
                        }
                    ],
                    margin: [60, 35]
                };
            },
            content: [
                {
                    columns: [
                        {
                            text: `Deposit Report\n`,
                            style: 'header',
                            alignment: 'left'
                        },
                        {
                            text: `${officeName}\n`,
                            style: 'smallText',
                            alignment: 'right'
                        }
                    ]
                },
                table
            ],
            styles: this.styles,
            pageMargins: [60, 60],
            pageOrientation: 'landscape'
        };
        pdfMake.createPdf(documentDefinition).download(this.fileName);
    }

    /**
     * (Creates a table to add to the pdf report, this also will filter your dataobject for you
     * Use the customCreateTable if you want more control on how your table data will be displayed.)
     *
     * @param {Array<string>} displayColumns ['Job #', 'Invoice Amount', 'Taxable Amount']
     * @param {Array<string>} filterKeys ['jobid', 'invoiceamount', 'taxableamount']
     * @param {Array<any>} dataObj array of json objects containing the tables data
     * @param {string} color optional param: 'blue' '#CCCCCC'
     * @param {string} tableHeaderColor optional param: 'blue' '#d3d3d3'
     * @param {Array<any>} widths optional param: ['*', 100, '*', 'auto']
     * @param {string} alignment optional param: 'center', 'right', or 'left'
     * @param {Array<number>} margin optional param: [0, 0, 0, 0]
     * @memberof PdfExporter
     * @returns {void}
     */
    createTable(displayColumns: Array<string>, filterKeys: Array<string>, dataObj: Array<any>, color?: string, tableHeaderColor?: string,
        widths?: Array<any>, alignment: string = 'center', margin: Array<number> = [0, 0, 0, 0]): void {
        let body = new Array();

        // First array for table should be the display column
        body.push(_.map(displayColumns, (column) => ({
            text: column, style: 'smallTableHeader', alignment: alignment,
            border: [false, false, true, false]
        })));

        // Filtering for the programmer
        _.map(dataObj, (data) => {
            let object = _.pick(data, filterKeys);
            object = Object.values(object);
            object = _.map(object, (value) => {
                return { text: value, style: 'smallTableFont', alignment: alignment };
            });
            body.push(object);
            return object;
        });

        const table = {
            style: '',
            margin: margin,
            table: {
                widths: widths ? widths : new Array(displayColumns.length).fill('*'),
                // headerRows: 1,
                body: body
            },
            layout: {
                fillColor: function (i, node) {
                    if (color) {
                        if (i === 0) {
                            return (tableHeaderColor) ? tableHeaderColor : null;
                        }
                        return (i % 2 === 0) ? color : null;
                    } else {
                        if (i === 0) {
                            return (tableHeaderColor) ? tableHeaderColor : null;
                        }
                        return (i % 2 === 0) ? 'aliceblue' : null;
                    }
                },
                hLineWidth: function (i, node) {
                    return 0;
                },
                vLineWidth: function (i, node) {
                    return 0;
                },
                hLineColor: function (i, node) {
                    return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                },
                vLineColor: function (i, node) {
                    return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                },
            }
        };
        this.table.push(table);
    }

    /**
     * (Returns the base64 image from an image url)
     *
     * @param {*} url
     * @returns
     * @memberof PdfExporter
     */
    getBase64ImageFromURL(url) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.setAttribute('crossOrigin', 'anonymous');
            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);
                const dataURL = canvas.toDataURL('image/png');
                resolve(dataURL);
            };
            img.onerror = error => {
                reject(error);
            };
            img.src = url;
        });
    }

    /**
     * (Returns the input or N/A if its undefined, null, or an empty string)
     *
     * @param {string} input
     * @param {string} checkEmail - set this to true if you want it to check for 'needsanuniqueemail'
     * @returns {string}
     * @memberof PdfExporter
     */
    checkString(input: string, checkEmail: boolean = false, returnNothing: boolean = false): string {
        const checkEmails = ['needsanunique', 'needanunique', 'needsanuniqueemail'];
        if (this.checkIfNullOrUndefined(input) || (checkEmail && _.find(checkEmails, (email) => input.includes(email)))) {
            return returnNothing ? '' : 'N/A';
        } else if (input.length === 0) {
            return returnNothing ? '' : 'N/A';
        }

        return input;
    }

    /**
     * (Builds a service table)
     *
     * @param {Array<any>} services
     * @param {Array<number>} [margin=[0, 0, 0, 0]]
     * @returns
     * @memberof PdfExporter
     */
    servicesTable(services: Array<any>, parcelLength: number = 1, margin: Array<number> = [0, 0, 0, 0]) {
        const body = [];
        body.push([
            { text: '#', bold: true, fontSize: 14 },
            { text: 'Description', bold: true, fontSize: 14 },
            { text: 'Taxable', bold: true, fontSize: 14, alignment: 'center' },
            { text: 'Amount', bold: true, fontSize: 14, alignment: 'right' }
        ]);
        _.each(services, (service, index) => {
            body.push([
                { text: index + 1, fontSize: 12 },
                {
                    text: !this.checkIfNullOrUndefined(service.notes) ? [service.taskdetails.name + '\n',
                    { text: service.notes, fontSize: 10, italics: true }] : service.taskdetails.name, fontSize: 12
                },
                service.taxable ? { image: 'checkmark', alignment: 'center', fit: [10, 10] } : { text: '' },
                { text: accounting.formatMoney(service.customrate * parcelLength), fontSize: 12, alignment: 'right' }
            ]);
        });

        return {
            table: {
                widths: ['auto', '*', 'auto', 75],
                body
            },
            layout: 'lightHorizontalLines',
            margin: margin
        };
    }

    /**
     * (Makes a table for payments, basically shows the history of payments on a job)
     *
     * @param {Array<any>} payments
     * @param {Array<number>} [margin=[0, 0, 0, 0]]
     * @returns
     * @memberof PdfExporter
     */
    listPaymentsTable(payments: Array<any>, margin: Array<number> = [0, 0, 0, 0]) {
        const body = [];
        let counter = 1;
        body.push([
            { text: '#', bold: true, fontSize: 14 },
            { text: 'Date', bold: true, fontSize: 14 },
            { text: 'Payment Type', bold: true, fontSize: 14, alignment: 'center' },
            { text: 'Payment Amount', bold: true, fontSize: 14, alignment: 'right' }
        ]);
        payments = _.sortBy(payments, (payment) => new Date(payment.made));

        _.each(payments, (payment, iteration) => {
            if (payment.type === 'Rollback' || payment.rolledback) {
                return;
            } else {
                body.push([
                    { text: counter, fontSize: 12 },
                    { text: payment.made == null ? 'N/A' : moment(payment.made).format('MM/DD/YY'), fontSize: 12 },
                    { text: payment.type, fontSize: 12 },
                    { text: accounting.formatMoney(payment.paymentamount), fontSize: 12, alignment: 'right' }
                ]);
                counter++;
            }
        });

        return payments.length === 0 || body.length === 1 ? null : {
            table: {
                widths: ['auto', 'auto', 100, '*'],
                body
            },
            layout: 'lightHorizontalLines',
            margin: margin
        };
    }
}
