/* tslint:disable */

declare var Object: any;
export interface BadgeInterface {
  "id": number;
  "banner": string;
  "desc"?: string;
  "archived": boolean;
  "color": string;
}

export class Badge implements BadgeInterface {
  "id": number;
  "banner": string;
  "desc": string;
  "archived": boolean;
  "color": string;
  constructor(data?: BadgeInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Badge`.
   */
  public static getModelName() {
    return "Badge";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Badge for dynamic purposes.
  **/
  public static factory(data: BadgeInterface): Badge{
    return new Badge(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Badge',
      plural: 'Badges',
      path: 'Badges',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'number'
        },
        "banner": {
          name: 'banner',
          type: 'string'
        },
        "desc": {
          name: 'desc',
          type: 'string'
        },
        "archived": {
          name: 'archived',
          type: 'boolean',
          default: false
        },
        "color": {
          name: 'color',
          type: 'string',
          default: '#35375E'
        },
      },
      relations: {
      }
    }
  }
}
