/* tslint:disable */
import {
  Job,
  Badge
} from '../index';

declare var Object: any;
export interface FlagInterface {
  "badgeid": number;
  "jobid": number;
  "active": boolean;
  "comment"?: string;
  "id"?: number;
  parentjob?: Job;
  badgedetails?: Badge;
}

export class Flag implements FlagInterface {
  "badgeid": number;
  "jobid": number;
  "active": boolean;
  "comment": string;
  "id": number;
  parentjob: Job;
  badgedetails: Badge;
  constructor(data?: FlagInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Flag`.
   */
  public static getModelName() {
    return "Flag";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Flag for dynamic purposes.
  **/
  public static factory(data: FlagInterface): Flag{
    return new Flag(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Flag',
      plural: 'Flags',
      path: 'Flags',
      idName: 'id',
      properties: {
        "badgeid": {
          name: 'badgeid',
          type: 'number'
        },
        "jobid": {
          name: 'jobid',
          type: 'number'
        },
        "active": {
          name: 'active',
          type: 'boolean',
          default: false
        },
        "comment": {
          name: 'comment',
          type: 'string',
          default: 'null'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        parentjob: {
          name: 'parentjob',
          type: 'Job',
          model: 'Job',
          relationType: 'belongsTo',
                  keyFrom: 'jobid',
          keyTo: 'id'
        },
        badgedetails: {
          name: 'badgedetails',
          type: 'Badge',
          model: 'Badge',
          relationType: 'hasOne',
                  keyFrom: 'badgeid',
          keyTo: 'id'
        },
      }
    }
  }
}
