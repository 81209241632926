import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
/**
 * Publish and pass data (replace deprecated Ionic Events)
 *
 * @export
 * @class EventService
 */
@Injectable({
  providedIn: 'root'
})
export class EventService {

  private refreshJobOverviewSource = new Subject();
  refreshJobOverviewSource$ = this.refreshJobOverviewSource.asObservable();

  private refreshJobTableSource = new Subject();
  refreshJobTableSource$ = this.refreshJobTableSource.asObservable();

  private openJobModalSource = new Subject();
  openJobModalSource$ = this.openJobModalSource.asObservable();

  private openClientModalSource = new Subject();
  openClientModalSource$ = this.openClientModalSource.asObservable();

  private closeFinanceModalSource = new Subject();
  closeFinanceModalSource$ = this.closeFinanceModalSource.asObservable();

  private updateJobFlagSource = new Subject();
  updateJobFlagSource$ = this.updateJobFlagSource.asObservable();

  private onJobMarkerClickSource = new BehaviorSubject(null);
  onJobMarkerClickSource$ = this.onJobMarkerClickSource.asObservable();

  private unassignTaskSource = new Subject();
  unassignTaskSource$ = this.unassignTaskSource.asObservable();

  private assignTaskSource = new Subject();
  assignTaskSource$ = this.assignTaskSource.asObservable();

  private createTaskSource = new Subject();
  createTaskSource$ = this.createTaskSource.asObservable();

  private updateTaskSource = new Subject();
  updateTaskSource$ = this.updateTaskSource.asObservable();

  private invalidateMapSource = new Subject();
  invalidateMapSource$ = this.invalidateMapSource.asObservable();

  private updateOperationSource = new BehaviorSubject(null);
  updateOperationSource$ = this.updateOperationSource.asObservable();

  private completeJobCreatedSource = new BehaviorSubject(null);
  completeJobCreatedSource$ = this.completeJobCreatedSource.asObservable();

  private updateActiveJobSource = new BehaviorSubject(null);
  updateActiveJobSource$ = this.updateActiveJobSource.asObservable();

  private removeParcelDuringCreateSource = new BehaviorSubject(null);
  removeParcelDuringCreateSource$ = this.removeParcelDuringCreateSource.asObservable();

  private updateClientInfoSource = new BehaviorSubject(null);
  updateClientInfoSource$ = this.updateClientInfoSource.asObservable();

  private completeClientCreatedSource = new BehaviorSubject(null);
  completeClientCreatedSource$ = this.completeClientCreatedSource.asObservable();

  private completeInvoiceCreatedSource = new BehaviorSubject(null);
  completeInvoiceCreatedSource$ = this.completeInvoiceCreatedSource.asObservable();

  private updateRolePermissionsSource = new Subject();
  updateRolePermissionsSource$ = this.updateRolePermissionsSource.asObservable();

  private getLayersDataSource = new Subject();
  getLayersDataSource$ = this.getLayersDataSource.asObservable();


  private updateStageSource = new Subject();
  updateStageSource$ = this.updateStageSource.asObservable();

  private onLayerUpdateSource = new Subject();
  onLayerUpdateSource$ = this.onLayerUpdateSource.asObservable();

  private updatePaymentTableSource = new BehaviorSubject(null);
  updatePaymentTableSource$ = this.updatePaymentTableSource.asObservable();

  constructor() { }

  publishRefreshJobOverview(): void {
    this.refreshJobOverviewSource.next();
    console.log(this.publishRefreshJobOverview.name);
  }

  publishRefreshJobTable(): void {
    this.refreshJobTableSource.next();
    console.log(this.publishRefreshJobTable.name);
  }

  publishOpenJobModal(): void {
    this.openJobModalSource.next();
    console.log(this.publishOpenJobModal.name);
  }

  publishOpenClientModal(): void {
    this.openClientModalSource.next();
    console.log(this.publishOpenClientModal.name);
  }

  publishCloseFinanceModal(): void {
    this.closeFinanceModalSource.next();
    console.log(this.publishCloseFinanceModal.name);
  }

  publishUpdateJobFlag(): void {
    this.updateJobFlagSource.next();
    console.log(this.publishUpdateJobFlag.name);
  }

  publishOnJobMarkerClick(jobId: Number): void {
    this.onJobMarkerClickSource.next(jobId);
    console.log(this.publishOnJobMarkerClick.name);
  }

  publishUnassignTask(id: any): void {
    this.unassignTaskSource.next(id);
    console.log(`${this.publishUnassignTask.name}`);
  }

  publishAssignTask(id: any): void {
    this.assignTaskSource.next(id);
    console.log(`${this.publishAssignTask.name}`);
  }

  publishInvalidateMap(): void {
    this.invalidateMapSource.next();
    console.log(`${this.publishInvalidateMap.name}`);
  }

  publishUpdateOperation(data: any): void {
    this.updateOperationSource.next(data);
    console.log(`${this.publishUpdateOperation.name}`);
  }

  publishCompleteJobCreated(data: any): void {
    this.completeJobCreatedSource.next(data);
    console.log(`${this.publishCompleteJobCreated.name}`);
  }

  publishUpdateActiveJob(data: any): void {
    this.updateActiveJobSource.next(data);
    console.log(`${this.publishUpdateActiveJob.name}`);
  }

  publishRemoveParcelDuringCreate(n: Number): void {
    this.removeParcelDuringCreateSource.next(n);
    console.log(`${this.publishRemoveParcelDuringCreate.name}`);
  }

  publishUpdateClientInfo(data: any): void {
    this.updateClientInfoSource.next(data);
    console.log(`${this.publishUpdateClientInfo.name}`);
  }

  publishCompleteClientCreated(data: any): void {
    this.completeClientCreatedSource.next(data);
    console.log(`${this.publishCompleteClientCreated.name}`);
  }

  publishCompleteInvoiceCreated(data: any): void {
    this.completeInvoiceCreatedSource.next(data);
    console.log(`${this.publishCompleteInvoiceCreated.name}`);
  }

  publishUpdateRolePermissions(): void {
    this.updateRolePermissionsSource.next();
    console.log(`${this.publishUpdateRolePermissions.name}`);
  }

  publishGetLayersData(): void {
    this.getLayersDataSource.next();
    console.log(`${this.publishGetLayersData.name}`);
  }

  publishUpdateTask(): void {
    this.updateTaskSource.next();
    console.log(`${this.publishUpdateTask.name}`);
  }

  publishCreateTask(): void {
    this.createTaskSource.next();
    console.log(`${this.publishCreateTask.name}`);
  }

  publishUpdateStage(): void {
    this.updateStageSource.next();
    console.log(`${this.publishUpdateStage.name}`);
  }

  publishOnLayerUpdate(): void {
    this.onLayerUpdateSource.next();
    console.log(`${this.publishOnLayerUpdate.name}`);
  }

  publishUpdatePaymentTable(data: any): void {
    this.updatePaymentTableSource.next(data);
    console.log(`${this.publishUpdatePaymentTable.name}`);
  }
}
