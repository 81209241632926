/* tslint:disable */
import {
  GeoPoint
} from '../index';

declare var Object: any;
export interface CoordinatePointInterface {
  "coordinateparentdetailsId": number;
  "coordinateparentdetailsType"?: string;
  "pt": GeoPoint;
  "description"?: string;
  "datestring"?: string;
  "id"?: number;
  coordinatedetails?: any;
}

export class CoordinatePoint implements CoordinatePointInterface {
  "coordinateparentdetailsId": number;
  "coordinateparentdetailsType": string;
  "pt": GeoPoint;
  "description": string;
  "datestring": string;
  "id": number;
  coordinatedetails: any;
  constructor(data?: CoordinatePointInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `CoordinatePoint`.
   */
  public static getModelName() {
    return "CoordinatePoint";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of CoordinatePoint for dynamic purposes.
  **/
  public static factory(data: CoordinatePointInterface): CoordinatePoint{
    return new CoordinatePoint(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'CoordinatePoint',
      plural: 'coord',
      path: 'coord',
      idName: 'id',
      properties: {
        "coordinateparentdetailsId": {
          name: 'coordinateparentdetailsId',
          type: 'number'
        },
        "coordinateparentdetailsType": {
          name: 'coordinateparentdetailsType',
          type: 'string'
        },
        "pt": {
          name: 'pt',
          type: 'GeoPoint'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "datestring": {
          name: 'datestring',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        coordinatedetails: {
          name: 'coordinatedetails',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
                  keyFrom: 'coordinateparentdetailsId',
          keyTo: 'id'
        },
      }
    }
  }
}
