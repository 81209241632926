import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Router } from '@angular/router';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';

import { map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { AuthActionTypes, LogIn, LogInSuccess } from '../actions/auth.actions';

import * as _ from 'underscore';

@Injectable()
export class AuthEffects {

    constructor(
        private actions: Actions,
        private router: Router,
    ) { }

    @Effect()
    LogIn: Observable<any> = this.actions.pipe(
        ofType(AuthActionTypes.LOGIN),
        map((action: LogIn) => new LogInSuccess(action.payload)),
    );

    @Effect({ dispatch: false })
    LogInSuccess: Observable<any> = this.actions.pipe(
        ofType(AuthActionTypes.LOGIN_SUCCESS),
        tap((user) => {
            // sessionStorage.setItem('crdnl_user', JSON.parse(user));
        })
    );

    @Effect({ dispatch: false })
    LogOut: Observable<any> = this.actions.pipe(
        ofType(AuthActionTypes.LOGOUT),
        tap((user) => {
            // sessionStorage.remove('crdnl_user');
        })
    );
}

