import { ErrorHandler, Injectable } from '@angular/core';
import { AlertController, NavController } from '@ionic/angular';
import { EnvService } from '../services/env.service';
import { Ticket } from './ticket';
import { Observable } from 'rxjs';
import { CardinalToast } from './cardinal-toast';
import { take } from 'rxjs/operators';
import { DataServiceService } from '../services/data-service.service';

@Injectable()
export class CustomErrorHandler extends ErrorHandler {
	private errorObj: any;

	constructor(
		public alertCtrl: AlertController,
		protected env: EnvService,
		protected ticket: Ticket,
		protected toast: CardinalToast,
		protected navCtrl: NavController,
		protected dataService: DataServiceService
	) {
		super();
	}
	/**
	 * Generic PresentError function.
	 * @memberof CustomErrorHandler
	 */
	async presentError() {
		//handle different error code differently
		console.error(this.errorObj);
		switch (Number(this.errorObj['statusCode'])) {
			case 401: //unauthorized, most likely caused by race-condition when user logs out
				//suspress
				this.toast.showErrorToast(this.errorObj);
				console.log(this.errorObj);
				return;
			case 404:
				console.log('404 error, redirecting to login page');
				this.navCtrl.navigateRoot('/');
				return;
			default:
				if (this.errorObj['name'] === 'MissingPDFException') {
					this.toast.showErrorToast(this.errorObj);
				}
				break;
		}
		this.toast.showErrorToast(this.errorObj);
	}

	public setErrorObj(error: any) {
		this.errorObj = error;
	}

	public getErrorObj() {
		return this.errorObj;
	}
	/**
	 *This method collect error data and create a ticket. Send the ticket to Support ADO board if user initiates the request.
	 * This also doesn't show error in production and demo mode's console log
	 * @param {*} error - error object
	 * @param {*} [user=null] - User data (mostly from this.dataservice.client)
	 * @param {*} [additionalData=null] - additional data to be sent in the ticket
	 * @returns Promise<void> - return this.presentError method
	 * @memberof CustomErrorHandler
	 */
	public handleError(
		error: any,
		user: any = this.dataService.user,
		additionalData: any = null
	) {
		this.setErrorObj(error);
		this.ticket.setError(error);
		this.ticket.setTitle('User Error Report: ' + error['name']);
		if (user) {
			this.ticket.setUserInfo(user);
		}
		if (additionalData) {
			this.ticket.setPayload(additionalData);
		}

		console.error(error);
		if (error.statusCode !== undefined) {
			return this.presentError();
		} else {
			console.log('Undefined error occured.');
			// will not show toast here because of package error (mostly because of package's code)
			return;
		}
	}

	//this should only be used for known error that does not cause issues (data-dependency issue related to latency)
	public suppressError(error: any) {
		switch (this.env.NODE_ENV) {
			case 'production':
				//send message, report issue
				break;
			case 'demo':
			case 'development':
				console.log(error);
				break;
		}
	}

	handleClientError(error: any, user: any = null, additionalData: any = null) {}

	/**
	 * This is DEPRECATED since pre-beta. CardinalToast is now being used.
	 *
	 * @returns
	 * @memberof CustomErrorHandler
	 */
	async popupError() {
		const alert = await this.alertCtrl.create({
			header: 'Error ' + this.errorObj['statusCode'],
			subHeader: 'An unexpected error has occurred.',
			message:
				'If this is the first time you see this error, please send us a report. ',
			inputs: [
				{
					type: 'text',
					name: 'txtReportDescription',
					id: 'txtReportDescription',
					placeholder: 'Briefly tell us what happened...',
				},
			],
			buttons: [
				{
					text: 'Report Error',
					handler: (data) => {
						console.log('Sending an error report....');
						this.ticket.setDescription(data['txtReportDescription']);
						this.ticket
							.sendPostRequest()
							.pipe(take(1))
							.subscribe((res) => {
								if (res['id']) {
									console.log('Ticket created!');
								} else {
									console.log(res);
								}
							});
					},
				},
				{
					text: 'Close',
					role: 'cancel',
				},
			], //array
		});
		return await alert.present();
	}
}
