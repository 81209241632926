/* tslint:disable */
import {
  Job,
  Payment,
  Service
} from '../index';

declare var Object: any;
export interface InvoiceInterface {
  "jobid": number;
  "companyid"?: number;
  "clientid"?: number;
  "contactid"?: number;
  "billinginfoid"?: number;
  "iterationnumber"?: number;
  "taxrate": number;
  "subtotal": number;
  "taxableamount"?: number;
  "taxamount": number;
  "amountpaidtodate"?: number;
  "paidInFull": boolean;
  "total": number;
  "amountremaining": number;
  "paymentIds"?: Array<any>;
  "fileurl": string;
  "notes"?: string;
  "stripeinvoiceid"?: string;
  "id"?: number;
  "created"?: Date;
  "updatedAt"?: Date;
  job?: Job;
  payments?: Payment[];
  services?: Service[];
}

export class Invoice implements InvoiceInterface {
  "jobid": number;
  "companyid": number;
  "clientid": number;
  "contactid": number;
  "billinginfoid": number;
  "iterationnumber": number;
  "taxrate": number;
  "subtotal": number;
  "taxableamount": number;
  "taxamount": number;
  "amountpaidtodate": number;
  "paidInFull": boolean;
  "total": number;
  "amountremaining": number;
  "paymentIds": Array<any>;
  "fileurl": string;
  "notes": string;
  "stripeinvoiceid": string;
  "id": number;
  "created": Date;
  "updatedAt": Date;
  job: Job;
  payments: Payment[];
  services: Service[];
  constructor(data?: InvoiceInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Invoice`.
   */
  public static getModelName() {
    return "Invoice";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Invoice for dynamic purposes.
  **/
  public static factory(data: InvoiceInterface): Invoice{
    return new Invoice(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Invoice',
      plural: 'Invoices',
      path: 'Invoices',
      idName: 'id',
      properties: {
        "jobid": {
          name: 'jobid',
          type: 'number'
        },
        "companyid": {
          name: 'companyid',
          type: 'number'
        },
        "clientid": {
          name: 'clientid',
          type: 'number'
        },
        "contactid": {
          name: 'contactid',
          type: 'number'
        },
        "billinginfoid": {
          name: 'billinginfoid',
          type: 'number'
        },
        "iterationnumber": {
          name: 'iterationnumber',
          type: 'number'
        },
        "taxrate": {
          name: 'taxrate',
          type: 'number'
        },
        "subtotal": {
          name: 'subtotal',
          type: 'number'
        },
        "taxableamount": {
          name: 'taxableamount',
          type: 'number'
        },
        "taxamount": {
          name: 'taxamount',
          type: 'number'
        },
        "amountpaidtodate": {
          name: 'amountpaidtodate',
          type: 'number',
          default: 0
        },
        "paidInFull": {
          name: 'paidInFull',
          type: 'boolean',
          default: false
        },
        "total": {
          name: 'total',
          type: 'number'
        },
        "amountremaining": {
          name: 'amountremaining',
          type: 'number'
        },
        "paymentIds": {
          name: 'paymentIds',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "fileurl": {
          name: 'fileurl',
          type: 'string'
        },
        "notes": {
          name: 'notes',
          type: 'string'
        },
        "stripeinvoiceid": {
          name: 'stripeinvoiceid',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "created": {
          name: 'created',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        job: {
          name: 'job',
          type: 'Job',
          model: 'Job',
          relationType: 'belongsTo',
                  keyFrom: 'jobid',
          keyTo: 'id'
        },
        payments: {
          name: 'payments',
          type: 'Payment[]',
          model: 'Payment',
          relationType: 'referencesMany',
                  keyFrom: 'paymentIds',
          keyTo: 'id'
        },
        services: {
          name: 'services',
          type: 'Service[]',
          model: 'Service',
          relationType: 'hasMany',
          modelThrough: 'Job',
          keyThrough: 'serviceId',
          keyFrom: 'id',
          keyTo: 'jobid'
        },
      }
    }
  }
}
