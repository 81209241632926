/* tslint:disable */
import { Injectable } from '@angular/core';
import { StreetAddress } from '../../models/StreetAddress';
import { Parcel } from '../../models/Parcel';
import { ContactInfo } from '../../models/ContactInfo';
import { Company } from '../../models/Company';
import { BillingInfo } from '../../models/BillingInfo';
import { Position } from '../../models/Position';
import { Permissions } from '../../models/Permissions';
import { Stage } from '../../models/Stage';
import { Task } from '../../models/Task';
import { Office } from '../../models/Office';
import { Job } from '../../models/Job';
import { Badge } from '../../models/Badge';
import { Invoice } from '../../models/Invoice';
import { Payment } from '../../models/Payment';
import { MultiAccessToken } from '../../models/MultiAccessToken';
import { Operation } from '../../models/Operation';
import { Service } from '../../models/Service';
import { Flag } from '../../models/Flag';
import { Layer } from '../../models/Layer';
import { Polygon } from '../../models/Polygon';
import { CoordinatePoint } from '../../models/CoordinatePoint';
import { Line } from '../../models/Line';
import { Deliverable } from '../../models/Deliverable';
import { Azure } from '../../models/Azure';
import { Cardinalconfig } from '../../models/Cardinalconfig';
import { ClientCompany } from '../../models/ClientCompany';
import { JobParcel } from '../../models/JobParcel';
import { Client } from '../../models/Client';
import { Employee } from '../../models/Employee';

export interface Models { [name: string]: any }

@Injectable()
export class SDKModels {

  private models: Models = {
    StreetAddress: StreetAddress,
    Parcel: Parcel,
    ContactInfo: ContactInfo,
    Company: Company,
    BillingInfo: BillingInfo,
    Position: Position,
    Permissions: Permissions,
    Stage: Stage,
    Task: Task,
    Office: Office,
    Job: Job,
    Badge: Badge,
    Invoice: Invoice,
    Payment: Payment,
    MultiAccessToken: MultiAccessToken,
    Operation: Operation,
    Service: Service,
    Flag: Flag,
    Layer: Layer,
    Polygon: Polygon,
    CoordinatePoint: CoordinatePoint,
    Line: Line,
    Deliverable: Deliverable,
    Azure: Azure,
    Cardinalconfig: Cardinalconfig,
    ClientCompany: ClientCompany,
    JobParcel: JobParcel,
    Client: Client,
    Employee: Employee,
    
  };

  public get(modelName: string): any {
    return this.models[modelName];
  }

  public getAll(): Models {
    return this.models;
  }

  public getModelNames(): string[] {
    return Object.keys(this.models);
  }
}
