import { Injectable } from '@angular/core';

@Injectable()
export class EnvService {
	// The values that are defined here are the default values that can
	// be overridden by env.js

	public apiUrl = '';

	public HOST_NAME = 'localhost';
	public NODE_ENV = 'development';
	public HOST_PORT = '3000';
	public STRIPE_ENABLE = 0;
	public demouser = 'owner1@email.inc';
	public demopw = '0000';
	public HTTPS = false;

	constructor() {}
}
