/* tslint:disable */
import {
  Parcel
} from '../index';

declare var Object: any;
export interface PolygonInterface {
  "parentid"?: number;
  "parentlayerType"?: string;
  "parentlayer": string;
  "geomstring": string;
  "geog": any;
  "centerlat": number;
  "centerlon": number;
  "pipvalue"?: string;
  "geomarea"?: number;
  "id"?: number;
  "created"?: Date;
  "updated"?: Date;
  polygon?: any;
  parcel?: Parcel;
}

export class Polygon implements PolygonInterface {
  "parentid": number;
  "parentlayerType": string;
  "parentlayer": string;
  "geomstring": string;
  "geog": any;
  "centerlat": number;
  "centerlon": number;
  "pipvalue": string;
  "geomarea": number;
  "id": number;
  "created": Date;
  "updated": Date;
  polygon: any;
  parcel: Parcel;
  constructor(data?: PolygonInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Polygon`.
   */
  public static getModelName() {
    return "Polygon";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Polygon for dynamic purposes.
  **/
  public static factory(data: PolygonInterface): Polygon{
    return new Polygon(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Polygon',
      plural: 'Polygons',
      path: 'Polygons',
      idName: 'id',
      properties: {
        "parentid": {
          name: 'parentid',
          type: 'number',
          default: 0
        },
        "parentlayerType": {
          name: 'parentlayerType',
          type: 'string'
        },
        "parentlayer": {
          name: 'parentlayer',
          type: 'string'
        },
        "geomstring": {
          name: 'geomstring',
          type: 'string'
        },
        "geog": {
          name: 'geog',
          type: 'any'
        },
        "centerlat": {
          name: 'centerlat',
          type: 'number'
        },
        "centerlon": {
          name: 'centerlon',
          type: 'number'
        },
        "pipvalue": {
          name: 'pipvalue',
          type: 'string',
          default: 'null'
        },
        "geomarea": {
          name: 'geomarea',
          type: 'number',
          default: 0
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "created": {
          name: 'created',
          type: 'Date'
        },
        "updated": {
          name: 'updated',
          type: 'Date'
        },
      },
      relations: {
        polygon: {
          name: 'polygon',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
                  keyFrom: 'parentid',
          keyTo: 'id'
        },
        parcel: {
          name: 'parcel',
          type: 'Parcel',
          model: 'Parcel',
          relationType: 'hasOne',
                  keyFrom: 'parentid',
          keyTo: 'recordid'
        },
      }
    }
  }
}
