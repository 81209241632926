/* tslint:disable */
import {
  ContactInfo
} from '../index';

declare var Object: any;
export interface OfficeInterface {
  "id": number;
  "name": string;
  "shortname": string;
  "logo"?: any;
  "taxrate": number;
  "jobmaskprefix"?: string;
  "nextjobnumber"?: number;
  contactinfo?: ContactInfo;
}

export class Office implements OfficeInterface {
  "id": number;
  "name": string;
  "shortname": string;
  "logo": any;
  "taxrate": number;
  "jobmaskprefix": string;
  "nextjobnumber": number;
  contactinfo: ContactInfo;
  constructor(data?: OfficeInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Office`.
   */
  public static getModelName() {
    return "Office";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Office for dynamic purposes.
  **/
  public static factory(data: OfficeInterface): Office{
    return new Office(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Office',
      plural: 'Offices',
      path: 'Offices',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'number'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "shortname": {
          name: 'shortname',
          type: 'string'
        },
        "logo": {
          name: 'logo',
          type: 'any'
        },
        "taxrate": {
          name: 'taxrate',
          type: 'number'
        },
        "jobmaskprefix": {
          name: 'jobmaskprefix',
          type: 'string'
        },
        "nextjobnumber": {
          name: 'nextjobnumber',
          type: 'number',
          default: 0
        },
      },
      relations: {
        contactinfo: {
          name: 'contactinfo',
          type: 'ContactInfo',
          model: 'ContactInfo',
          relationType: 'hasOne',
                  keyFrom: 'id',
          keyTo: 'contactdetailsId'
        },
      }
    }
  }
}
