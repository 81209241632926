import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Ticket } from './ticket';
import { take } from 'rxjs/operators';
import { pipe } from 'rxjs';
import * as _ from 'underscore';

/**
 * Position on the screen where the toast will show up
 *
 * @export
 * @enum {number}
 */
export enum TOAST_POSITION {
	TOP = 'top',
	MIDDLE = 'middle',
	BOTTOM = 'bottom',
}

/**
 * Different toast types will show different color (success, error, warning, info)
 *
 * @export
 * @enum {number}
 */
export enum TOAST_TYPE {
	SUCCESS = 'success',
	WARNING = 'warning',
	ERROR = 'danger',
	INFO = 'light',
}

@Injectable()
export class CardinalToast {
	constructor(public toastCtrl: ToastController, protected ticket: Ticket) {}

	/**
	 * Show a generic toast message - default duration: 4000. This extends default ToastController.
	 *
	 * @param {string} msg - toast message
	 * @param {TOAST_TYPE} [msgType=TOAST_TYPE.TOAST_TYPE.SUCCESS] - toast message type suchs as error, success, warning, and info.
	 * @param {TOAST_POSITION} [toastPosition=TOAST_POSITION.BOTTOM] - top or bottom, use TOAST_POSITION enum
	 * @memberof CardinalToast
	 */
	async showToast(
		msg: string,
		msgType: TOAST_TYPE = TOAST_TYPE.SUCCESS,
		toastPosition: TOAST_POSITION = TOAST_POSITION.BOTTOM
	) {
		const toast = await this.toastCtrl.create({
			message: msg,
			position: toastPosition,
			color: msgType,
			duration: 3500,
		});
		toast.present();
	}

	/**
	 * Show toast message for error handling
	 *
	 * @param {*} errorObj
	 * @param {TOAST_POSITION} [toastPosition=TOAST_POSITION.BOTTOM]
	 * @returns
	 * @memberof CardinalToast
	 */
	async showErrorToast(
		errorObj: any,
		toastPosition: TOAST_POSITION = TOAST_POSITION.BOTTOM
	) {
		let msg = 'There is an unexpected error.';
		this.ticket.setError(errorObj);
		console.error(errorObj);
		switch (Number(errorObj['statusCode'])) {
			case 401:
				msg = 'Unauthorized access.';
				break;
			case 404:
				msg = 'This page does not exist.';
				break;
			case 422:
				msg = _.reduce(
					errorObj.details.messages,
					(memo, messg) => {
						if (messg.length > 0) {
							return memo + messg[0] + '. ';
						} else {
							return memo;
						}
					},
					''
				);
				break;
			case 500:
				msg = 'Internal Server Error.';
				console.log(errorObj);
				break;
			default:
				console.log('Error: ' + errorObj['statusCode']);
				if (errorObj['name'] === 'MissingPDFException') {
					msg =
						'There is a problem retrieving the invoice pdf file, please try again later or submit a bug report.';
				}
				break;
		}

		const toast = await this.toastCtrl.create({
			message: `Error ${errorObj['statusCode']} - ${msg}`,
			position: toastPosition,
			color: TOAST_TYPE.ERROR,
			duration: 6000,
			buttons: [
				{
					side: 'end',
					icon: 'send',
					text: 'Report',
					handler: () => {
						this.ticket.setDescription('Report sent by User');
						this.ticket
							.sendPostRequest()
							.pipe(take(1))
							.subscribe((res) => {
								if (res['id']) {
									console.log('Ticket sent!');
								} else {
									console.log('Ticket was not sent!');
								}
							});
					},
				},
			],
		});

		toast.onDidDismiss().then(() => {});

		toast.present();
	}
}
