import _ from 'underscore';
import { loadModules } from 'esri-loader';
import { CardinalToast, TOAST_TYPE } from '../classes/cardinal-toast';
import { ToastController } from '@ionic/angular';
import { Ticket } from './ticket';

export class EsriMap {
  mapViewEl: any = null;
  map: any = null;
  center: Array<number> = [-94.922562, 29.487425];
  zoom: number = 10;
  mapLoaded: boolean = false;
  view: any = null;
  featureLayers = []; // this holds the feature layers that are generated by the developer
  featureServices = []; // this holds the esri feature services
  isLegendReady = false;
  readonly ESRI_PRINT_URL: string =
      'https://utility.arcgisonline.com/arcgis/rest/services/Utilities/PrintingTools/GPServer/Export%20Web%20Map%20Task';
  readonly mapDatatypes = [
    { esriType: 'double', type: 'number'},
    { esriType: 'string', type: 'string'}
  ];
  legendExpand: any = null;
  layerVisibilityExpand: any = null;
  printExpand: any = null;
  isFilterReady = false;
  isSearchReady = false;
  originalFeatures = []; // features in featureLayers in order (order matters here)
  readonly MIN_ZOOM: number = 16; // Zoom level in esri goes by integers, the higher the number the more zoomed in the user is
  readonly POLYGON_STYLE = [50, 98, 168, 0.5]; // r, g, b, opacity (0 - 1) // blue, opacity 50%
  constructor() {}

  /**
   * Creates an esri map given an element.
   *
   * @param {*} mapViewEl the viewchild element of the div where the map will go
   * @returns
   * @memberof EsriMap
   */
  async initializeMap(mapViewEl: any) {
    this.mapViewEl = mapViewEl;
    const [Map, MapView] = await loadModules([
      'esri/Map',
      'esri/views/MapView'
    ]);
    // Configure the Map
    const mapProperties = {
      basemap: 'hybrid'
    };

    this.map = new Map(mapProperties);

    // Initialize the MapView
    const mapViewProperties = {
      container: this.mapViewEl.nativeElement,
      center: this.center,
      zoom: this.zoom,
      map: this.map
    };

    this.view = new MapView(mapViewProperties);

    // This won't let the user activate the parcel layers until they are past the minimum zoom level
    this.view.watch('zoom', (ev) => {
      if (this.view.zoom < this.MIN_ZOOM) {
        _.each(this.featureServices, (service) => {
          service.visible = false;
        });
      }
    });

    await this.view.when();  // wait for the map to load
    return this.view;
  }

  /**
   * Initializes and creates a list of feature layer services.
   *
   * @param {*} featureServices a list of feature services from db
   * @param {*} [popupTemplate=null] include a popup template if you want one
   * @param {boolean} [isVisible=false] set this to true if you want the layer to load automatically
   * @memberof EsriMap
   */
  async initializeFeatureServices(featureServices, popupTemplate: any = null, isVisble = false) {
    // Load the modules for the ArcGIS API for JavaScript
    const [FeatureLayer] = await loadModules([
      'esri/layers/FeatureLayer'
    ]);

    this.featureServices = _.map(featureServices, (featureService, index) => {
      const layer = new FeatureLayer({
        url: featureService.featureserviceurl,
        spatialReference: 4326,
        title: featureService.name,
        // renderer: {
        //   // 72,61,139
        //   // 	30,144,255
        //   type: 'simple',  // autocasts as new SimpleRenderer()
        //   symbol: {
        //     type: 'simple-fill',  // autocasts as new SimpleFillSymbol()
        //     color: [72, 61, 139, 0.2],
        //     style: 'solid',
        //     outline: {  // autocasts as new SimpleLineSymbol()
        //       color: [72, 61, 139],
        //       width: 1
        //     }
        //   }
        // }
      });
      layer.visible = isVisble; // Default to hidden
      layer.popupTemplate = popupTemplate;

      layer.watch('visible', (ev) => {
        // When map is destroyed, this.view.zoom is -1
        if (this.view.zoom < this.MIN_ZOOM && this.view.zoom !== -1) {
          layer.visible = false;
          const toast = new CardinalToast(new ToastController(), null);
          toast.showToast('Zoom in closer to enable the layer.', TOAST_TYPE.WARNING);
        }
      });

      return layer;
    });

    this.map.layers.addMany(this.featureServices);
  }

  /**
   * Initializes and adds a legend and layer visibility
   *
   * @memberof EsriMap
   */
  async initializeLegends() {
    // Load the modules for the ArcGIS API for JavaScript
    const [Expand, LayerList, Legend, Print] = await loadModules([
      'esri/widgets/Expand',
      'esri/widgets/LayerList',
      'esri/widgets/Legend',
      'esri/widgets/Print'
    ]);

    // Creates printer expand
    this.printExpand = new Expand({
      content: new Print({
          view: this.view,
          // specify your own print service
          printServiceUrl: this.ESRI_PRINT_URL
        }),
        view: this.view,
        expanded: false
      });

    // Creates legend
    const legend = new Legend({
        view: this.view,
        layerInfos: _.map(this.featureLayers, (featLayer) => {
            return {layer: featLayer, title: featLayer.title};
        }),
        container: document.createElement('div')
    });

    // Creates layer list visibility
    const layerListVisibility = new LayerList({
        container: document.createElement('div'),
        view: this.view
    });

    this.layerVisibilityExpand = new Expand({
        expandIconClass: 'esri-icon-layers',
        view: this.view,
        content: layerListVisibility.domNode
    });

    this.legendExpand = new Expand({
      expandIconClass: 'esri-icon-documentation',
      view: this.view,
      content: legend.domNode
    });

    this.view.ui.add(this.legendExpand, 'top-right');
    this.view.ui.add(this.layerVisibilityExpand, 'top-right');
    this.view.ui.add(this.printExpand, 'top-left');
    this.isLegendReady = true;
  }

  /**
   * Creates a new layer and adds it to the map.
   *
   * @param {Array<any>} features an array of objects, such as jobs. Each object should have obj.center.lat, obj.center.lon
   * @param {string} layerName the name/title of the new layer
   * @param {string} [imageUrl='http://static.arcgis.com/images/Symbols/Shapes/BluePin1LargeB.png'] an image url for the marker point
   * @param {boolean} [isVisible=true] if the layer is visible, set this to true
   * @param {*} [popupTemplate=null] include popup template object if you want a popup
   * @param {*} [labelClass=null] include label class if you want points labeled
   * @memberof EsriMap
   */
  async addNewLayer(features: Array<any>, layerName: string, imageUrl: string = 'http://static.arcgis.com/images/Symbols/Shapes/BluePin1LargeB.png',
  isVisible: boolean = true, popupTemplate: any = null, labelClass: any = null, geometryType: string = 'point') {
    const [FeatureLayer, Graphic, PictureMarkerSymbol] = await loadModules([
      'esri/layers/FeatureLayer',
      'esri/Graphic',
      'esri/symbols/PictureMarkerSymbol'
    ]);
    const featureLayerOptions = {
      visible: isVisible,
      source: _.map(features, (feature) => {
        // console.log(feature);
        return new Graphic(this.createFeatureGraphics(feature, geometryType));
      }),
      featureReduction: null,
      objectIdField: 'OBJECTID',
      title: layerName,
      labelingInfo: labelClass ? labelClass : null,
      fields: this.buildFields(features[0]),
      geometryType: geometryType,
      renderer: null,
      popupTemplate: popupTemplate
    };

    // Different geometry types need different renderers
    switch (geometryType) {
      case 'point':
        featureLayerOptions.renderer = {  // overrides the layer's default renderer
        type: 'simple',
        symbol: new PictureMarkerSymbol ({
          url: imageUrl,
          width: '25px',
          height: '25px',
          // xoffset: 10,
          yoffset: 5
        })
      };
        break;
      case 'polygon':
        // delete featureLayerOptions.renderer;
        featureLayerOptions.renderer = {
          type: 'simple',  // autocasts as new SimpleRenderer()
          symbol: {
            type: 'simple-fill',  // autocasts as new SimpleFillSymbol()
            color: [173, 216, 230, 0.5],
            style: 'solid',
            outline: {  // autocasts as new SimpleLineSymbol()
              color: [173, 216, 230],
              width: 1
            }
          }
        };
        break;
    }

    const layer = new FeatureLayer(featureLayerOptions);

    this.map.layers.add(layer);
    this.featureLayers.push(layer);

    // Keeps from overflowing original features array
    if (this.originalFeatures.length < this.featureLayers.length) {
      this.originalFeatures.push(layer.source.items);
      // console.log(this.originalFeatures);
    }
  }

  /**
   * Creates an object that will be used later to create new point graphics for the map.
   *
   * @param {*} feature a dataObject that contains center object
   * @returns
   * @memberof EsriMap
   */
  createNewPointGraphic(feature) {
    return {
      geometry: {
        type: 'point',
        longitude: feature.center.lon,
        latitude:  feature.center.lat
      },
      attributes: feature
    };
  }

  /**
   * Returns polygon object, not currently working.
   *
   * @param {*} feature an object containing data and a geometry string for polygons
   * @returns an object
   * @memberof EsriMap
   */
  createNewPolygonGraphic(feature) {
    if (!feature.rings) {
      return null;
    }

    return {
      geometry: {
        type: 'polygon',
        rings: feature.rings,
      },
      attributes: feature
    };
  }

  /**
   * Creates a feature graphic for feature layers
   *
   * @param {*} feature an object containing center and other data
   * @param {string} geometryType 'point' or 'polygon'
   * @returns
   * @memberof EsriMap
   */
  createFeatureGraphics(feature, geometryType: string) {
    switch (geometryType) {
      case 'point':
        return this.createNewPointGraphic(feature);
      case 'polygon':
        return this.createNewPolygonGraphic(feature);
      default:
        return [];
    }
  }

  /**
   * Generates a random color, used for esri points.
   *
   * @returns {Array<number>}
   * @memberof EsriMap
   */
  randomColor(): Array<number> {
    const x = Math.floor(Math.random() * 256);
    const y = Math.floor(Math.random() * 256);
    const z = Math.floor(Math.random() * 256);
    return [x, y, z];
  }

  /**
   * Builds a fields array that can be used for defining the `fields` in a feature layer.
   *
   * @param {*} feature an element of the map points attributes, for a job it would be {id, mask, comments, etc}
   * @returns {Array<any>}
   * @memberof EsriMap
   */
  buildFields(feature): Array<any> {
    const values = _.values(feature);
    const keys = _.keys(feature);
    let fields = _.map(values, (val, index) => {
      const dataType = _.find(this.mapDatatypes, (dataType) => typeof val === dataType.type);

      if (dataType) {
        return { name: keys[index], type: dataType.esriType };
      }

      return null;
    });

    fields = _.filter(fields);
    fields.push({name: 'OBJECTID', type: 'oid'}); // Need to add this, esri always requires this
    return fields;
  }

  /**
   * Finds the layer by title.
   *
   * @param {string} title the title of the layer you want to find
   * @returns
   * @memberof EsriMap
   */
  findLayer(title: string) {
    return _.find(this.featureLayers, (layer) => layer.title.toLowerCase() === title.toLowerCase());
  }

  /**
   * Updates a feature layer by removing the layer and recreating a new one.
   *
   * @param {*} features all features that you want to be on the new layer
   * @param {string} layerTitle the title of the layer
   * @param {*} [labelClass] include the object if you want labels added to the features, check esri docs for information on label classes
   * @param {boolean} [shouldUpdateLegend=true] leave blank if you want the legends to be reinitialized
   * @returns
   * @memberof EsriMap
   */
  async updateFeatureLayer(features, layerTitle: string, labelClass?, shouldUpdateLegend: boolean = true) {
    if (!layerTitle) {
      return; // Need title of layer to find the layer
    }

    const layer = this.findLayer(layerTitle);

    if (!layer) {
      console.log('Cannot find feature layer');
      return; // Can't find feature layer
    }

    this.removeLayer(layer);

    // If no features were provided, still create the feature layer so that we have it
    if (!features || features.length === 0) {
      this.addNewLayer([], layerTitle, layer.renderer.symbol.url, true, layer.popupTemplate, labelClass);
    } else {
      this.addNewLayer(features, layerTitle, layer.renderer.symbol.url, true, layer.popupTemplate, labelClass);
    }

    // Only update the legend if the programmer wants you to
    if (shouldUpdateLegend) {
      this.updateLegend();
    }
  }

  /**
   * Removes the layer from the map and the global array holding feature layers.
   *
   * @param {*} layer a layer object from featureLayers
   * @memberof EsriMap
   */
  removeLayer(layer) {
    if (!layer) {
      return;
    }

    this.map.layers.remove(layer); // Remove layer
    this.featureLayers = _.reject(this.featureLayers, (lay) => lay.id === layer.id);
  }

  /**
   * Updates the legends by removing them from the UI and reinitializing them.
   *
   * @memberof EsriMap
   */
  updateLegend() {
    console.log('Update legend called');
    this.view.ui.remove(this.legendExpand);
    this.view.ui.remove(this.layerVisibilityExpand);
    this.view.ui.remove(this.printExpand);
    this.legendExpand = null;
    this.layerVisibilityExpand = null;
    this.printExpand = null;
    this.initializeLegends();
  }

  /**
   * Moves the center of the map and zooms in on the location.
   *
   * @param {number} lat latitude
   * @param {number} lon longitude
   * @memberof EsriMapLayersComponent
   */
  moveCenter(lat: number, lon: number) {
    this.center = [lon, lat];
    this.view.goTo({
      target: this.center,
      zoom: 14
    });
  }

  /**
   * Creates a filter UI that the user can use to filter features. Programmer must define custom function for filtering.
   *
   * @param {string} elementId
   * @memberof EsriMap
   */
  async addCheckBoxFilter(elementId: string) {
    const [Expand] = await loadModules([
      'esri/widgets/Expand'
    ]);

    // Get the div programmer defined and make it visible
    const filter = document.getElementById(elementId);
    filter.style.visibility = 'visible';

    // Create the expand
    const filterExpand = new Expand({
      expandIconClass: 'esri-icon-filter',
      view: this.view,
      content: filter,
      expanded: false
    });

    this.view.ui.add(filterExpand, 'top-left'); // Add to the top left
    this.isFilterReady = true;
  }

  /**
   * Adds esri search expand to a map.
   *
   * @param {string} elementId the dom id of the div containing the ion-input
   * @memberof EsriMap
   */
  async addEsriSearch(elementId: string) {
    const [Expand] = await loadModules([
      'esri/widgets/Expand'
    ]);

    // Get the div programmer defined and make it visible
    const search = document.getElementById(elementId);
    if (search) {
      search.style.visibility = 'visible';
    }
    // Create the expand
    const searchExpand = new Expand({
      expandIconClass: 'esri-icon-search',
      view: this.view,
      content: search,
      expanded: false
    });

    this.view.ui.add(searchExpand, 'top-left'); // Add to the top left
    this.isSearchReady = true;
  }

  /**
   * Add graphics to the map, going to be orange circles.
   *
   * @param {*} features an array of objects that will be put on the map
   * @memberof EsriMap
   */
  async addGraphics(features) {
    const [Graphic] = await loadModules([
      'esri/Graphic'
    ]);

    const markerSymbol = {
      type: 'simple-marker', // autocasts as new SimpleMarkerSymbol()
      color: [226, 119, 40],
      outline: {
        // autocasts as new SimpleLineSymbol()
        color: [255, 255, 255],
        width: 2
      }
    };

    const points = _.map(features, (feat) => {
      const point = this.createNewPointGraphic(feat);
      point['symbol'] = markerSymbol;
      return new Graphic(point);
    });

    this.view.graphics.addMany(points);
  }

  /**
   * 
   *
   * @param {Array<Array<any>>} features a nested array of features [layerFeatures1, layerFeatures2, layerFeatures3, ...]
   * @param {*} layer a layer (usually from featureLayers)
   * @returns
   * @memberof EsriMap
   */
  findFeaturesForLayer(features: Array<Array<any>>, layer: any) {
    if (!features || !_.isArray(features) || features.length === 0) {
      return null;
    }

    return _.find(features, (feats) => layer.title === feats[0].layer.title);
  }

  /**
   * Apply edits to the feature layer by filtering out features that do not match the search value provided.
   *
   * @param {string} searchValue search value in a text box
   * @param {Array<any>} [filteredFeatures=null] provide this if you have a filter on your map
   * @param {Array<string>} [keys=['parcelIdsLabel', 'legalDescription']] attribute keys to compare search value with
   * @returns
   * @memberof EsriMap
   */
  async searchFeatureLayers(searchValue: string, filteredFeatures: Array<any> = null,
      keys: Array<string> = ['parcelIdsLabel', 'legalDescription']) {
    searchValue = searchValue.trim().toLowerCase();
    const searchResults = {total: _.flatten(this.originalFeatures).length, found: 0, delete: 0};

    // If search is empty, re-add all the features to each layer
    if (searchValue === '') {
      let index = 0;
      for (const layer of this.featureLayers) {
        // Getting features to add
        let addFeatures = this.findFeaturesForLayer(filteredFeatures, layer);
        addFeatures = !addFeatures ? this.findFeaturesForLayer(this.originalFeatures, layer) : addFeatures;
        searchResults.found += addFeatures.length;

        // Getting all features
        const result = await layer.queryFeatures();
        searchResults.delete += result.features.length;
        const edits = {
          deleteFeatures: result.features,
          addFeatures: addFeatures
        };

        await layer.applyEdits(edits);
        index++;
      }

      return searchResults;
    }

    // Finding features that need to be deleted
    for (const layer of this.featureLayers) {
      // Features to filter through, limits the number of features we have to search through
      let features = this.findFeaturesForLayer(filteredFeatures, layer);
      features = !features ? this.findFeaturesForLayer(this.originalFeatures, layer) : features;

      // Actual search is here
      const featuresToDelete = _.filter(features, (feat) => {
        const attributes = feat.attributes; // object values to the feature
        // Returning features that do not match the search value
        return !_.find(keys, (key) => {
          // Check to see if key exists on feat
          if (!attributes[key]) {
            return false;
          }

          // Convert array to string if its an array
          attributes[key] = _.isArray(attributes[key]) ? attributes[key].join(' ') : attributes[key];
          attributes[key] = typeof attributes[key] === 'string' ? attributes[key] : attributes[key].toString(); // Convert to string
          attributes[key] = attributes[key].trim().toLowerCase();
          return attributes[key].includes(searchValue);
        });
        // this.updateFeatureLayer(filteredFeatures, )
      });
      const featuresToAdd = _.reject(features, (feat) =>
        _.findIndex(featuresToDelete, (featToDelete) => featToDelete.attributes.OBJECTID === feat.attributes.OBJECTID) !== -1);
      const edits = {
        deleteFeatures: featuresToDelete,
        addFeatures: featuresToAdd
      };

      await layer.applyEdits(edits); // Apply edits
      searchResults.found += featuresToAdd.length;
      searchResults.delete += featuresToDelete.length;
    }

    return searchResults;
  }

  /**
   * Gets esri layer object by title.
   *
   * @param {string} title the name/title of the esri layer
   * @returns {*}
   * @memberof EsriMap
   */
  getLayerByTitle(title: string): any {
    return _.find(this.map.layers.items, (layer) => layer.title === title);
  }

  /*
  * Adds a graphics layer to the map, features are usually from a search result to arcgis.
  *
  * @param {Array<any>} features list of esri features
  * @param {string} title the name or title of the layer
  * @returns
  * @memberof EsriMap
  */
  async addGraphicsLayer(features: Array<any>, title: string) {
    let selectedGraphic = null;
    const [Graphic, GraphicsLayer] = await loadModules([
      'esri/Graphic',
      'esri/layers/GraphicsLayer'
    ]);

    // Remove layer with same name as title
    const foundLayer = this.getLayerByTitle(title);
    if (foundLayer) {
      this.map.remove(foundLayer);
    }

    const graphics = _.map(features, (feature) => {
      return new Graphic({
        geometry: {
          type: 'polygon',
          rings: feature.geometry.rings
        },
        attributes: feature.attributes,
        symbol: {
          type: 'simple-fill',
          color: this.POLYGON_STYLE,  // orange, opacity 80%
          outline: {
            color: [255, 255, 255],
            width: 1
          }
        },
        popupTemplate: {
          title: (feature) => {
            selectedGraphic = feature.graphic;
            return `Parcel ${feature.graphic.attributes.OBJECTID}`;
          },
          content: [{
            type: "fields",
            fieldInfos: _.map(_.keys(feature.attributes), (key) => {
              return { fieldName: key };
            })
          }],
          outFields: ['*'],
          actions: [
            { title: 'Create Job', id: 'createjob' }
          ]
        }
      });
    });

    const layer = new GraphicsLayer({
      graphics: graphics,
      title: title
    });

    this.map.add(layer);
    return graphics;
  }

  /**
   * Adds a parcel layer using either/both esri parcels and cardinal parcels
   *
   * @param {Array<any>} [esriParcels=[]] must only include parcels that are from arcgis service that is mapped to cardinal parcel (see esri.service.ts)
   * @param {Array<any>} [cardinalParcels=[]] must only include parcels that are from cardinal database
   * @param {string} title a string giving a name/title to the layer
   * @returns
   * @memberof EsriMap
   */
  async addParcelLayer(esriParcels: Array<any> = [], cardinalParcels: Array<any> = [], title: string, usePolygons: boolean = true, imageUrl: string = 'http://static.arcgis.com/images/Symbols/Shapes/BluePin1LargeB.png') {
    const [Graphic, GraphicsLayer, PictureMarkerSymbol] = await loadModules([
      'esri/Graphic',
      'esri/layers/GraphicsLayer',
      'esri/symbols/PictureMarkerSymbol'
    ]);

    // Layer already exists
    const foundLayer = this.getLayerByTitle(title);
    let esriGraphics, cardinalGraphics = [];
    
    // Creating graphics
    const symbol = {
      type: 'simple-fill',
      color: this.POLYGON_STYLE,  // blue, opacity 50%
      outline: {
        color: [255, 255, 255],
        width: 1
      }
    };

    const pictureSymbol = {
      type: 'picture-marker',
      url: imageUrl,
      width: '25px',
      height: '25px'
    };

    cardinalGraphics = _.flatten(_.map(cardinalParcels, (parcel) => {
      const polygon = JSON.parse(parcel.polygon.geomstring).coordinates;
      const polygonGraphic = new Graphic({
        geometry: {
          type: 'polygon',
          rings: polygon
        },
        attributes: parcel,
        symbol
      });
      return [
        polygonGraphic,
        new Graphic({
          geometry: {
            type: 'point',
            longitude: polygonGraphic.geometry.extent.center.longitude,
            latitude: polygonGraphic.geometry.extent.center.latitude
          },
          attributes: parcel,
          symbol: pictureSymbol
        })
      ];
    }));

    esriGraphics = _.flatten(_.map(esriParcels, (parcel) => {
      const polygon = new Graphic({
        geometry: {
          type: 'polygon',
          rings: parcel.geoJson.coordinates
        },
        attributes: parcel,
        symbol
      });

      return [
        polygon,
        new Graphic({
          geometry: {
            type: 'point',
            longitude: polygon.geometry.extent.center.longitude,
            latitude: polygon.geometry.extent.center.latitude
          },
          attributes: parcel,
          symbol: pictureSymbol
        })
      ];
    }));

    const graphics = [...cardinalGraphics, ...esriGraphics];

    await this.createOrReplaceGraphicsLayer(graphics, foundLayer, title);
    return graphics;
  }

  /**
   * Zooms to a target location, this is usually a graphic.
   *
   * @param {*} target an esri graphic's extent, ex: graphic.geometry.extent
   * @param {*} [options={duration: 1500}] Must be a positive number, default is 1.5s (1500)
   * @memberof EsriMap
   */
  zoomToTarget(target: any, options: any = {duration: 1500}, zoom: number = null) {
    if (!zoom) {
      this.view.goTo({target}, options);
    } else {
      this.view.goTo({target, zoom}, options);
    }
  }

  /**
   * Enables create polygon on an esri map.
   *
   * @param {string} [layerTitle="Custom Polygon"] name of layer that has the created polygon
   * @memberof EsriMap
   */
  async enableCreatePolygon(layerTitle: string = null) {
    const refClass = this;
    const [Draw] = await loadModules([
      'esri/views/draw/Draw'
    ]);

    const draw = new Draw({
      view: this.view
    });

    var action = draw.create("polygon");
    
    // PolygonDrawAction.vertex-add
    // Fires when user clicks, or presses the "F" key.
    // Can also be triggered when the "R" key is pressed to redo.
    action.on("vertex-add", function (evt) {
      refClass.createPolygonGraphic(evt.vertices, layerTitle);
    });
  
    // PolygonDrawAction.vertex-remove
    // Fires when the "Z" key is pressed to undo the last added vertex
    action.on("vertex-remove", function (evt) {
      refClass.createPolygonGraphic(evt.vertices, layerTitle);
    });
  
    // Fires when the pointer moves over the view
    action.on("cursor-update", function (evt) {
      refClass.createPolygonGraphic(evt.vertices, layerTitle);
    });
  
    // Add a graphic representing the completed polygon
    // when user double-clicks on the view or presses the "C" key
    action.on("draw-complete", function (evt) {
      refClass.createPolygonGraphic(evt.vertices, layerTitle);
    });
  }
  
  /**
   * Creates a polygon based on x and y vertices and passing a title gives the layer a name of your choice.
   *
   * @param {*} vertices a list of esri generated vertices that contains all the points created to draw the polygon
   * @param {string} [title="Custom Polygon"] name of layer that has the created polygon
   * @memberof EsriMap
   */
  async createPolygonGraphic(vertices, title: string = "Custom Polygon") {
    const [Graphic, GraphicsLayer, WebMercatorUtils] = await loadModules([
      'esri/Graphic',
      'esri/layers/GraphicsLayer',
      'esri/geometry/support/webMercatorUtils'
    ]);

    let layer = this.getLayerByTitle(title);

    // Convert x and y vertices to lng and lat
    const coordinates = _.map(vertices, (vertix) => {
      return WebMercatorUtils.xyToLngLat(vertix[0], vertix[1]);
    });

    var polygon = {
      type: "polygon", // autocasts as Polygon
      rings: coordinates
    };
  
    var graphic = new Graphic({
      geometry: polygon,
      symbol: {
        type: "simple-fill", // autocasts as SimpleFillSymbol
        color: this.POLYGON_STYLE, // blue, opacity 50%
        style: "solid",
        outline: {  // autocasts as SimpleLineSymbol
          color: "white",
          width: 1
        }
      }
    });

    if (!layer) {
      // Create new layer
      layer = new GraphicsLayer({
        graphics: [graphic],
        title: title
      });
      this.map.add(layer);
    } else {
      // Clear and add new graphics
      layer.removeAll();
      layer.addMany([graphic]);
    }
  }

  /**
   * Create archived jobs layer as graphics layer, saves on performance over using feature service.
   *
   * @param {Array<any>} archivedJobs a list of archived jobs
   * @param {string} [title='Archived Jobs Search Results'] Can pass a custom title name to this function or leave the default
   * @returns
   * @memberof EsriMap
   */
  async createArchivedJobsLayer(archivedJobs: Array<any>, title: string = 'Archived Jobs Search Results') {
    const [Graphic] = await loadModules([
      'esri/Graphic'
    ]);

    // Trying to get layer
    const foundLayer = this.getLayerByTitle(title);

    // Create a symbol for drawing the point
    const markerSymbol = {
      type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
      color: [128, 128, 128],
      size: 10
    };

    const graphics = _.map(archivedJobs, (job) => {
      return new Graphic({
        geometry: {
          type: 'point',
          longitude: job.centerlon,
          latitude: job.centerlat
        },
        symbol: markerSymbol,
        popupTemplate: {
          title: (feature) => {
            return `Job #${feature.graphic.attributes.mask}`;
          },
          content: [{
            type: "fields",
            fieldInfos: [
              { fieldName: 'mask', label: 'Job #' },
              { fieldName: 'shortname', label: 'Office' },
              { fieldName: 'centerlat', label: 'Latitude' },
              { fieldName: 'centerlon', label: 'Longitude' }
            ]
          }],
          outFields: ['*'],
          actions: [
            { title: 'Open Job Details', id: 'openjobdetails' }
          ]
        },
        attributes: job
      })
    });

    await this.createOrReplaceGraphicsLayer(graphics, foundLayer, title);
    return graphics;
  }

  /**
   * Creates or replaces an existing graphics layer.
   *
   * @param {Array<any>} graphics list of graphics
   * @param {*} layer layer object
   * @param {string} title title of layer
   * @memberof EsriMap
   */
  async createOrReplaceGraphicsLayer(graphics: Array<any>, layer: any, title: string) {
    const [GraphicsLayer] = await loadModules([
      'esri/layers/GraphicsLayer'
    ]);

    // Check to see if layer exists
    if (!layer) {
      // Create new layer
      layer = new GraphicsLayer({
        graphics: graphics,
        title: title
      });
      this.map.add(layer);
    } else {
      layer.removeAll();
      layer.addMany(graphics);
    }
  }

  /**
   * Given a graphics layer, you can select which features are shown.
   *
   * @param {Array<any>} graphicsToHide graphics that need to be hidden, send empty array if you want to show everything
   * @param {string} layerTitle title of the layer to select
   * @returns
   * @memberof EsriMap
   */
  filterGraphicsLayer(graphicsToHide: Array<any>, layerTitle: string) {
    const layer = this.getLayerByTitle(layerTitle);

    if (!layer) {
      throw new Error('Layer could not be found');
    }

    // Reset all graphics to be visible
    if (!graphicsToHide || graphicsToHide.length === 0) {
      _.each(layer.graphics.items, (graphic, index) => layer.graphics.items[index].visible = true);
      return layer.graphics;
    }

    // Filter graphics with each
    _.each(layer.graphics.items, (item, index) => {
      if (_.find(graphicsToHide, (graphic) => graphic.uid === item.uid)) {
        layer.graphics.items[index].visible = false;
      } else {
        layer.graphics.items[index].visible = true;
      }
    });

    return layer.graphics;
  }
}
