/* tslint:disable */
import {
  ContactInfo,
  Client
} from '../index';

declare var Object: any;
export interface CompanyInterface {
  "clientIds"?: Array<any>;
  "name": string;
  "id"?: number;
  contactinfo?: ContactInfo[];
  clients?: Client[];
}

export class Company implements CompanyInterface {
  "clientIds": Array<any>;
  "name": string;
  "id": number;
  contactinfo: ContactInfo[];
  clients: Client[];
  constructor(data?: CompanyInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Company`.
   */
  public static getModelName() {
    return "Company";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Company for dynamic purposes.
  **/
  public static factory(data: CompanyInterface): Company{
    return new Company(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Company',
      plural: 'Companies',
      path: 'Companies',
      idName: 'id',
      properties: {
        "clientIds": {
          name: 'clientIds',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        contactinfo: {
          name: 'contactinfo',
          type: 'ContactInfo[]',
          model: 'ContactInfo',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'contactdetailsId'
        },
        clients: {
          name: 'clients',
          type: 'Client[]',
          model: 'Client',
          relationType: 'referencesMany',
                  keyFrom: 'clientIds',
          keyTo: 'id'
        },
      }
    }
  }
}
