import { enableProdMode, Injectable, ReflectiveInjector } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
// import { environment } from './environments/environment';
import { EnvService } from "./app/services/env.service";
const injector = ReflectiveInjector.resolveAndCreate([EnvService]);
const envService = injector.get(EnvService);

// console.log(environment)
if (envService.NODE_ENV !== "development") {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));

//detect browser refresh when the current url is not login page
window.onbeforeunload = (ev) => {
  let currentUrl = window.location.href;
  if (!currentUrl.includes("/login")) {
    console.log("Browser refresh detected.");
    //return the message to browser api.
    if (envService.NODE_ENV !== "development") {
      var dialogText = "Are you sure?";
      ev.returnValue = dialogText;
      return dialogText;
    }
  }
};
