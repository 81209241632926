import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-taskpopover',
  templateUrl: './taskpopover.component.html',
  styleUrls: ['./taskpopover.component.scss']
})
export class TaskpopoverComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
