/* tslint:disable */

declare var Object: any;
export interface LayerInterface {
  "id"?: number;
  "name": string;
  "north": number;
  "south": number;
  "east": number;
  "west": number;
  "featurecount": number;
  "layertype": string;
  "geometrytype": string;
  "layerurl"?: string;
  "maplayer": boolean;
  "pointinpolylayer": boolean;
  "linklayer": boolean;
  "linkregex"?: string;
  "nearestlayer": boolean;
  "color"?: string;
  "fillcolor"?: string;
  "opacity": number;
  "icon"?: string;
  "featureserviceurl"?: string;
}

export class Layer implements LayerInterface {
  "id": number;
  "name": string;
  "north": number;
  "south": number;
  "east": number;
  "west": number;
  "featurecount": number;
  "layertype": string;
  "geometrytype": string;
  "layerurl": string;
  "maplayer": boolean;
  "pointinpolylayer": boolean;
  "linklayer": boolean;
  "linkregex": string;
  "nearestlayer": boolean;
  "color": string;
  "fillcolor": string;
  "opacity": number;
  "icon": string;
  "featureserviceurl": string;
  constructor(data?: LayerInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Layer`.
   */
  public static getModelName() {
    return "Layer";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Layer for dynamic purposes.
  **/
  public static factory(data: LayerInterface): Layer{
    return new Layer(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Layer',
      plural: 'layers',
      path: 'layers',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'number'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "north": {
          name: 'north',
          type: 'number'
        },
        "south": {
          name: 'south',
          type: 'number'
        },
        "east": {
          name: 'east',
          type: 'number'
        },
        "west": {
          name: 'west',
          type: 'number'
        },
        "featurecount": {
          name: 'featurecount',
          type: 'number'
        },
        "layertype": {
          name: 'layertype',
          type: 'string'
        },
        "geometrytype": {
          name: 'geometrytype',
          type: 'string'
        },
        "layerurl": {
          name: 'layerurl',
          type: 'string'
        },
        "maplayer": {
          name: 'maplayer',
          type: 'boolean'
        },
        "pointinpolylayer": {
          name: 'pointinpolylayer',
          type: 'boolean'
        },
        "linklayer": {
          name: 'linklayer',
          type: 'boolean'
        },
        "linkregex": {
          name: 'linkregex',
          type: 'string'
        },
        "nearestlayer": {
          name: 'nearestlayer',
          type: 'boolean'
        },
        "color": {
          name: 'color',
          type: 'string',
          default: '#f16913'
        },
        "fillcolor": {
          name: 'fillcolor',
          type: 'string',
          default: 'null'
        },
        "opacity": {
          name: 'opacity',
          type: 'number',
          default: 0.5
        },
        "icon": {
          name: 'icon',
          type: 'string',
          default: 'null'
        },
        "featureserviceurl": {
          name: 'featureserviceurl',
          type: 'string',
          default: 'null'
        },
      },
      relations: {
      }
    }
  }
}
