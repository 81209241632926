import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { EnvService } from './env.service';

import {
	LoopBackConfig,
	LoopBackFilter,
	Models,
	BaseLoopBackApi,
	LineApi,
} from '../shared/sdk';
import { LoopBackAuth } from '../shared/sdk/services';
import { Observable, of, forkJoin, empty, EMPTY, Subscription } from 'rxjs';
import {
	flatMap,
	take,
	map,
	mergeMap,
	retry,
	tap,
} from 'rxjs/operators';

import * as addrparser from 'parse-address';
import * as moment from 'moment';

// import { WebWorkerService } from 'ngx-web-worker';

import {
	StreetAddress,
	Badge,
	Client,
	Company,
	ContactInfo,
	CoordinatePoint,
	Employee,
	Flag,
	Invoice,
	Job,
	Layer,
	Office,
	Operation,
	Parcel,
	Payment,
	Permissions,
	Position,
	Service,
	Stage,
	Task,
	Polygon,
	Line,
	Deliverable,
	Azure,
	Cardinalconfig,
	ClientCompany,
	JobParcel,
} from '../shared/sdk/models/index';

import {
	StreetAddressApi,
	BadgeApi,
	ClientApi,
	CompanyApi,
	ContactInfoApi,
	CoordinatePointApi,
	EmployeeApi,
	FlagApi,
	InvoiceApi,
	JobApi,
	LayerApi,
	OfficeApi,
	OperationApi,
	ParcelApi,
	PaymentApi,
	PermissionsApi,
	PositionApi,
	ServiceApi,
	StageApi,
	TaskApi,
	PolygonApi,
	DeliverableApi,
	AzureApi,
	CardinalconfigApi,
	ClientCompanyApi,
	JobParcelApi,
} from '../shared/sdk/services/index';

import _ from 'underscore';
import { EventService } from './event.service';
// import * as socketIo from 'socket.io-client';
export interface Preferences {
	managingOfficeId: number;
	managerIds: Array<number>;
	defaultJobsLayer: number;
}

@Injectable({
	providedIn: 'root',
})
export class DataServiceService {
	public user: any = null;
	public userType: string = null;
	public userPermissions: any = null;
	public employeeusr: Employee = null;
	public clientusr: Client = null;
	public defaultPreferences: Preferences = {
		managingOfficeId: null,
		managerIds: null,
		defaultJobsLayer: null
	};

	public configObj: any = {
	};

	public activeJobs = null;
	public initialMapZoomLevel = 10;

	public dataObj: any = {
		offices: {
			model: Office,
			list: [],
		},
		jobs: {
			list: [],
			working: [],
			assigned: [],
			model: Job,
			headers: {
				overview: [
					{
						b: 'Job #',
						k: 'mask',
						p: null,
					},
					{
						b: 'Parcel ID',
						k: 'parcelId',
						p: null,
					},
					{
						b: 'Client',
						k: 'personname',
						p: null,
					},
					{
						b: 'Managers',
						k: 'managerIds',
						p: null,
					},
					{
						b: 'Progress',
						k: 'operations',
						p: null,
					},
					{
						b: 'Address',
						k: 'parcels',
						p: null,
					},
					{
						b: 'Created/Due',
						k: 'created',
						p: null,
					},
				],
				userManages: [
					{
						b: 'Job #',
						k: 'mask',
					},
					{
						b: 'Stage Progress',
						k: 'operations',
					},
					{
						b: 'Client',
						k: 'personname',
					},
					{
						b: 'Created/Due',
						k: 'created',
					},
				],
				userAssigned: [
					{
						b: 'Job #',
						k: 'mask',
					},
					{
						b: 'Client',
						k: 'personname',
					},
					{
						b: 'Assigned Stage',
						k: 'userassignedstage',
					},
					{
						b: 'Created/Due',
						k: 'created',
					},
					{
						b: 'Mark Stage',
						k: 'userstagestatus',
					},
				],
				moneyStats: [
					{
						b: 'Jobs Due This Month',
						k: 'jobsDueCount',
					},
					{
						b: 'Jobs Due Total',
						k: 'jobsDueTotal',
					},
					{
						b: 'Jobs Completed This Month',
						k: 'jobsCompleteCount',
					},
					{
						b: 'Jobs Complete Total',
						k: 'jobsCompleteTotal',
					},
					{
						b: 'Jobs Invoiced This Month',
						k: 'jobsInvoiced',
					},
					{
						b: 'Jobs Paid This Month',
						k: 'jobsPaid',
					},
				],
				moneyTotals: [
					{
						b: 'Job Overview',
						k: 'activejoboverview',
					},
					{
						b: 'Invoice Amount',
						k: 'activetaskcost',
					},
					{
						b: 'Taxable Amount',
						k: 'activetaxableamount',
					},
					{
						b: 'Tax Total',
						k: 'activetaxvalue',
					},
					{
						b: 'Total Amount',
						k: 'activetotalvalue',
					},
					{
						b: 'Payment Received',
						k: 'activepaymentreceived',
					},
					{
						b: 'Total Sales',
						k: 'activeuntaxedtotalamount',
					},
					{
						b: 'Estimated Taxable Sales',
						k: 'activetaxabletotalamount',
					},
				],
				moneyHeader: [
					{
						b: 'Invoice',
						k: 'mask',
					},
					{
						b: 'Invoice Amount',
						k: 'taskcost',
					},
					{
						b: 'Taxable Amount',
						k: 'taxableamount',
					},
					{
						b: 'Tax Total',
						k: 'taxvalue',
					},
					{
						b: 'Total Amount Due',
						k: 'totalvalue',
					},
					{
						b: 'Payment Received',
						k: 'paidflag',
					},
					{
						b: 'Total Sales',
						k: 'untaxedtotalamount',
					},
					{
						b: 'Estimated Taxable Sales',
						k: 'taxabletotalamount',
						// },
						// {
						//     b: "Tax Record",
						//     k: "taxtotalamount"
					},
				],
			},
		},
		users: {
			list: [],
			model: Employee,
			headers: {
				overview: [],
			},
		},
		clients: {
			list: [],
			model: Client,
			headers: {
				overview: [
					{
						b: 'Client Name',
						k: 'personname',
						p: null,
					},
					{
						b: 'Phone',
						k: 'phone',
						p: null,
					},
					{
						b: 'Email',
						k: 'email',
						p: null,
					},
				],
			},

		},
		companies: {
			list: [],
			model: Company,
			headers: {},

		},
		tasks: {
			list: [],
			model: Task,
			headers: {
				overview: [
					{
						b: 'Task Name',
						k: 'taskname',
					},
					{
						b: 'Description',
						k: 'taskdesc',
					},
					{
						b: 'Task Rate',
						k: 'rate',
					},
					{
						b: 'Task Assigned',
						k: 'isassigned',
					},
				],
			},

		},
		stages: {
			list: [],
			// model: Stage,
			headers: {
				overview: [],
			},

		},
		roles: {
			list: [],
			model: Position,
			headers: {
				overview: [],
			},

		},
		invoices: {
			list: [],
			model: Invoice,
			headers: {
				overview: [],
				taskHeader: [
					{
						b: 'Task',
						k: 'taskname',
					},
					{
						b: 'Taxable',
						k: 'taxable',
					},
					{
						b: 'Rate',
						k: 'rate',
					},
				],
			},

		},
		jobpayments: {
			list: [],
			model: Payment,
			headers: {
				overview: [],
			},

		},
		ops: {
			list: [],
			model: Operation,
			headers: {
				overview: [],
			},

		},
		services: {
			list: [],
			model: Service,
			headers: {
				overview: [],
			},

		},
		permissions: {
			list: [],
			model: Permissions,
			headers: {
				overview: [
					{
						b: 'Can Review Own User',
						k: 'canviewselfuser',
						v: false,
					},
					{
						b: 'Can Manage Own User',
						k: 'canmanageselfuser',
						v: false,
					},
					{
						b: 'Can Manage Jobs',
						k: 'canmanagejobs',
						v: false,
					},
					{
						b: 'Can Assign Self Jobs',
						k: 'canassignselfjobs',
						v: false,
					},
					{
						b: 'Can Assign Jobs To Others',
						k: 'canassignotherusersjobs',
						v: false,
					},
					{
						b: 'Can View Job Details',
						k: 'canviewjobdetails',
						v: false,
					},
					{
						b: 'Can View Basic Client Details',
						k: 'canviewclientsbasic',
						v: false,
					},
					{
						b: 'Can View All Client Details',
						k: 'canviewclientsdetailed',
						v: false,
					},
					{
						b: 'Can View Basic Finances',
						k: 'canviewfinancesbasic',
						v: false,
					},
					{
						b: 'Can View Detailed Finances',
						k: 'canviewfinancesdetailed',
						v: false,
					},
					{
						b: 'Can Edit Role Details',
						k: 'canmanageroles',
						v: false,
					},
					{
						b: 'Can Edit Stage Details',
						k: 'canmanagestages',
						v: false,
					},
					{
						b: 'Can Edit Task Details',
						k: 'canmanagetasks',
						v: false,
					},
					{
						b: 'Can Manage Administrators',
						k: 'canmanageadmins',
						v: false,
					},
					{
						b: 'Can Control Program Settings',
						k: 'canmanageprogram',
						v: false,
					},
				],
			},

		},
		badges: {
			list: [],

		},
		flags: {
			list: [],

		},
		deliverables: {

		},
	};
	public financeMonthsList = [
		{
			name: 'January',
			id: 0,
			id2: '00',
			filteron: false,
		},
		{
			name: 'February',
			id: 1,
			id2: '01',
			filteron: false,
		},
		{
			name: 'March',
			id: 2,
			id2: '02',
			filteron: false,
		},
		{
			name: 'April',
			id: 3,
			id2: '03',
			filteron: false,
		},
		{
			name: 'May',
			id: 4,
			id2: '04',
			filteron: false,
		},
		{
			name: 'June',
			id: 5,
			id2: '05',
			filteron: false,
		},
		{
			name: 'July',
			id: 6,
			id2: '06',
			filteron: false,
		},
		{
			name: 'August',
			id: 7,
			id2: '07',
			filteron: false,
		},
		{
			name: 'September',
			id: 8,
			id2: '08',
			filteron: false,
		},
		{
			name: 'October',
			id: 9,
			id2: '09',
			filteron: false,
		},
		{
			name: 'November',
			id: 10,
			id2: '10',
			filteron: false,
		},
		{
			name: 'December',
			id: 11,
			id2: '11',
			filteron: false,
		},
	];

	public defaultLbFilter: LoopBackFilter = {
		limit: 10,
		skip: 0,
	};

	private socket;
	public ioConnection: any;
	public activeJobModalData: any = null;
	public activeClientModalData: any = {};

	private webWorkerPromises: Promise<any>[] = [];
	public webWorkerResults: any[] = [];
	private baseUrl = '';
	private socketUrl = '';

	public filteredOfficeId = 0; // 0 is Select

	// mapComponentLoaded:boolean = false;
	filterApplied = false;
	filterComponentConditionObject: any;
	filterComponentJobData: any;
	filterComponentClientData: any;

	jobFilterParams: any;
	clientFilterParams: any;
	financeFilterParams: any;

	/*for creating a client and then navigating straight to job creation page*/
	activeClientId: number = null;
	activeCompanyId: number = null;
	uimapsubscriptionstate = true;
	// controls if the map component will actively subscribe to its
	// relevant change streams (not needed in views where the map does not exist)

	subscriptions: Subscription[] = [];

	constructor(
		public event: EventService,
		private environment: EnvService,
		private http: HttpClient,
		protected auth: LoopBackAuth,
		private addressApi: StreetAddressApi,
		private badgeApi: BadgeApi,
		private clientApi: ClientApi,
		private companyApi: CompanyApi,
		private employeeApi: EmployeeApi,
		private contactInfoApi: ContactInfoApi,
		private flagApi: FlagApi,
		private invoiceApi: InvoiceApi,
		private jobApi: JobApi,
		private officeApi: OfficeApi,
		private operationApi: OperationApi,
		private parcelApi: ParcelApi,
		private paymentApi: PaymentApi,
		private permissionsApi: PermissionsApi,
		private positionApi: PositionApi,
		private serviceApi: ServiceApi,
		private stageApi: StageApi,
		private taskApi: TaskApi,
		private layerApi: LayerApi,
		private polygonApi: PolygonApi,
		private coordinatePointApi: CoordinatePointApi,
		private lineApi: LineApi,
		private deliverableApi: DeliverableApi,
		private azureApi: AzureApi,
		private cardinalconfigApi: CardinalconfigApi,
		private clientCompanyApi: ClientCompanyApi,
		private jobParcelApi: JobParcelApi,
	) {
		// console.log(this.environment);
		if (this.environment.HTTPS === false) {
			// for VS CODE debugger only
			this.baseUrl = 'http://localhost:3001';
		} else {
			// local docker container or on azure
			this.baseUrl =
				this.environment.HOST_NAME === 'localhost'
					? `https://localhost:${this.environment.HOST_PORT}`
					: `https://${this.environment.HOST_NAME}`;
		}

		LoopBackConfig.setBaseURL(this.baseUrl);
		LoopBackConfig.setDebugMode('production' !== this.environment.NODE_ENV);

		// listens for updates to tasking changes on other clients
		const updateTaskSubscription = this.event.updateTaskSource$.subscribe(
			() => {
				this.remoteUpdateTasks();
			}
		);
		this.subscriptions.push(updateTaskSubscription);

		const createTaskSubscription = this.event.createTaskSource$.subscribe(
			() => {
				this.remoteUpdateTasks();
			}
		);
		this.subscriptions.push(createTaskSubscription);

		const updateStageSubscription = this.event.updateStageSource$.subscribe(
			() => {
				this.remoteUpdateStages();
			}
		);
		this.subscriptions.push(updateStageSubscription);
	}


	/**
	 * Get jobs by Filter but does not return spatial data
	 *
	 * @param {*} [jobFilter={}] jobFilter (LoopbackFilter)
	 * @param {number} [skip=0] (skip first n value)
	 * @returns {Observable<any>}
	 * @memberof DataServiceService
	 */
	getJobsWithoutGeometries(jobFilter = {}, skip = 0): Observable<any> {
		jobFilter['skip'] = skip;
		return this.jobApi.getNonSpatialSearch(jobFilter);
	}

	getStages() {
		return this.stageApi.find({});
	}

	remoteUpdateStages() {
		this.getStages()
			.pipe(take(1))
			.subscribe((stages) => {
				this.dataObj['stages'].list = stages;
			});
	}

	remoteUpdateTasks() {
		this.taskApi
			.find({})
			.pipe(take(1))
			.subscribe((tasks) => {
				this.dataObj['tasks'].list = tasks;
			});
	}

	public logoutUser() {
		localStorage.clear();
		if (this.userType == 'Employee') {
			return this.employeeApi.logout();
		} else if (this.userType == 'Client') {
			return this.clientApi.logout();
		}
	}

	public clearUserData() {
		this.auth.clear();
	}

	/**
	 * Return a Promise from changePassword Employee/Client's server call
	 *
	 * @param {string} oldPassword
	 * @param {string} newPassword
	 * @returns {Promise<any>}
	 * @memberof DataServiceService
	 */
	public updatePassword(
		oldPassword: string,
		newPassword: string
	): Promise<any> {
		if (this.userType === 'Employee') {
			return this.employeeApi
				.changePassword(oldPassword, newPassword)
				.toPromise();
		} else if (this.userType === 'Client') {
			return this.clientApi
				.changePassword(oldPassword, newPassword)
				.toPromise();
		}
		return new Promise((resolve, reject) => {
			reject();
		});
	}

	/**
	 * Logging in as employee
	 *
	 * @param {*} credentials
	 * @returns {Observable<any>}
	 * @memberof DataServiceService
	 */
	public employeeLogin(credentials): Observable<any> {

		return this.employeeApi
			.login(credentials)
			.pipe(take(1),
				map((employee: Employee) => {
					return this.employeeInitAfterLogin(employee);
				}), tap(() => {
					this.filteredOfficeId = this.user.preferences.managingOfficeId;
					this.userType = 'Employee';
				})
			);
	}

	/**
	 * Reset client's password and send an email. response OK even when client is not found
	 *
	 * @param {string} email
	 * @returns {Observable<any>}
	 * @memberof DataServiceService
	 */
	public clientRequestNewPassword(email: string): Observable<any> {
		return this.clientApi.requestNewPassword(`"${email}"`);
	}

	/**
	 * Reset employee's password and send an email. response OK even when client is not found
	 *
	 * @param {string} email
	 * @returns {Observable<any>}
	 * @memberof DataServiceService
	 */
	public employeeRequestNewPassword(email: string): Observable<any> {
		return this.employeeApi.requestNewPassword(`"${email}"`);
	}

	/**
	 * Reset employee's password and send an email. response OK even when client is not found
	 *
	 * @param {string} email
	 * @returns {Observable<any>}
	 * @memberof DataServiceService
	 */
	public employeeRequestForgotPassword(email: string): Observable<any> {
		return this.employeeApi.requestForgotPassword(`"${email}"`);
	}

	/**
	 * After logging successfully, retrieve necessary data for specific user (employee type)
	 *
	 * @param {*} employee
	 * @returns {Observable<any>}
	 * @memberof DataServiceService
	 */
	public employeeInitAfterLogin(employee: any): Observable<any> {

		this.user = employee.hasOwnProperty('user') ? employee['user'] : employee;
		this.userPermissions = this.computeUserPermissionsObject(
			_.map(this.user.positions, (position) => {
				return position.permissions;
			})
		);
		let obs = [
			this.stageApi.find(),
			this.taskApi.find(),
			this.employeeApi.find(),
			this.positionApi.find(),
			this.badgeApi.find(),
			this.officeApi.find(),
			this.cardinalconfigApi.find(),
		];

		return of(forkJoin(obs).pipe(take(1)).subscribe((res) => {
			this.dataObj.stages.list = res[0];
			this.dataObj.tasks.list = res[1];
			this.dataObj.users.list = res[2];
			this.dataObj.roles.list = res[3];
			this.dataObj.badges.list = res[4];
			this.dataObj.offices.list = res[5];
			_.each(res[6], (obj) => {
				this.configObj[obj.key] = obj.value;
			});
		}));
	}

	/**
	 * Authenticate to client portal
	 *
	 * @param {*} credentials body object for client credentials
	 * @returns {Observable<any>}
	 * @memberof DataServiceService
	 */
	public clientLogin(credentials: any): Observable<any> {
		return this.clientApi.login(credentials)
			.pipe(
				map((client: Client) => {
					if (client != null) {
						this.auth.save();
					}
					this.userType = 'Client';
					if (client.hasOwnProperty('user')) {
						this.user = client['user'];
					}
					return client;
				}))
	}

	/**
	 * Call this to update the employee list stored in dataservice
	 *
	 * @memberof DataServiceService
	 */
	updateSourceEmployeeList() {
		this.employeeApi
			.find()
			.pipe(take(1))
			.subscribe((employees) => {
				this.dataObj.users.list = employees;
			});
	}
	/**
	 * Call this to update the office list stored in dataservice
	 *
	 * @memberof DataServiceService
	 */
	updateSourceOfficeList() {
		this.officeApi
			.find()
			.pipe(take(1))
			.subscribe((offices) => {
				this.dataObj.offices.list = offices;
			});
	}

	/**
	 * Call this to update the task list stored in dataservice
	 *
	 * @memberof DataServiceService
	 */
	updateSourceTaskList() {
		this.taskApi
			.find()
			.pipe(take(1))
			.subscribe((tasks) => {
				this.dataObj.tasks.list = tasks;
			});
	}

	/**
	 * Call this to update the role list stored in dataservice
	 *
	 * @memberof DataServiceService
	 */
	updateSourceRoleList() {
		this.positionApi
			.find()
			.pipe(take(1))
			.subscribe((roles) => {
				this.dataObj.roles.list = roles;
			});
	}

	/**
	 *
	 *
	 * @param {*} [clientId=null] If clientId is null, retrieve the current user in data-service
	 * @returns {Observable}
	 * @memberof DataServiceService
	 */
	public getJobsByClientId(clientId = null): Observable<any> {
		if (clientId === null) {
			clientId = this.user.id;
		}
		return this.jobApi.find({
			where: { clientid: clientId },
			include: ['deliverables']
		});
	}

	private computeUserPermissionsObject(arrayOfPermissionsObjs: any) {
		//there will always be at least one permissions object
		var globalPermObj = {};
		//if there is more than one, the truthy values need to be merged
		if (arrayOfPermissionsObjs.length > 1) {
			var keys = _.keys(arrayOfPermissionsObjs[0]);
			var skipList = []; //list of permissions which have been set to true, so don't need to be checked twice
			//plug each key into the global permission object the dataservice will use to check permissions
			_.each(keys, (k) => {
				globalPermObj[k] = false;
			});
			//scan each permissions object
			_.each(arrayOfPermissionsObjs, (permissionObj) => {
				_.mapObject(permissionObj, (v, k) => {
					//if the permssion is enabled for any position, it will be set to true
					//the permission key will also be added to the skip list to pass over checking it in future calls
					if (!skipList.includes(k) && v == true) {
						globalPermObj[k] = true;
						skipList.push(k);
					}
				});
			});
			return globalPermObj;
		} else {
			return arrayOfPermissionsObjs[0];
		}
	}
	getUserAssignedJobsCount(assignedCountFilter = { and: [] }) {
		if (
			_.findIndex(assignedCountFilter.and, (obj) => {
				return _.has(obj, 'completed');
			}) == -1
		) {
			assignedCountFilter.and.push({ complete: false });
		}

		if (
			_.findIndex(assignedCountFilter.and, (obj) => {
				return _.has(obj, 'closed');
			}) == -1
		) {
			assignedCountFilter.and.push({ closed: false });
		}

		return this.employeeApi.countAssigned(this.user.id, assignedCountFilter);
	}

	getUserAssignedJobs(
		skipVal: number = 0,
		assignedFilter = Object.assign(
			{
				where: {
					and: [],
				},
			},
			this.defaultLbFilter
		)
	) {
		var ref: DataServiceService = this;
		assignedFilter.skip = skipVal;

		var dateDataIdx = _.findIndex(assignedFilter.where.and, (obj) => {
			return _.has(obj, 'created');
		});
		if (dateDataIdx != -1) {
			//if there is no 'created' key, there is no equivalent parameter for the operations model, so it can be passed
			_.map(assignedFilter.where.and.created, (value, idx) => {
				if (moment.isMoment(value) == true) {
					if (idx == 0) {
						assignedFilter.where.and.push({ assigned: { gte: value } });
						assignedFilter.where.and.push({ start: { gte: value } });
						assignedFilter.where.and.push({ end: { gte: value } });
					}

					if (idx == 1) {
						assignedFilter.where.and.push({ assigned: { lte: value } });
						assignedFilter.where.and.push({ start: { lte: value } });
						assignedFilter.where.and.push({ end: { lte: value } });
					}
				}
			});
		}

		if (
			_.findIndex(assignedFilter.where.and, (obj) => {
				return _.has(obj, 'complete ');
			}) == -1
		) {
			assignedFilter.where.and.push({ complete: false });
		}

		var multiUserArgIdx = _.findIndex(assignedFilter.where.and, (obj) => {
			if (_.has(obj, 'or') == true) {
				if (
					_.findIndex(obj.or, (item) => {
						_.has(item, 'assigneduser');
					})
				) {
					return true;
				} else {
					return false;
				}
			}
		});

		var singleUserArgIdx = _.findIndex(assignedFilter.where.and, (obj) => {
			return _.has(obj, 'assigneduser');
		});

		if (singleUserArgIdx == -1 && multiUserArgIdx == -1) {
			assignedFilter.where.and.push({ assigneduser: this.user.id });
		}

		//if the filter has an index which is meant to be a related dimension, such as clientid for operations
		let preprocessFilters = this.stackUserAssignedJobFilterObservables(
			assignedFilter
		);
		// var clientIdIdx = _.findIndex(assignedFilter.where.and, (obj) => { return _.has(obj, "clientid") });
		if (_.keys(preprocessFilters).length > 0) {
			return forkJoin(preprocessFilters).pipe(
				flatMap((data: any) => {
					if (_.has(data, 'clientid')) {
						assignedFilter.where.and.push({
							id: { inq: _.pluck(data.clientid, 'id') },
						});
					}

					return ref.operationApi.find(assignedFilter);
				})
			);
		} else {
			return this.operationApi.find(assignedFilter);
		}
	}

	getUserWorkingJobs(skipVal: number = 0) {
		let filter: LoopBackFilter = Object.assign({}, this.defaultLbFilter);
		filter.skip = skipVal;
		return this.employeeApi.getWorking(this.user.id, filter);
	}

	getUserManagedJobsCount(managedCountFilter = { and: [] }) {
		if (this.filteredOfficeId) {
			managedCountFilter.and.push({ managingoffice: this.filteredOfficeId });
		}

		managedCountFilter.and.push({
			or: [
				{ managerIds: { like: `%[${this.user.id}]%` } },
				{ managerIds: { like: `%${this.user.id},%` } },
				{ managerIds: { like: `%${this.user.id}]%` } },
				{ managerIds: { like: `%[${this.user.id},%` } },
			],
		});

		if (
			_.findIndex(managedCountFilter.and, (obj) => {
				return _.has(obj, 'complete');
			}) == -1
		) {
			managedCountFilter.and.push({ complete: false });
		}

		if (
			_.findIndex(managedCountFilter.and, (obj) => {
				return _.has(obj, 'closed');
			}) == -1
		) {
			managedCountFilter.and.push({ closed: false });
		}

		return this.employeeApi.countManages(this.user.id, managedCountFilter);
	}

	private switchcaseCompositeObservableFilters(key, filterObj) {
		switch (key) {
			case 'assigneduser': {
				var assignedUserIdx = _.findIndex(filterObj.where.and, (obj) => {
					return _.has(obj, 'assigneduser');
				});
				var assigneduserIds = filterObj.where.and.splice(assignedUserIdx, 1)[0]
					.assigneduser.inq; //list of assigned user ids to filter by
				let obs = this.jobApi.getByAssignedUserIds(assigneduserIds);
				return obs;
			}
			case 'inprogress': {
				var stageProgressIdx = _.findIndex(filterObj.where.and, (obj) => {
					return _.has(obj, 'inprogress');
				});
				var stageIdValue = filterObj.where.and.splice(stageProgressIdx, 1)[0]
					.stageid; //operation stage id value to filter by
				let obs = this.jobApi.getByStageInProgress(stageIdValue);
				return obs;
			}
			case 'clientid': {
				var clientIdIdx = _.findIndex(filterObj.where.and, (obj) => {
					return _.has(obj, 'clientid');
				});
				var clientIds = filterObj.where.and.splice(clientIdIdx, 1)[0].clientid
					.inq; //list of client ids to filter by
				let obs = this.operationApi.getByClientIds(clientIds);
				return obs;
			}
			case 'badgeid': {
				var badgeIdIdx = _.findIndex(filterObj.where.and, (obj) => {
					return _.has(obj, 'badgeid');
				});
				var badgeIds = filterObj.where.and.splice(badgeIdIdx, 1)[0].badgeid.inq; //list of badge ids to filter by
				let obs = this.jobApi.getByActiveFlags(badgeIds);
				return obs;
			}
		}
	}

	private stackUserAssignedJobFilterObservables(filterObj) {
		var assignedFilterList = ['clientid', 'badgeid'];
		var observablesList: any = {};

		_.map(filterObj.where.and, (obj) => {
			var key = _.keys(obj)[0];
			if (assignedFilterList.includes(key)) {
				observablesList[key] = this.switchcaseCompositeObservableFilters(
					key,
					filterObj
				);
			}
		});

		return observablesList;
	}

	private stackUserManagedJobFilterObservables(filterObj) {
		var ref: DataServiceService = this;
		var managedFilterList = ['assigneduser', 'inprogress', 'badgeid'];
		var observablesList = {};

		var filtered = _.filter(filterObj.where.and, (obj) => {
			var key = _.keys(obj)[0];
			return managedFilterList.includes(key);
		});

		_.map(filtered, (obj) => {
			var key = _.keys(obj)[0];
			var response = ref.switchcaseCompositeObservableFilters(key, filterObj);
			observablesList[key] = response;
		});

		return observablesList;
	}

	getUserManagedJobs(
		skipVal: number = 0,
		managedFilter = Object.assign(
			{
				where: {
					and: [],
				},
			},
			this.defaultLbFilter
		)
	) {
		//cc:initialload#7; query jobs by user is manager on job API;
		// let filter: LoopBackFilter = Object.assign({}, this.defaultLbFilter);
		managedFilter.skip = skipVal;
		var ref: DataServiceService = this;

		var managerArgIdx = _.findIndex(managedFilter.where.and, (obj) => {
			if (_.has(obj, 'or') == true) {
				if (
					_.findIndex(obj.or, (item) => {
						_.has(item, 'managerIds');
					})
				) {
					return true;
				} else {
					return false;
				}
			}
		});

		if (this.filteredOfficeId) {
			managedFilter.where.and.push({ managingoffice: this.filteredOfficeId });
		}

		if (managerArgIdx == -1) {
			managedFilter.where.and.push({
				or: [
					{ managerIds: { like: `%[${this.user.id}]%` } },
					{ managerIds: { like: `%${this.user.id},%` } },
					{ managerIds: { like: `%${this.user.id}]%` } },
					{ managerIds: { like: `%[${this.user.id},%` } },
				],
			});
		}

		if (
			_.findIndex(managedFilter.where.and, (obj) => {
				return _.has(obj, 'closed');
			}) == -1
		) {
			managedFilter.where.and.push({ closed: false });
		}

		let preprocessFilters = this.stackUserManagedJobFilterObservables(
			managedFilter
		);
		if (_.keys(preprocessFilters).length > 0) {
			// if there are any filters for the scope which require preprocessing
			let validIds = [];
			return forkJoin(preprocessFilters).pipe(
				flatMap((data: any) => {
					let keys = _.keys(data);
					if (keys.length > 0) {
						validIds = _.uniq(
							_.flatten(
								_.map(keys, (k) => {
									return _.pluck(data[k], 'id');
								})
							)
						);
					}
					managedFilter.where.and.push({ id: { inq: validIds } });

					return ref.jobApi.getManagestable(managedFilter);
				})
			);
		} else {
			return this.jobApi.getManagestable(managedFilter);
		}
	}

	getActiveJobs(skipVal: number = 0) {
		let filter: LoopBackFilter = Object.assign({}, this.defaultLbFilter);
		filter.skip = skipVal;
		return this.jobApi.getActive(filter);
	}

	getUserJobsByIdFilterInq(jobidset: number[]) {
		var includeFilter: LoopBackFilter = {
			where: { id: { inq: jobidset } },
		};

		return this.jobApi.find(includeFilter);
	}

	getActiveJobsForJobOverview(
		skipval: number = 0,
		jobOverviewFilter = Object.assign(
			{
				where: {
					and: [],
					include: { relation: 'office' },
				},
			},
			this.defaultLbFilter
		)
	) {
		// let filter: LoopBackFilter =
		jobOverviewFilter['skip'] = skipval;
		// filter.skip = skipval;
		var ref: DataServiceService = this;

		var managerArgIdx = _.findIndex(jobOverviewFilter.where.and, (obj) => {
			if (_.has(obj, 'or') == true) {
				if (
					_.findIndex(obj.or, (item) => {
						_.has(item, 'managerIds');
					})
				) {
					return true;
				} else {
					return false;
				}
			}
		});

		if (
			_.findIndex(jobOverviewFilter.where.and, (obj) => {
				return _.has(obj, 'closed');
			}) == -1
		) {
			jobOverviewFilter.where.and.push({ closed: false });
		}

		// var assignedUserIdx = _.findIndex(managedFilter.where.and, (obj) => { return _.has(obj, "assigneduser") });
		let preprocessFilters = this.stackUserManagedJobFilterObservables(
			jobOverviewFilter
		);
		if (_.keys(preprocessFilters).length > 0) {
			var validIds = [];
			return forkJoin(preprocessFilters).pipe(
				flatMap((data: any) => {
					var keys = _.keys(data);
					if (keys.length > 0) {
						validIds = _.uniq(
							_.flatten(
								_.map(keys, (k) => {
									return _.pluck(data[k], 'id');
								})
							)
						);
						// validIds.concat(jobIds)
					}
					jobOverviewFilter.where.and.push({ id: { inq: validIds } });

					return this.jobApi.getBase(jobOverviewFilter);
				})
			);
		} else {
			return this.jobApi.getActive(jobOverviewFilter);
		}
	}

	getActiveJobsWithFinancesForJobOverview(
		skipval: number = 0,
		financeFilter = Object.assign(
			{
				where: {
					and: [],
				},
			},
			this.defaultLbFilter
		)
	) {
		// let filter: LoopBackFilter = Object.assign({}, this.defaultLbFilter);
		// filter.skip = skipval;
		financeFilter['skip'] = skipval;

		// filter.skip = skipval;
		var ref: DataServiceService = this;

		var managerArgIdx = _.findIndex(financeFilter.where.and, (obj) => {
			if (_.has(obj, 'or') == true) {
				if (
					_.findIndex(obj.or, (item) => {
						_.has(item, 'managerIds');
					})
				) {
					return true;
				} else {
					return false;
				}
			}
		});

		if (
			_.findIndex(financeFilter.where.and, (obj) => {
				return _.has(obj, 'closed');
			}) == -1
		) {
			financeFilter.where.and.push({ closed: false });
		}

		// var assignedUserIdx = _.findIndex(managedFilter.where.and, (obj) => { return _.has(obj, "assigneduser") });
		let preprocessFilters = this.stackUserManagedJobFilterObservables(
			financeFilter
		);
		if (_.keys(preprocessFilters).length > 0) {
			//if there are any filters for the scope which require preprocessing
			// var assigneduserIds = managedFilter.where.and.splice(assignedUserIdx, 1)[0].assigneduser.inq;//list of client ids to filter by
			// return ref.jobApi.getByAssignedUserIds(assigneduserIds).pipe(
			return forkJoin(preprocessFilters).pipe(
				flatMap((data: any) => {
					var validIds = [];
					if (_.has(data, 'assigneduser')) {
						// managedFilter.where.and.push({ id: { inq: _.pluck(data.assigneduser, 'id') } });
						validIds.concat(_.pluck(data.assigneduser, 'id'));
					}
					if (_.has(data, 'inprogress')) {
						// managedFilter.where.and.push({ id: { inq: _.pluck(data.inprogress, 'id') } });
						validIds.concat(_.pluck(data.inprogress, 'id'));
					}
					if (_.has(data, 'badgeid')) {
						// managedFilter.where.and.push({ id: { inq: _.pluck(data.badgeid, 'id') } });
						validIds.concat(_.pluck(data.badgeid, 'id'));
					}
					financeFilter.where.and.push({ id: { inq: validIds } });

					if (this.filteredOfficeId) {
						financeFilter.where.and.push({
							managingoffice: this.filteredOfficeId,
						});
					}
					return this.jobApi.getWithfinances(financeFilter);
				})
			);
		} else {
			if (this.filteredOfficeId) {
				financeFilter.where.and.push({ managingoffice: this.filteredOfficeId });
			}
			// return this.jobApi.getActive(jobOverviewFilter);
			return this.jobApi.getWithfinances(financeFilter);
		}
	}

	getActiveJobsForJobOverviewCount(
		jobFilter = Object.assign({
			where: {
				and: [],
			},
		})
	) {
		if (jobFilter.where.and.length == 0) {
			if (this.filteredOfficeId) {
				jobFilter.where.and.push({ managingoffice: this.filteredOfficeId });
			}
			return this.jobApi.countActive(jobFilter.where);
		} else {
			if (this.filteredOfficeId) {
				jobFilter.where.and.push({ managingoffice: this.filteredOfficeId });
			}
			return this.jobApi.count(jobFilter.where);
		}
	}

	getActiveClientsForClientOverview(
		skipval: number = 0,
		limit: number = 10,
		clientFilter = Object.assign(
			{
				where: {
					and: [],
				},
			},
			this.defaultLbFilter
		)
	) {
		// let filter: LoopBackFilter = Object.assign({}, this.defaultLbFilter);
		// filter.skip = skipval;
		clientFilter['skip'] = skipval;
		clientFilter['limit'] = limit;

		if (
			_.has(clientFilter.where, 'and') &&
			clientFilter.where.and.length == 0
		) {
			clientFilter.where = _.omit(clientFilter.where, 'and');
		}

		if (_.has(clientFilter.where, 'or') == false) {
			return this.clientApi.getClientswithactivejobs(clientFilter);
		} else {
			return this.clientApi.getAlljobs(clientFilter);
		}
	}

	getActiveClientsForClientOverviewCount(
		clientFilter = Object.assign({
			where: {
				and: [],
			},
		})
	) {
		if (
			_.has(clientFilter.where, 'and') &&
			clientFilter.where.and.length == 0
		) {
			clientFilter.where = _.omit(clientFilter.where, 'and');
		}

		if (_.has(clientFilter.where, 'and') == true) {
			return this.clientApi.countClientswithactivejobs(clientFilter.where);
		} else {
			return this.clientApi.countAlljobs(clientFilter.where);
		}
	}
	getClientsForGlobalSearch(skipval: number = 0, clientname) {
		var splitname = clientname.split(' ');
		var lastnamestring = '';
		if (splitname.length > 1 && splitname[1].length > 0) {
			lastnamestring = `${splitname[1].trim()}%`;
		}
		var payload = {
			where: {
				or: [
					{
						firstname: {
							ilike: `${splitname[0].trim()}%`,
						},
					},
					{
						or: [
							{
								lastname: {
									ilike: lastnamestring,
								},
							},
							{
								lastname: {
									ilike: `${splitname[0]}%`,
								},
							},
						],
					},
				],
			},
		};
		let filter: LoopBackFilter = Object.assign(
			{},
			this.defaultLbFilter,
			payload
		);
		filter.skip = skipval;
		return this.clientApi.find(filter);
	}

	getJobInvoiceCount(idnum: number) {
		return this.jobApi.countInvoices(idnum);
	}
	getJobWithAllDetailsById(idnum: number) {
		var filter: LoopBackFilter = {
			where: { id: idnum },
		};
		return this.jobApi.getEntirewithclient(filter);
	}
	getJobWithDetailsById(idnum: number) {
		var filter: LoopBackFilter = {
			where: { id: idnum },
		};
		return this.jobApi.getManagestable(filter);
	}
	getJobParcelsByJobId(idnum: number) {
		return this.jobApi.getParcels(idnum);
	}

	renderUsernameFromIdNum(n: number) {
		var userObj = _.findWhere(this.dataObj.users.list, { id: n });
		if (userObj != undefined) {
			return `${userObj.firstname} ${userObj.lastname}`;
		} else {
			return 'None';
		}
	}
	addClientToCompany(company, clientid) {
		if (company != undefined) {
			var updateFilter = { id: company.id };
			var clientIdArray = company.clientIds.push(clientid);
			return this.companyApi
				.updateAttributes(company.id, {
					name: company.name,
					clientIds: `[${clientIdArray.toString()}]`,
				})
				.toPromise();
		} else {
			return new Promise((resolve, reject) => resolve(null));
		}
	}
	addCompanyToClient(clientid, companyid) {
		var updateFilter = { id: clientid };
		let companyIdArray = [];
		companyIdArray.push(companyid.toString());
		return this.clientApi.updateAll(updateFilter, {
			companyIds: `[${companyIdArray.toString()}]`,
		});
	}
	lookupClientByClientIdOnly(clientidnum: number) {
		return this.clientApi.findById(clientidnum);
	}
	searchCreateJobClientsCount(clientname: string) {
		var splitname = clientname.split(' ');
		var lastnamestring = '';
		if (splitname.length > 1 && splitname[1].length > 0) {
			lastnamestring = `${splitname[1].trim()}%`;
		}
		let filter: object = {
			// where: {
			or: [
				{
					firstname: {
						ilike: `${splitname[0].trim()}%`,
					},
				},
				// {
				// or: [
				{
					lastname: {
						ilike: lastnamestring,
					},
				},
				{
					lastname: {
						ilike: `${splitname[0]}%`,
					},
				},
				// ],
				// }
			],
			// }
		};
		return this.clientApi.count(filter);
	}
	searchCreateJobClients(clientname: string) {
		// console.log(clientname);
		let filter: LoopBackFilter = {
			where: {},
		};
		let splitname = clientname.split(' ');
		if (splitname.length == 2) {
			//strict case "firstname lastname"
			filter.where = {
				and: [
					{ firstname: { ilike: `%${splitname[0]}%` } },
					{ lastname: { ilike: `%${splitname[1]}%` } },
				],
			};
			filter['where']['and'].push({ active: true });
			filter['limit'] = 30;
		} else if (splitname.length > 2) {
			const splitLength = splitname.length;
			const shortLastname = splitname[splitLength - 1];
			const longFirstname = splitname.slice(0, splitLength - 1).join(' ');
			const shortFirstname = splitname[0];
			const longLastName = splitname.slice(1, splitLength).join(' ');
			//firstname (long)
			//lastname (oneword)
			// OR
			//firstname (oneword)
			//lastname (long)
			filter.where = {
				or: [
					{
						and: [
							{ firstname: { ilike: `${longFirstname}` } },
							{ lastname: { ilike: `${shortLastname}` } },
							{ active: true },
						],
					},
					{
						and: [
							{ firstname: { ilike: `${shortFirstname}` } },
							{ lastname: { ilike: `${longLastName}` } },
							{ active: true },
						],
					},
				],
			};
			filter['limit'] = 40;
		} else if (splitname.length == 1) {
			// firstname or lastname
			filter.where = {
				or: [
					{
						and: [
							{ firstname: { ilike: `%${splitname[0]}%` } },
							{ active: true },
						],
					},
					{
						and: [
							{ lastname: { ilike: `%${splitname[0]}%` } },
							{ active: true },
						],
					},
				],
			};
			filter['limit'] = 60;
		}
		// var lastnamestring = "";
		// if (splitname.length > 1 && splitname[1].length > 0) {
		//     lastnamestring = `${splitname[1].trim()}%`;
		// }
		// let filter: LoopBackFilter = {
		//     where: {
		//         or: [
		//             {
		//                 firstname: {
		//                     ilike: `${splitname[0].trim()}%`
		//                 }
		//             },
		//             {
		//                 or: [
		//                     {
		//                         lastname: {
		//                             ilike: lastnamestring
		//                         }
		//                     },
		//                     {
		//                         lastname: {
		//                             ilike: `${splitname[0]}%`
		//                         }
		//                     }
		//                 ],
		//             }]
		//     }
		// };
		filter['skip'] = 0;
		filter['limit'] = 40;
		return this.clientApi.find(filter);
	}
	searchCreateJobParcelAddressCount(filterArray: any) {
		let filter: any = {
			and: filterArray.concat({ addressparentdetailsType: 'Parcel' }),
		};
		return this.addressApi.count(filter);
	}
	searchCreateJobParcelAddress(filter: LoopBackFilter) {
		if (!filter.limit) {
			filter.limit = 20;
		}
		filter.skip = 0;
		return this.parcelApi.find(filter);
	}

	private buildGlobalParcelSearchFilter(key: any) {
		var parcelObj = addrparser.parseLocation(key);
		if (parcelObj != null) {
			var filterArray = [];
			_.mapObject(parcelObj, (value, key) => {
				var temp = {};
				temp[key] = { ilike: `${value}%` };
				filterArray.push(temp);
			});
		}
		let filter: LoopBackFilter = {
			where: {
				and: filterArray.concat({ addressparentdetailsType: 'Parcel' }),
				include: { relation: 'parcel' },
			},
		};
		return filter;
	}
	getParcelsForGlobalSearchCount(key: any) {
		return this.addressApi.count(this.buildGlobalParcelSearchFilter(key));
	}
	getParcelsForGlobalSearch(key: any) {
		return this.addressApi.find(this.buildGlobalParcelSearchFilter(key));
	}

	// getOnDemandMapJSON(boundObj) {
	//     return this.parcelApi.getOnDemandMapJSON(boundObj);
	// }
	checkTasks() {
		return this.taskApi.find({});
	}

	/**
	 * Get all custom tasks from task table
	 *
	 * @returns Observable
	 * @memberof DataServiceService
	 */
	getCustomTasks() {
		const filter: LoopBackFilter = {
			where: { iscustom: true },
		};
		return this.taskApi.find(filter);
	}
	/**
	 * Get all non-custom tasks from task table (iscustom === false)
	 *
	 * @returns Observable
	 * @memberof DataServiceService
	 */
	getTasks() {
		const filter: LoopBackFilter = {
			where: { iscustom: false },
		};
		return this.taskApi.find(filter);
	}

	createNewTask(task) {
		return this.taskApi.create(task);
	}
	updateTask(taskid, data) {
		return this.taskApi.updateAttributes(taskid, data);
	}
	archiveExistingTask(taskid, data) {
		var payload = Object.assign(data, { archived: true });
		return this.taskApi.updateAttributes(taskid, payload);
	}
	unArchiveExistingTask(taskid, data) {
		var payload = Object.assign(data, { archived: false });
		return this.taskApi.updateAttributes(taskid, payload);
	}
	createNewJobBase(job) {
		//cc:createnewjob#9;forward base payload to api
		return this.jobApi.create(job);
	}
	createNewJobOps(data) {
		//cc:createnewjob#15;secondary job payload - operations
		return this.operationApi.create(data).toPromise();
	}
	createNewJobTasks(data) {
		//cc:createnewjob#17;secondary job payload - services
		return this.serviceApi.create(data).toPromise();
	}
	createNewJobFlags(data) {
		//cc:createnewjob#19;secondary job payload - flags
		return this.flagApi.create(data).toPromise();
	}
	updateJobFlag(data) {
		// return this.flagApi.updateAttributes(data.id, data).toPromise();
		//the updateAll where clause is a little weird and doesn't need a 'where' property, it can be passed the conditions directly
		var whereClause: LoopBackFilter = {};
		// whereClause['and'] = [{ id: data.id }, { jobid: data.jobid }, { badgeid: data.badgeid }];
		whereClause['and'] = [{ jobid: data.jobid }, { badgeid: data.badgeid }];
		// whereClause['id'] = data.id;
		// whereClause['badgeid'] = data.badgeid;
		// whereClause['jobid'] = data.jobid;
		return this.flagApi.updateAll(whereClause, data).toPromise();
	}
	updateJobComments(data) {
		return this.jobApi.updateAttributes(data.id, data).toPromise();
	}
	updateJobDueDate(data) {
		return this.jobApi.updateAttributes(data.id, data).toPromise();
	}
	updateJobManaging(data) {
		return this.jobApi.updateAttributes(data.id, data).toPromise();
	}
	getOperationId(filter: any) {
		return this.operationApi.findOne(filter).toPromise();
	}
	updateJobOperation(whereObj: any, dataObj: any) {
		return new Promise((resolve, reject) => {
			var whereFilter: LoopBackFilter = { where: whereObj };
			this.getOperationId(whereFilter).then((op: any) => {
				this.jobApi
					.updateByIdOperations(op.jobid, op.id, dataObj)
					.subscribe((res: any) => {
						resolve(null);
					});
			});
		});
		// return this.operationApi.patchAttributes( whereObj, dataObj);
		// return this.operationApi.upsertWithWhere( whereObj, dataObj);
	}
	userUpdatedAssignedStage(opsid, data) {
		this.operationApi.updateAttributes(opsid, data).subscribe(
			(info) => {
				// console.log(info)
			},
			(err) => {
				throw (err);
			}
		);
	}
	createJobTaskAssignment(data) {
		return this.serviceApi.create(data);
	}
	removeJobTaskAssignment(uniqueserviceid: number) {
		return this.serviceApi.deleteById(uniqueserviceid);
	}

	/**
	 *
	 *
	 * @param {*} id - The id in service table (not taskid and jobid)
	 * @param {*} data - The data to be updated (example: {customrate: 9999, iscustom: true} )
	 * @returns {Observable}
	 * @memberof DataServiceService
	 */
	updateServiceById(id, data) {
		return this.serviceApi.updateAll({ id: id }, data);
	}

	/**
	 *
	 *
	 * @param {*} jobid
	 * @param {*} taskid
	 * @param {*} data - The data to be updated (example: {customrate: 9999, iscustom: true})
	 * @returns {Observable}
	 * @memberof DataServiceService
	 */
	updateService(jobid, taskid, data) {
		return this.serviceApi.updateAll({ jobid: jobid, taskid: taskid }, data);
	}

	getOfficeTaxRate(officeid: number) {
		let filter: LoopBackFilter = {
			// where: { id: officeid },
			// fields: { taxrate: true }
		};
		return this.officeApi.findById(officeid, filter);
	}
	//role aka. position (model)
	createRole(data: any) {
		return this.positionApi.create(data);
	}
	updateRolePermissions(data: any) {
		return this.permissionsApi.updateExistingPositionPermissions(data);
	}
	updateStageRoles(data: any) {
		return this.stageApi.updateStageRoleAssignment(data);
	}
	getUploadedMapLayers() {
		return this.layerApi.find();
	}
	checkIfMapLayerExists(data: any) {
		let filter: LoopBackFilter = {
			where: { name: data.layername },
		};
		return this.layerApi.find(filter);
	}
	createMapLayer(data: object) {
		return this.layerApi.create(data);
	}
	analyzeParcelOnPoint(data) {
		return this.parcelApi.analyzeParcel(data);
	}

	private setOctetStreamHeaders(oldheaders) {
		var customheaders: HttpHeaders = new HttpHeaders();
		customheaders = customheaders.append(
			'Content-Type',
			'application/octet-stream'
		);
		customheaders = customheaders.append(
			'Authorization',
			LoopBackConfig.getAuthPrefix() + this.auth.getAccessTokenId()
		);
		// headers.lazyUpdate[2] = { 'Content-Length': `${jsonbin.size}`, op: "a" };
		return customheaders;
	}

	private setPdfStreamHeaders(oldheaders) {
		var customheaders: HttpHeaders = new HttpHeaders();
		customheaders = customheaders.append('Content-Type', 'application/pdf');
		customheaders = customheaders.append(
			'Authorization',
			LoopBackConfig.getAuthPrefix() + this.auth.getAccessTokenId()
		);
		// headers.lazyUpdate[2] = { 'Content-Length': `${jsonbin.size}`, op: "a" };
		return customheaders;
	}

	private setMultipartFormHeaders(oldheaders) {
		var customheaders: HttpHeaders = new HttpHeaders();
		//DO NOT ACTUALLY SET CONTENT-TYPE headerslack
		// customheaders = customheaders.append('Content-Type', 'multipart/form-data');
		customheaders = customheaders.append(
			'Authorization',
			LoopBackConfig.getAuthPrefix() + this.auth.getAccessTokenId()
		);
		// headers.lazyUpdate[2] = { 'Content-Length': `${jsonbin.size}`, op: "a" };
		return customheaders;
	}

	uploadSpatialDataFile(file: any) {
		var ref: DataServiceService = this;
		// var body = { config: { params: configObj, layername: layername }, data: geoJSON };
		// var body = {"file":file};
		// var jsonbin = new Blob([JSON.stringify(body)], { type: "application/octet-stream" });
		var binfile = new Blob([file]);
		//send the data as a file stream for speed/memory pressure tolerance
		// return this.webWorkerUploadSpatialData(this.webWorkerParcelUpload, jsonbin)
		// switch (layertype) {
		// case "Parcel": {
		//All file types go through the endpoint on the parcelApi
		//it's difficult to implement authentication on custom routes
		return this.parcelApi.uploadData({}, {}, binfile, (headers) => {
			return ref.setOctetStreamHeaders(headers);
			// var customheaders: HttpHeaders = new HttpHeaders();
			// customheaders = customheaders.append('Content-Type', 'application/octet-stream');
			// customheaders = customheaders.append(
			//     'Authorization',
			//     LoopBackConfig.getAuthPrefix() + this.auth.getAccessTokenId()
			// );
			// // headers.lazyUpdate[2] = { 'Content-Length': `${jsonbin.size}`, op: "a" };
			// return customheaders;
			// let options = new RequestOptions({ headers: headers });
			// return headers;
		});
		// break;
		// }
		// }
	}
	extractParcelsByConfig(payload: any) {
		return this.parcelApi.extractParcelsFromFile({}, {}, payload);
	}
	extractPointsByConfig(payload: any) {
		return this.coordinatePointApi.extractPointsFromFile({}, {}, payload);
	}
	extractParcelPolygons(payload: any) {
		return this.parcelApi.exportPolygonsFromNewParcelData({}, {}, payload);
	}
	extractParcelAddresses(payload: any) {
		return this.parcelApi.exportAddressesFromNewParcelData({}, {}, payload);
	}
	cleanupUploadedTable(payload: any) {
		return this.parcelApi.cleanupTemporaryTable(payload);
	}
	cleanupUploadedFile(payload: any) {
		return this.parcelApi.cleanupTemporaryFile(payload);
	}
	extractPolygonsByConfig(payload: any) {
		return this.polygonApi.extractPolygonsFromFile({}, {}, payload);
	}
	extractLinesByConfig(payload: any) {
		return this.lineApi.extractLinesFromFile({}, {}, payload);
	}
	// uploadParcelLayerData(geoJSON: any, configObj: any, layername: string) {
	//     var ref: DataServiceService = this;
	//     var body = { config: { params: configObj, layername: layername }, data: geoJSON };
	//     // var jsonbin = new Blob([JSON.stringify(body)], { type: "application/octet-stream" });
	//     var jsonbin = new Blob([JSON.stringify(body)]);
	//     //send the data as a file stream for speed/memory pressure tolerance
	//     // return this.webWorkerUploadSpatialData(this.webWorkerParcelUpload, jsonbin)
	//     return this.parcelApi.uploadData({}, {}, jsonbin, (headers) => {
	//         return ref.setOctetStreamHeaders(headers);
	//             //         // var customheaders: HttpHeaders = new HttpHeaders();
	//         // customheaders = customheaders.append('Content-Type', 'application/octet-stream');
	//         // customheaders = customheaders.append(
	//         //     'Authorization',
	//         //     LoopBackConfig.getAuthPrefix() + this.auth.getAccessTokenId()
	//         // );
	//         // // headers.lazyUpdate[2] = { 'Content-Length': `${jsonbin.size}`, op: "a" };
	//         // return customheaders;
	//         // let options = new RequestOptions({ headers: headers });
	//         // return headers;
	//     });
	// }
	// uploadBenchmarkLayerData(geoJSON: any, configObj: any) {
	//     let body = { config: configObj, data: geoJSON }
	//     return this.parcelApi.uploadBenchmarkData(body);
	// }
	// uploadPolygonLayerData(geoJSON: any, configObj: any, layername: string) {
	//     var ref: DataServiceService = this;
	//     var body = { config: { params: configObj, layername: layername }, data: geoJSON };
	//     // var jsonbin = new Blob([JSON.stringify(body)], { type: "application/octet-stream" });
	//     var jsonbin = new Blob([JSON.stringify(body)]);
	//     //send the data as a file stream for speed/memory pressure tolerance
	//     return this.polygonApi.extractPolygonsFromFile({}, {}, jsonbin, (headers) => {
	//         return ref.setOctetStreamHeaders(headers);
	//             //         // var customheaders: HttpHeaders = new HttpHeaders();
	//         // customheaders = customheaders.append('Content-Type', 'application/octet-stream');
	//         // customheaders = customheaders.append(
	//         //     'Authorization',
	//         //     LoopBackConfig.getAuthPrefix() + this.auth.getAccessTokenId()
	//         // );
	//         // // headers.lazyUpdate[2] = { 'Content-Length': `${jsonbin.size}`, op: "a" };
	//         // return customheaders;
	//         // // let options = new RequestOptions({ headers: headers });
	//         // // return headers;
	//     });
	// }

	updateAvailableMapLayers(layerid: number, flag: boolean) {
		let filter: LoopBackFilter = { where: { id: layerid } };
		return this.layerApi.updateAll(filter, { maplayer: flag });
	}

	getAvailableMapLayers() {
		let filter: LoopBackFilter = { where: { maplayer: true } };
		return this.layerApi.find(filter);
	}

	getAnalysisLayers() {
		let filter: LoopBackFilter = {
			where: {
				or: [
					{ pointinpolylayer: true },
					{ nearestlayer: true },
					{ linklayer: true },
				],
			},
		};
		return this.layerApi.find(filter);
	}
	queryAnalysisLayer(layer: string, querytype: string, lnglat: any) {
		switch (querytype) {
			case 'pointinpolylayer': {
				return this.polygonApi.analyze({ layer: layer, coords: lnglat });
				// break;
			}
			case 'nearestlayer': {
				var latlng = lnglat.slice().reverse();
				return this.coordinatePointApi.findOne({
					where: {
						pt: { near: latlng, unit: 'miles' },
						coordinateparentdetailsType: layer,
					},
				});
			}
			case 'linklayer': {
				break;
			}
		}
	}
	getNextJobMaskNumber() {
		return this.jobApi.nextJobNumberForOffice();
	}

	getJobsByMaskNumberCount(key: string) {
		return this.jobApi.countBase({ mask: { like: `%${key}%` } });
	}

	getJobsByMaskNumber(key: string, skipVal = 0) {
		let filter: LoopBackFilter = Object.assign({}, this.defaultLbFilter, {
			where: { mask: { like: `%${key}%` } },
		});
		filter.skip = skipVal;
		return this.jobApi.getBase(filter);
	}

	queryNearestJob(coords: any) {
		return this.jobApi.nearestJob({ coords: coords });
	}
	getMapActiveJobPoints(jobId: number = null) {
		if (!jobId) {
			return this.jobApi.getActive();
		} else {
			const mapfilter = {
				where: { id: jobId },
				limit: 1
			}
			return this.jobApi.getActive(mapfilter);
		}
	}
	upsertDeliverableRecord(data: any) {
		// return this.deliverableApi.patchOrCreate(data)
	}
	checkForExistingJobDeliverables(jobid) {
		let filter: LoopBackFilter = {
			where: { jobid: jobid },
		};
		return this.deliverableApi.find(filter);
	}
	uploadDeliverableFile(fileObjList: any, jobid: number) {
		var ref: DataServiceService = this;

		let promises = [];
		_.each(fileObjList, (file, i) => {
			var promise = new Promise((resolve, reject) => {
				let formData = new FormData();
				formData.append('jobNum', jobid.toString());
				// formData.append('token', this.usr['token']);
				// for (var i = 0; i < fileObjList.length; i++) {
				// fileObjList[i]['name'] = `deliverables/${fileObjList[i]['name']}`;
				formData.append(`${i}`, fileObjList[i]);
				// }
				//let headers = new Headers({ /*"Accept":"application/octet-stream",*/ /*"Content-type":"multipart/form-data"*/ });
				//let options = new RequestOptions({ headers: headers });
				// let params = {"jobNum":jobNum, fileName:fileObj.file.name};
				// this.http.post(`https://${this.HOST_NAME}/uploadFilesToJobFolder`, formData/*, options*/).subscribe(res => {
				//cc:jobdeliverableupload#4;forward deliverables to API
				this.azureApi
					.uploadDeliverable({}, {}, jobid, formData, (headers) => {
						return ref.setMultipartFormHeaders(headers);
					})
					.subscribe(
						(result) => {
							// var data = JSON.parse(result['_body']);
							resolve(result);
						},
						(err) => {
							reject(err);
						}
					);
				// this.http.post(this.genLbUrlString("azure/uploadDeliverable"), formData, options).subscribe(res => {
				//   var data = JSON.parse(res['_body']);
				//                   //   resolve(data);
				// });
				// this.makeApiRequest(`azure/job-${jobNum}/upload`, 'post', formData).then(resp => {
				//   var data = JSON.parse(resp['_body']);
				//   resolve(data);
				// });
			});
			promises.push(promise);
		});

		return Promise.all(promises);

		// return new Promise((resolve, reject) => {
		//     let formData = new FormData();
		//     formData.append('jobNum', jobid.toString());
		//     // formData.append('token', this.usr['token']);
		//     for (var i = 0; i < fileObjList.length; i++) {
		//         // fileObjList[i]['name'] = `deliverables/${fileObjList[i]['name']}`;
		//         formData.append(`${i}`, fileObjList[i]);
		//     }
		//     //let headers = new Headers({ /*"Accept":"application/octet-stream",*/ /*"Content-type":"multipart/form-data"*/ });
		//     //let options = new RequestOptions({ headers: headers });
		//     // let params = {"jobNum":jobNum, fileName:fileObj.file.name};
		//     // this.http.post(`https://${this.HOST_NAME}/uploadFilesToJobFolder`, formData/*, options*/).subscribe(res => {
		//     //cc:jobdeliverableupload#4;forward deliverables to API
		//     this.azureApi.uploadDeliverable({}, {}, formData, (headers) => {
		//         return ref.setMultipartFormHeaders(headers);
		//     })
		//         .subscribe(result => {
		//             // var data = JSON.parse(result['_body']);
		//                     //             resolve(result);
		//         }, err => {
		//             reject(err);
		//         })
		//     // this.http.post(this.genLbUrlString("azure/uploadDeliverable"), formData, options).subscribe(res => {
		//             //     //   var data = JSON.parse(res['_body']);
		//             //     //           //     //   resolve(data);
		//     // });
		//     // this.makeApiRequest(`azure/job-${jobNum}/upload`, 'post', formData).then(resp => {
		//             //     //   var data = JSON.parse(resp['_body']);
		//             //             //     //   resolve(data);
		//     // });
		// });
	}

	downloadDeliverableFileUrl(jobid: number, deliverableId: number) {
		var ref: DataServiceService = this;
		let downloadbody = { job: jobid, deliverableId: deliverableId };
		return this.azureApi.downloadDeliverableUrl(downloadbody);
	}

	downloadDeliverableFile(sasUrl: string) {
		return new Promise((resolve, reject) => {
			// let options = new RequestOptions({ responseType: ResponseContentType.Blob });
			this.http.get(sasUrl, { responseType: 'blob' }).subscribe((res) => {
				// dspRef.http.get(url).subscribe(res => {
				resolve(res);
			});
		});
	}

	setDeliverableVisibility(deliverable: any, flag: boolean) {
		deliverable.visible = flag;
		return this.deliverableApi.updateAttributes(
			deliverable.id,
			deliverable
		);
	}

	deleteDeliverableFile(jobid: number, deliverableId: number) {
		return this.azureApi.deleteDeliverable({
			job: jobid,
			deliverableId: deliverableId,
		});
	}

	getServiceData(uniqueid: number) {
		return this.serviceApi.findById(uniqueid);
	}
	getJobServiceDataByJobId(jobid: number) {
		return this.jobApi.getServices(jobid);
	}
	getTaskBaseForRefresh() {
		return this.taskApi.find();
	}
	createNewJobInvoiceRecord(data: any) {
		//cc:generateinvoice#5;sends payload to api
		return this.invoiceApi.create(data).pipe(take(1));
	}
	getJobInvoiceRecords(jobid: number) {
		let filter: LoopBackFilter = {
			// where: { and: [{ hasbeeninvoiced: true }, { clientid: clientidval }, { nin: alreadySelectedJobIds }] },
			where: { jobid: jobid },
			order: 'created DESC',
		};
		return this.invoiceApi.find(filter);
	}
	getJobPaymentRecords(jobid: number) {
		let filter: LoopBackFilter = {
			// where: { and: [{ hasbeeninvoiced: true }, { clientid: clientidval }, { nin: alreadySelectedJobIds }] },
			where: { id: jobid },
			include: { relation: 'payments' },
		};
		return this.jobApi.findOne(filter).pipe(
			take(1),
			map((job: any) => {
				return job.payments;
			})
		);
	}
	getJobInvoiceFile(jobid: number, invoiceiteration: number) {
		//cc:generateinvoice#9;get invoice pdf file with SAS tokens in URL
		var ref: DataServiceService = this;
		let body = { jobid: jobid, iterationnumber: invoiceiteration };
		// let options = new RequestOptions({ responseType: ResponseContentType.Blob });
		return this.http
			.post(`${this.baseUrl}/api/Invoices/getInvoiceFile`, body, {
				responseType: 'blob',
			});
	}
	getJobsWhichHaveBeenInvoiced(
		clientidval: number,
		alreadySelectedJobIds: number[] = null
	) {
		let filter: LoopBackFilter = {
			// where: { and: [{ hasbeeninvoiced: true }, { clientid: clientidval }, { nin: alreadySelectedJobIds }] },
			where: { and: [{ hasbeeninvoiced: true }, { clientid: clientidval }] },
			order: 'created DESC',
		};
		return this.jobApi.find(filter);
	}
	submitPaymentRecord(data: any) {
		//cc:generatepayment#4;payment forwarded to API
		return this.paymentApi.create(data);
	}
	getFinanceTotals() {
		return this.invoiceApi.totals(this.filteredOfficeId);
	}
	manuallyMarkJobFieldworkComplete(jobid: number, datetimevalue: any) {
		let constraint = { id: jobid };
		let data = { fieldworkcomplete: datetimevalue };
		return this.jobApi.updateAll(constraint, data);
	}
	manuallyCloseJob(jobid: number, flag: boolean) {
		var constraint = { id: jobid };
		var data = { closed: flag };
		return this.jobApi.updateAll(constraint, data);
	}
	setConfigValue(key: string, value: any) {
		var filter = { key: key };
		return this.cardinalconfigApi.updateAll(filter, { value: value });
	}
	// uploadLinkLayerData(geoJSON: any, configObj: any) {
	//     let body = { config: configObj, data: geoJSON }
	//     return this.parcelApi.uploadLinkData(body);
	// }
	// lookupClientByClientIdAndCompanyId(clientidnum:number, companyidnum:number){
	//     let filter:LoopBackFilter = {include:{"companies":{where:{companyid:companyidnum}}}};
	//     return this.clientApi.findById(clientidnum, filter);
	// }
	// private webWorkerParcelUpload(jsonbin:any){
	//     var ref: DataServiceService = this;
	//     this.parcelApi.uploadData({}, {}, jsonbin, (headers) => {
	//         return ref.setOctetStreamHeaders(headers);
	//     });
	// }
	// private webWorkerUploadSpatialData(fxn:Function, data:any) {
	//     const promise = this._webWorkerService.run(fxn, data);
	//     const result = new Result(data, 0 ,true);
	//     this.webWorkerResults.push(result);
	//     this.webWorkerPromises.push(promise);
	//     // const result = new Result(n, 0, true);
	//     // this.webWorkerResults.push(result);
	//     // this.promises.push(promise);
	//     promise.then(function(response) {
	//         result.result = response;
	//         result.loading = false;
	//     });
	//     return from(promise);
	// }
	getClientById(id: number) {
		return this.clientApi.findById(id);
	}

	updateMapLayerProperties(layer: any, key, value) {
		let filter = { id: layer.id };
		let payload = {};
		payload[key] = value;
		return this.layerApi.updateAll(filter, payload);
	}

	sendClientInvoiceEmail(invoiceidobj) {
		return this.invoiceApi.emailClientInvoice(invoiceidobj);
	}

	/**
	 *
	 *
	 * @param {number} jobId - ID of the job
	 * @param {string} [action='updateJobInfo'] - describe the action
	 * @returns {*}
	 * @memberof DataServiceService
	 */
	sendJobUpdateEmail(jobId: number, action = 'updateJobInfo'): any {
		return this.jobApi.sendUpdateEmail({
			jobId: jobId,
			userId: this.user,
			action: action,
		});
	}

	/**
	 * (Updates client & stripe information such as: firstname, lastname, email. We update Cardinal's client information
	 * and stripes customer information)
	 *
	 * @param {*} clientData
	 * @returns {Observable<any>} - An observerable that returns a list of stripe customer objects
	 * @memberof DataServiceService
	 */
	updateClientInfo(clientData: any): Observable<any> {
		if (clientData.email.trim().length === 0) {
			// generate random unique client's email because it's a required field
			clientData.email = `needsanuniqueemail@${clientData.firstname}${clientData.lastname
				}${Math.floor(Math.random() * Math.floor(99999))}.email`.toLowerCase();
		}
		const persistedClientData = _.pick(
			clientData,
			'firstname',
			'lastname',
			'companyids',
			'email'
		);
		return this.clientApi
			.updateAll({ id: clientData.id }, persistedClientData)
			.pipe(
				mergeMap((result) => {
					return this.updateStripeCustomer(clientData);
				})
			);
	}

	/**
	 * (Updates the stripe customer information for a client. Needs a full client object.)
	 *
	 * @param {*} clientData
	 * @returns {Observable<any>} - Returns a stripe customer list if there were stripe customers to update
	 * @memberof DataServiceService
	 */
	updateStripeCustomer(clientData: any): Observable<any> {
		return this.clientApi.updateStripeCustomer(clientData);
	}

	/**
	 * Allows user to updates selected clients 'active' column in DB
	 *
	 * @param {*} activeStatus
	 * @returns
	 * @memberof DataServiceService
	 */
	updateClientActiveStatus(clientID: any, activeStatus: any) {
		return this.clientApi.updateAll({ id: clientID }, { active: activeStatus });
	}

	// Also need to delete entry from jobparcel.
	// TODO: remove unlinkParcels after remove parcelIds from job table
	removeParcelFromJob(jobId, parcelId) {
		return forkJoin([
			this.jobApi.unlinkParcels(jobId, parcelId),
			this.jobParcelApi.deleteWhere({ jobid: jobId, parcelid: parcelId }),
		]);
	}

	/* --------------------------CLIENTCOMPANY METHODS--------------------- */

	/**
	 * This will create a new clientcompany (can also use upsertClientCompany)
	 *
	 * @param {*} data
	 * @returns {Observable<any>}
	 * @memberof DataServiceService
	 */
	createClientCompany(data: any): Observable<any> {
		if (!data.companyid) {
			data.companyid = 0;
		}
		return this.clientCompanyApi.create(data);
	}

	/**
	 * Update clientcompany row(s) that match the table id
	 *
	 * @param {Number} id
	 * @param {*} data
	 * @returns {Observable<any>}
	 * @memberof DataServiceService
	 */
	updateClientCompanyById(id: Number, data: any): Observable<any> {
		return this.clientCompanyApi.updateAll({ id: id }, data);
	}
	/**
	 * Update clientcompany row(s) that match the clientid and companyid fields
	 *
	 * @param {Number} clientId clientid attribute
	 * @param {number} [companyId=null] companyidattribute (nullable)
	 * @param {*} data new data
	 * @returns {Observable<any>}
	 * @memberof DataServiceService
	 */
	updateClientCompany(
		clientId: Number,
		companyId: Number = null,
		data: any
	): Observable<any> {
		return this.clientCompanyApi.updateAll(
			{ clientid: clientId, companyid: companyId },
			data
		);
	}

	/**
	 * Check if clientcompany object exists. Return true if exists.
	 *
	 * @param {Number} clientId
	 * @param {Number} [companyId=null]
	 * @returns {Observable<any>}
	 * @memberof DataServiceService
	 */
	doesClientCompanyExist(
		clientId: Number,
		companyId: Number = null
	): Observable<any> {
		const filter: LoopBackFilter = { where: {} };
		filter['and'] = [{ clientid: clientId }, { companyid: companyId }];
		return this.clientCompanyApi.count(filter).pipe(
			take(1),
			map((response) => {
				if (response.count === 0) {
					return false;
				} else {
					return true;
				}
			})
		);
	}

	/**
	 * Insert or update a row in clientcompany (create STRIPE customer id if STRIPE_ENABLE === 1)
	 *
	 * @param {Number} clientId clientid attribute (required)
	 * @param {Number} [companyId=null] companyid attribute (nullable)
	 * @param {*} [data=null] other attributes (nullable)
	 * @returns
	 * @memberof DataServiceService
	 */
	upsertClientCompany(
		clientId: Number,
		companyId: Number = null,
		data: any = null
	) {
		if (data === null) {
			data = { clientid: clientId, companyid: companyId };
		}
		if (Number(this.environment.STRIPE_ENABLE) === 1) {
			data = { clientid: clientId, companyid: companyId };
			return this.clientCompanyApi.createStripeCustomer(data);
		}
		return this.clientCompanyApi.upsertWithWhere(
			{ clientid: clientId, companyid: companyId },
			data
		);
	}
	/**
	 * Delete a row in clientcompany given id
	 *
	 * @param {Number} id
	 * @returns {Observable<any>}
	 * @memberof DataServiceService
	 */
	deleteClientCompanyById(id: Number): Observable<any> {
		return this.clientCompanyApi.deleteById(id);
	}

	/**
	 * Get a clientcompany row given the table id
	 *
	 * @param {Number} id
	 * @returns {Observable<any>} An object of clientcompany with client and company (if not null) relations
	 * @memberof DataServiceService
	 */
	findOneClientCompanyById(id: Number): Observable<any> {
		return this.clientCompanyApi
			.findOne({ where: { id: id }, include: ['client', 'company'] })
			.pipe(
				map((clientCompany: any) => {
					if (clientCompany.companyid === null && clientCompany.company) {
						delete clientCompany.company;
					}
					return clientCompany;
				})
			);
	}

	/**
	 * Get all data in clientcompany table
	 *
	 * @returns {Observable<any>} An array of clientcompanies with client and company (if not null) relations
	 * @memberof DataServiceService
	 */
	getClientCompanies(): Observable<any> {
		const filter: LoopBackFilter = {
			include: ['client', 'company'],
		};
		return this.clientCompanyApi.find(filter).pipe(
			map((clientCompanies: any) => {
				clientCompanies = _.map(clientCompanies, (row) => {
					if (row.companyid === null && row.company) {
						delete row.company;
					}
					return row;
				});
				return clientCompanies;
			})
		);
	}
	/*--------------------- END CLIENTCOMPANY METHODS---------------------------------- */

	/**
	 * Insert or update a row in clientcompany, also create a Stripe customer object and update clientcompany's stripecustomerid
	 *
	 * @param {Number} clientId clientid attribute (required)
	 * @param {Number} [companyId=null] companyid attribute (nullable)
	 * @returns
	 * @memberof DataServiceService
	 */
	createClientCompanyWithStripe(clientId: Number, companyId: Number = null) {
		const data = { clientid: clientId, companyid: companyId };
		return this.clientCompanyApi.createStripeCustomer(data);
	}

	/**
	 * Change the payment status to rolledback and update the comment
	 * Rollback has 2 parts: change the status of a payment to rolledback and insert a new payment with a negative amount
	 *
	 * @param {number} jobId
	 * @param {number} paymentId
	 * @param {string} comment
	 * @returns {Observable<any>}
	 * @memberof DataServiceService
	 */
	rollbackPayment(
		jobId: number,
		paymentId: number,
		comment: string
	): Observable<any> {
		return this.paymentApi.updateAll(
			{ id: paymentId },
			{ rolledback: true, comment: comment }
		);
	}

	/**
	 * Return a Stripe Invoice Object | null is STRIPE is not enabled
	 *
	 * @param {number} invoiceId Cardinal Invoice ID (Not Stripe)
	 * @returns {Observable<any>}
	 * @memberof DataServiceService
	 */
	getStripeInvoice(invoiceId: number): Observable<any> {
		if (Number(this.environment.STRIPE_ENABLE) === 1) {
			return this.invoiceApi.retrieveStripeInvoice(invoiceId);
		}
		return empty();
	}

	/* HANDLING JOB MASK/NUMBER */
	/**
	 * Update Office Job Number (both prefix and nextJObNumber are nullable)
	 *
	 * @param {Number} officeId
	 * @param {string} [prefix=null]
	 * @param {string} [nextJobNumber=null]
	 * @returns {Observable<any>}
	 * @memberof DataServiceService
	 */
	updateOfficeJobNumberSettings(
		officeId: Number,
		prefix: string = null,
		nextJobNumber: string = null
	): Observable<any> {
		return this.companyApi.updateAttributes(officeId, {
			jobmaskprefix: prefix,
			nextjobnumber: nextJobNumber ? parseInt(nextJobNumber) : null,
		});
	}

	/**
	 * Get next job mask for an office.
	 *
	 * @param {Number} officeId
	 * @param {string} [prefix=null] - If the prefix is default value, prefix will be the prefix for this office in db or current year (YY)
	 *                                  (if db prefix value is null)
	 * @returns {Observable<any>} - response Obj: {jobMask: '',prefix: prefix, nextJobNumber: 0}
	 * @memberof DataServiceService
	 */
	getOfficeNextJobMask(
		officeId: Number,
		prefix: string = null
	): Observable<any> {
		return this.officeApi.getNextJobMask(officeId, { prefix: prefix });
	}

	/**
	 * Return true if job mask is available so user can create a job
	 *
	 * @param {Number} officeId
	 * @param {string} jobMask Job Number/Mask to check for existence
	 * @returns {Observable<any>}
	 * @memberof DataServiceService
	 */
	isJobMaskAvailable(officeId: Number, jobMask: string): Observable<any> {
		return this.jobApi.count({ managingoffice: officeId, mask: jobMask }).pipe(
			take(1),
			map((countObj) => {
				if (countObj.count === 0) {
					return true;
				}
				return false;
			})
		);
	}

	/* END HANDLING JOB MASK/NUMBER */

	/**
	 * Given id and new email address. Check if any there are any client except this client already has this email
	 * Observable return true/false
	 *
	 * @param {number} id
	 * @param {string} email
	 * @returns {Observable<boolean>}
	 * @memberof DataServiceService
	 */
	doesClientEmailExist(id: number, email: string): Observable<boolean> {
		const filter: LoopBackFilter = { where: {} };
		filter['and'] = [{ id: { neq: id } }, { email: email }];
		return this.clientApi.count(filter).pipe(
			take(1),
			map((response) => {
				if (response.count === 0) {
					return false;
				} else {
					return true;
				}
			})
		);
	}

	/**
	 * Given id and new email address. Check if any there are any employee except this employee already has this email
	 * Observable return true/false
	 *
	 * @param {number} id
	 * @param {string} email
	 * @returns {Observable<boolean>}
	 * @memberof DataServiceService
	 */
	doesEmployeeEmailExist(id: number, email: string): Observable<boolean> {
		const filter: LoopBackFilter = { where: {} };
		filter['and'] = [{ id: { neq: id } }, { email: email }];
		return this.employeeApi.count(filter).pipe(
			take(1),
			map((response) => {
				if (response.count === 0) {
					return false;
				} else {
					return true;
				}
			})
		);
	}

	/**
	 * Given new email address. Check if any there are any employee except this employee already has this email
	 * Observable return true/false
	 *
	 * @param {string} email
	 * @returns {Observable<boolean>}
	 * @memberof DataServiceService
	 */
	isNewEmployeeEmailUnique(email: string): Observable<boolean> {
		const filter: LoopBackFilter = { where: { email: email } };
		return this.employeeApi.count(filter).pipe(
			take(1),
			map((response) => {
				if (response.count === 0) {
					return true;
				} else {
					return false;
				}
			})
		);
	}

	/**
	 * Create employee, create contactinfo and streetaddress for employee (using the first office's address as default)
	 *
	 * @param {*} employeeData - employee data
	 * @param {*} contactInfo - from the model contactinfo
	 * @param {*} streetAdress - from the model streetadress (relations addressdetails)
	 * @returns {Observable<any>}
	 * @memberof DataServiceService
	 */
	createEmployee(
		employeeData: any,
		contactInfo: any,
		streetAdress: any
	): Observable<any> {
		employeeData = _.pick(
			employeeData,
			'firstname',
			'lastname',
			'email',
			'officeIds',
			'positionIds',
			'preferences'
		);
		// Setting default values
		employeeData.email = employeeData.email.toLowerCase();
		employeeData.emailverified = true;
		employeeData.tfa = false;
		employeeData.realm = 'myrealm';
		employeeData.password = 'randomPassword';
		employeeData.username = employeeData.email.split('@')[0];

		return this.employeeApi.create(employeeData).pipe(
			mergeMap((employeeObject: any) => {
				return this.employeeApi.createContactinfo(
					employeeObject.id,
					contactInfo
				);
			}),
			mergeMap((contactInfoObject: any) => {
				return this.employeeApi.createContactinfoAddressdetails(
					contactInfoObject.contactdetailsId,
					contactInfoObject.id,
					streetAdress
				);
			})
		);
	}

	/**
	 * (Updates an employee using the employee popover form)
	 *
	 * @param {*} employeeData
	 * @returns {Observable<any>}
	 * @memberof DataServiceService
	 */
	updateEmployee(employeeData: any): Observable<any> {
		employeeData = _.pick(
			employeeData,
			'firstname',
			'lastname',
			'email',
			'officeIds',
			'positionIds',
			'contactinfo',
			'employeeId'
		);
		employeeData.email = employeeData.email.toLowerCase();
		const employee = {
			id: employeeData.employeeId,
			firstname: employeeData.firstname,
			lastname: employeeData.lastname,
			email: employeeData.email,
			officeIds: `[${employeeData.officeIds}]`,
			positionIds: `[${employeeData.positionIds}]`,
		};

		const response$ = forkJoin([
			this.employeeApi.updateAll({ id: employee.id }, employee),
			this.contactInfoApi.updateAll(
				{ id: employeeData.contactinfo[0].id },
				employeeData.contactinfo[0]
			),
		]);
		return response$;
	}

	/**
	 * Update office's information
	 *
	 * @param {*} officeData
	 * @returns {Observable<any>}
	 * @memberof DataServiceService
	 */
	updateOfficeInfo(officeData: any): Observable<any> {
		// Getting data from officeData
		const office = {
			id: officeData.officeId,
			name: officeData.name,
			shortname: officeData.shortname,
			taxrate: officeData.taxrate,
		};
		const contact = {
			id: officeData.contactinfoId,
			email: officeData.email,
			phone: officeData.phone,
			fax: officeData.fax,
			line1: officeData.line1,
			line2: officeData.line2,
			city: officeData.city,
			state: officeData.state,
			zip: officeData.zip,
		};

		const response$ = forkJoin([
			this.officeApi.updateAll({ id: office.id }, office),
			this.contactInfoApi.updateAll({ id: contact.id }, contact),
		]);
		return response$;
	}

	/**
	 * (Creates or finds one company and returns that company)
	 *
	 * @param {*} company - {name: 'somecompanyname'}
	 * @returns Observable<any>
	 * @memberof DataServiceService
	 */
	findOrCreateCompany(company: any, contactInfo: any): Observable<any> {
		// Adding contact info data
		contactInfo.contactdetailsId = null;
		contactInfo.contactdetailsType = 'Company';

		return this.companyApi
			.find({ where: { name: { ilike: company.name } }, limit: 1 })
			.pipe(
				mergeMap((companyObject: any) => {
					if (companyObject.length === 0) {
						return this.companyApi.create(company).pipe(
							mergeMap((newCompany: any) => {
								contactInfo.contactdetailsId = newCompany.id; // adding the contactdetailsId
								return this.contactInfoApi.create(contactInfo);
							})
						);
					} else {
						// Create contactinfo for existing company
						contactInfo.contactdetailsId = companyObject[0].id; // adding the contactdetailsId
						return this.contactInfoApi.create(contactInfo);
					}
				}),
				mergeMap((newContact: any) => {
					return this.companyApi.findById(newContact.contactdetailsId);
				})
			);
	}

	addCompaniesToClient(
		clientId: number,
		companyIds: Array<number>,
		contactInfo: any
	) {
		contactInfo.contactdetailsType = 'Company';
		// contactInfo.contactdetailsId
		const arr: Array<Observable<any>> = _.map(companyIds, (id) =>
			this.upsertClientCompany(clientId, id)
		).concat(
			_.map(companyIds, (id) => {
				const contact = _.clone(contactInfo);
				contact.contactdetailsId = id;
				return this.contactInfoApi.create(contact).pipe(
					mergeMap((cont) => {
						return this.companyApi.findById(id);
					}),
					mergeMap((company: any) => {
						const clientIds = company.clientIds;
						clientIds.push(clientId);
						return this.companyApi.updateAll(
							{ id: id },
							{ clientIds: `[${clientIds.join(', ')}]` }
						);
					}),
					mergeMap((company: any) => {
						return this.clientApi.updateAll(
							{ id: clientId },
							{ companyIds: `[${companyIds.join(', ')}]` }
						);
					})
				);
			})
		);
		return forkJoin(arr);
	}

	/**
	 * (Search company by name)
	 *
	 * @param {string} name
	 * @returns {Observable<any>} - Returns an obs that shows the result from the search
	 * @memberof DataServiceService
	 */
	searchCompany(name: string, limit?: number): Observable<any> {
		if (limit) {
			return this.companyApi.find({
				where: { name: { ilike: `%${name}%` }, limit: limit },
				order: 'name ASC',
			});
		}
		return this.companyApi.find({
			where: { name: { ilike: `%${name}%` } },
			order: 'name ASC',
		});
	}

	/**
	 * (Create client, contactinfo, addressdetails, and possible a company depending on clientData)
	 *
	 * @param {*} clientData
	 * @returns Observable<any>
	 * @memberof DataServiceService
	 */
	createNewClient(
		clientData: any,
		companyIds: Array<number> = []
	): Observable<any> {
		let client = _.pick(
			clientData,
			'firstname',
			'lastname',
			'email',
			'realm',
			'password'
		);
		const contactinfo = _.pick(
			clientData.contactinfo,
			'email',
			'phone',
			'fax',
			'line1',
			'line2',
			'city',
			'state',
			'zip'
		);
		const company = _.pick(clientData.company, 'name');

		if (client.email.trim().length === 0) {
			// generate random unique client's email because it's a required field
			client.email = `needsanuniqueemail@${client.firstname}${client.lastname}${moment().format('x')}.email`.toLowerCase().replace(/\s+/g, '');
		}

		let clientId = 0;
		let companyId = 0;

		if (company.name === '') {
			return this.clientApi.create(client).pipe(
				mergeMap((clientObject: any) => {
					clientId = clientObject.id;
					if (companyIds.length > 0) {
						return forkJoin([
							this.addCompaniesToClient(clientId, companyIds, contactinfo),
							this.clientApi.createContactinfo(clientObject.id, contactinfo),
						]);
					} else {
						return this.clientApi.createContactinfo(
							clientObject.id,
							contactinfo
						);
					}
				}),
				mergeMap((value) => {
					return this.clientApi.findById(clientId);
				})
			);
		} else {
			return this.clientApi.create(client).pipe(
				mergeMap((clientObject: any) => {
					clientId = clientObject.id;
					client = clientObject;
					return this.clientApi.createContactinfo(clientObject.id, contactinfo);
				}),
				mergeMap((clientResponseObject) => {
					// Check if company already exists
					return this.findOrCreateCompany(company, contactinfo);
				}),
				mergeMap((companyObject: any) => {
					let clientIds = companyObject.clientIds;
					clientIds.push(clientId);
					clientIds = `[${clientIds.join(', ')}]`;
					const obs = [
						this.upsertClientCompany(clientId, companyObject.id),
						this.clientApi.updateAll(
							{ id: clientId },
							{ companyIds: `[${companyObject.id.toString()}]` }
						),
						this.companyApi.updateAll(
							{ id: companyObject.id },
							{ clientIds: clientIds }
						),
						this.clientApi.findById(clientId),
					];
					// Adding extra companies if user selected more
					if (companyIds.length > 0) {
						obs.push(
							this.addCompaniesToClient(clientId, companyIds, contactinfo)
						);
					}
					return forkJoin(obs);
				}),
				mergeMap((forkResult) => {
					return this.clientApi.findById(clientId);
				})
			);
		}
	}
	/**
	 * (Gets Parcel from id)
	 *
	 * @param {string} id - ex: (1 - Max Parcel ID Number)
	 * @memberof DataServiceService
	 */
	getParcelByID(id: number) {
		return this.parcelApi.findOne({ where: { id: id }, limit: 1 });
	}

	/**
	 *
	 *
	 * @param {*} id - The id in parcel table (not taskid and jobid)
	 * @param {*} data - The data to be updated (example: {customrate: 9999, iscustom: true} )
	 * @returns {Observable}
	 * @memberof DataServiceService
	 */
	updateParcelById(id, data): Observable<any> {
		return this.parcelApi.updateAll({ id: id }, data);
	}

	/* Get Payment (ACTUAL NAME: SALESTAX REPORT) report (see sales-tax-report.page)
	 *
	 * @param {number} officeId To retrieve payment report per office
	 * @param {Date} [startDate=null] Query all jobs that have payment starting from this date
	 * @param {Date} [endDate=null] Query all jobs that have payment ending at this date
	 * @returns {Observable<any>}
	 * @memberof DataServiceService
	 */
	getSalesTaxReport(
		officeId: number,
		startDate: Date,
		endDate: Date
	): Observable<any> {
		return this.jobApi.salesTaxReport({
			officeId: officeId,
			startDate: startDate,
			endDate: endDate,
		});
	}

	/**
	 * Get Aged Accounts Receivables Report
	 *
	 * @param {number} officeId To retrieve payment report per office
	 * @returns {Observable<any>}
	 * @memberof DataServiceService
	 */
	getAgedAccountsReceivablesReport(officeId: number): Observable<any> {
		return this.jobApi.agedAccountsReceivablesReport({
			officeId: officeId,
		});
	}

	/**
	 * Get Deposit Report
	 *
	 * @param {number} officeId To retrieve payment report per office
	 * @returns {Observable<any>}
	 * @memberof DataServiceService
	 */
	getDepositReport(officeId: number): Observable<any> {
		return this.jobApi.depositReport({
			officeId: officeId,
		});
	}

	/**
	 * Get Payment Report
	 *
	 * @param {number|null} officeId To retrieve payment report per office
	 * @returns {Observable<any>}
	 * @memberof DataServiceService
	 */
	getPaymentReport(officeId: number = null): Observable<any> {
		return this.jobApi.paymentReport({
			officeId: officeId,
		});
	}

	/**
	 * (Searches for a client by their firstname and lastname)
	 *
	 * @param {string} firstname
	 * @param {string} [lastname=''] - this field is optional
	 * @returns {Observable<any>}
	 * @memberof DataServiceService
	 */
	searchClientByName(
		firstname: string,
		lastname: string = ''
	): Observable<any> {
		const regexFirstname = new RegExp(`^(${firstname})`, 'i');
		const regexLastname = new RegExp(`^(${lastname})`, 'i');
		const limit = 20;

		if (lastname === '') {
			return this.clientApi.find({
				order: 'firstname ASC',
				where: { firstname: { ilike: `%${firstname}%` } },
				limit: limit,
			});
		} else {
			return this.clientApi.find({
				order: 'lastname ASC',
				where: {
					and: [
						{ firstname: { ilike: `%${firstname}%` } },
						{ lastname: { ilike: `%${lastname}%` } },
					],
				},
				limit: limit,
			});
		}
	}

	/**
	 * (Change/swap the client that is referenced on the job)
	 *
	 * @param {any} newClient
	 * @param {any} job
	 * @param {any} [company=null]
	 * @returns {Observable<any>} - A fork join containing the job and client (possibly the company if the id was defined)
	 * @memberof DataServiceService
	 */
	changeClientOnJob(
		newClient: any,
		job: any,
		company: any = null
	): Observable<any> {
		const data = {
			clientid: newClient.id,
			companyid: null,
			billinginfoid: null,
			contactid: null,
		};
		let contactId = null;
		if (company !== null && !_.isEmpty(company.contactinfo)) {
			contactId = _.max(company.contactinfo, (contact) => contact.id).id;
			data.companyid = company.id;
		} else {
			contactId = _.max(newClient.contactinfo, (contact) => contact.id).id;
		}
		data.contactid = contactId;
		data.billinginfoid = contactId;

		return this.jobApi.updateAll({ id: job.id }, data).pipe(
			mergeMap((result: any) => {
				return this.getJobWithDetailsById(job.id);
			})
		);
	}

	/**
	 * (Adds a parcel to an existing job)
	 *
	 * @param {number} parcelId
	 * @param {number} jobId
	 * @returns
	 * @memberof DataServiceService
	 */
	addParcelToJob(parcelId: number, jobId: number): Observable<any> {
		return this.jobApi.findById(jobId).pipe(
			mergeMap((job: any) => {
				return forkJoin([
					this.jobApi.updateAll(
						{ id: jobId },
						{ parcelIds: `[${job.parcelIds.join(',')},${parcelId}]` }
					),
					this.createJobParcels(jobId, [parcelId]),
				]);
			})
		);
	}

	/**
	 * (Swaps the old parcel with a new parcel on a job)
	 *
	 * @param {number} oldParcelId
	 * @param {*} newParcelId
	 * @param {number} jobId
	 * @returns {Observable<any>}
	 * @memberof DataServiceService
	 */
	swapParcelOnJob(
		oldParcelId: number,
		newParcelId,
		jobId: number
	): Observable<any> {
		return this.jobApi.findById(jobId).pipe(
			mergeMap((job: any) => {
				const parcelIds = _.map(job.parcelIds, (parcelId: any) => {
					if (parcelId === oldParcelId) {
						return newParcelId;
					} else {
						return parcelId;
					}
				});
				// TODO: remove updateAll after remove parcelids from job
				return forkJoin([
					this.jobApi.updateAll(
						{ id: jobId },
						{ parcelIds: `[${parcelIds.join()}]` }
					),
					this.jobParcelApi.updateAll(
						{ jobid: jobId, parcelid: oldParcelId },
						{ parcelid: newParcelId }
					),
				]);
			})
		);
	}

	/**
	 * (Gets polygons in a radius around a lat and lon. 1 distance is roughly 67 miles.)
	 *
	 * @param {number} latitude
	 * @param {number} longitude
	 * @param {number} [distance=1]
	 * @param {string} parentLayer
	 * @param {number} [limit]
	 * @param {Array<number>} [excludePolygons]
	 * @returns {Observable<any>}
	 * @memberof DataServiceService
	 */
	getPolygonsInRadius(
		latitude: number,
		longitude: number,
		distance: number = 1,
		parentLayer: string,
		limit?: number
	): Observable<any> {
		if (limit) {
			// console.log('LIMIT');
			return this.polygonApi
				.find({
					where: {
						and: [{ parentlayerType: 'Parcel' }, { parentlayer: parentLayer }],
					},
					order: 'id ASC',
					fields: { id: true },
				})
				.pipe(
					mergeMap((polygons: any) => {
						const min = polygons[0].id;
						const max = polygons[polygons.length - 1].id;

						return this.polygonApi.find({
							where: {
								and: [
									{
										centerlat: { gte: latitude - distance },
										centerlon: { gte: longitude - distance },
									},
									{
										centerlat: { lte: latitude + distance },
										centerlon: { lte: longitude + distance },
									},
									{ id: { gte: min } },
									{ id: { lte: max } },
								],
							},
							limit: limit,
							fields: { geomstring: true },
						});
					})
				);
		} else {
			return this.polygonApi
				.find({
					where: {
						and: [{ parentlayerType: 'Parcel' }, { parentlayer: parentLayer }],
					},
					order: 'id ASC',
					fields: { id: true },
				})
				.pipe(
					mergeMap((polygons: any) => {
						const min = polygons[0].id;
						const max = polygons[polygons.length - 1].id;

						return this.polygonApi.find({
							where: {
								and: [
									{
										centerlat: { gte: latitude - distance },
										centerlon: { gte: longitude - distance },
									},
									{
										centerlat: { lte: latitude + distance },
										centerlon: { lte: longitude + distance },
									},
									{ id: { gte: min } },
									{ id: { lte: max } },
								],
							},
							fields: { geomstring: true },
						});
					})
				);
		}
	}

	/**
	 * (Verifies that the employee exists with the right verification token and id)
	 *
	 * @param {number} id
	 * @param {string} verificationToken
	 * @returns {Observable<any>}
	 * @memberof DataServiceService
	 */
	verifyEmployeeExists(id: number, verificationToken: string): Observable<any> {
		return this.employeeApi.verifyCredentials(id, verificationToken);
	}

	/**
	 * (Reset the employee's verification token)
	 *
	 * @param {number} employeeId
	 * @param {string} verificationToken
	 * @returns
	 * @memberof DataServiceService
	 */
	resetEmployeeVerificationToken(
		employeeId: number,
		verificationToken: string
	) {
		return this.employeeApi.resetVerificationToken({
			id: employeeId,
			verificationToken: verificationToken,
		});
	}

	/**
	 * (Get employee by id)
	 *
	 * @param {*} employeeId
	 * @returns {Observable<any>}
	 * @memberof DataServiceService
	 */
	getEmployeeById(employeeId): Observable<any> {
		return this.employeeApi.findById(employeeId);
	}

	/**
	 * (Update an employees password, this funciton is used with forgot password)
	 *
	 * @param {number} employeeId
	 * @param {string} password
	 * @param {string} verificationToken
	 * @returns
	 * @memberof DataServiceService
	 */
	updateEmployeePassword(
		employeeId: number,
		password: string,
		verificationToken: string
	) {
		return this.employeeApi.updatePassword(
			employeeId,
			verificationToken,
			password
		);
	}

	updateEmployeePreferences(employeeId: number, preferences: any) {
		return this.employeeApi.updateAll(
			{ id: employeeId },
			{ preferences: preferences }
		);
	}

	/**
	 * (Sets the default preferences)
	 *
	 * @returns {void}
	 * @memberof DataServiceService
	 */
	setDefaultPreferences(): void {
		this.defaultPreferences.managerIds = [this.user.id];
		this.defaultPreferences.managingOfficeId = this.user.officeIds[0];
		this.defaultPreferences.defaultJobsLayer = 0;
	}

	/**
	 * (Gets the user's preference or generates a default one)
	 *
	 * @returns {Preferences}
	 * @memberof DataServiceService
	 */
	getDefaultPreferences(): Preferences {
		if (
			!this.user.hasOwnProperty('preferences') ||
			this.user.preferences === null
		) {
			this.setDefaultPreferences();
			this.user.preferences = this.defaultPreferences;
			return this.defaultPreferences;
		} else {
			return this.user.preferences;
		}
	}

	/**
	 * (Search a job by job mask and order it in DESC)
	 *
	 * @param {string} mask
	 * @returns {Observable<any>}
	 * @memberof DataServiceService
	 */
	searchJobsByMask(mask: string): Observable<any> {
		return this.jobApi.find({
			where: { mask: { ilike: `%${mask}%` } },
			order: 'mask DESC',
		});
	}

	getPaymentAndInvoiceJobId(jobId: number) {
		return this.paymentApi.find({
			where: { jobIds: `[${jobId}]` },
			include: ['invoices'],
		});
	}

	/**
	 * (Updating the payment)
	 *
	 * @param {number} paymentId
	 * @param {*} paymentData - form data
	 * @returns
	 * @memberof DataServiceService
	 */
	updatePayment(paymentId: number, paymentData: any) {
		// Filtering out what data to update based on payment type
		if (paymentData.type === 'Check' || paymentData.type === 'Cash') {
			paymentData = _.pick(paymentData, 'made', 'posted', 'comment');
		} else {
			paymentData = _.pick(paymentData, 'posted', 'comment');
			paymentData.made = paymentData.posted; // Making made and posted the same
		}

		return this.paymentApi.updateAll({ id: paymentId }, paymentData).pipe(
			mergeMap((update) => {
				return this.paymentApi.findById(paymentId);
			})
		);
	}

	/**
	 * (Get the data needed to start formatting the client datatable on the client overview page. You can pass
	 * a client id and limit to grab one row, set limit to 1 so the query is faster.)
	 *
	 * @param {number} [id=null]
	 * @param {number} [limit=null]
	 * @returns {Observable<any>}
	 * @memberof DataServiceService
	 */
	getClientTableData(
		id: number = null,
		limit: number = null,
		query: any = null
	): Observable<any> {
		const where: any = {
			fields: {
				firstname: true,
				lastname: true,
				id: true,
				companyIds: true,
				active: true,
			},
			include: ['companies'],
		};
		id ? (where.where = { id: id }) : null;
		limit ? (where.limit = limit) : null;
		query ? (where.where = query) : null;
		return this.clientApi.find(where);
	}

	/**
	 * (Gets the invoice data needed for pdfmake)
	 *
	 * @param {number} jobId
	 * @param {*} additionalData - {notes:, iterationNumber:} if iterationNumber not provided, increment one
	 * @returns {Observable<any>}
	 * @memberof DataServiceService
	 */
	generateInvoicePdfMake(
		jobId: number,
		additionalData: any = null
	): Observable<any> {
		return this.invoiceApi.generateInvoiceData(jobId, additionalData);
	}

	/**
	 * Send newly created invoice file to server to upload to Azure Storage
	 *
	 * @param {*} pdfFile
	 * @returns
	 * @memberof DataServiceService
	 */
	storeInvoice(pdfFile) {
		let formData = new FormData();
		formData.append(`file`, pdfFile, pdfFile.name); // attach file (blob)
		let header = new HttpHeaders();

		header = header.append(
			'Authorization',
			LoopBackConfig.getAuthPrefix() + this.auth.getAccessTokenId()
		);

		return new Promise((resolve, reject) => {
			this.invoiceApi
				.storeInvoiceFile({}, {}, formData, (header) => {
					return this.setMultipartFormHeaders(header);
				})
				.pipe(take(1), retry(3))
				.subscribe(
					(result) => {
						// console.log(result);
						resolve(result);
					},
					(error) => {
						console.log(error);
						reject(error);
					}
				);
		});
	}

	/**
	 * Create a new invoice row in Invoice table
	 * Update the job table after creating new invoice, update itself with stripeinvoiceid (if available)
	 * Chain to generateInvoicePdfMake to generate pdf data
	 * @param {number} jobId
	 * @param {*} additionalData - {notes:, iterationNumber:} if iterationNumber not provided, increment one
	 * @returns {Observable<any>}
	 * @memberof DataServiceService
	 */
	createInvoice(jobId: number, additionalData: any = null): Observable<any> {
		return this.invoiceApi.createInvoice(jobId, additionalData).pipe(
			mergeMap((newInvoiceResponse) => {
				// console.log(newInvoiceResponse);
				return this.generateInvoicePdfMake(jobId, {
					iterationNumber: newInvoiceResponse.iterationnumber,
					notes: newInvoiceResponse.notes,
				});
			})
		);
	}

	/**
	 * (Gets the invoice file by passing it the job id and the invoice iteration number)
	 *
	 * @param {number} jobId
	 * @param {number} iterationNumber
	 * @returns {Observable<any>}
	 * @memberof DataServiceService
	 */
	getInvoiceFile(jobId: number, iterationNumber: number): Observable<any> {
		return this.invoiceApi.getInvoiceLink(jobId, iterationNumber);
	}
	/**
	 * Cancel the job by marking closed to true
	 *
	 * @param {number} jobId
	 * @returns {Observable<any>}
	 * @memberof DataServiceService
	 */
	cancelJob(jobId: number): Observable<any> {
		return this.jobApi.cancelJob(jobId);
	}

	/**
	 * Complete the job, but checks if the job has payments, invoices, and is already paid.
	 * User can bypass these checks by confirming that they want to anyways
	 *
	 * @param {number} jobId
	 * @param {boolean} [updateAnyways=false]
	 * @returns {Observable<any>}
	 * @memberof DataServiceService
	 */
	completeJob(jobId: number, updateAnyways: boolean = false): Observable<any> {
		return this.jobApi.completeJob(jobId, updateAnyways);
	}

	/**
	 * Reopen the job by setting closed to false
	 *
	 * @param {number} jobId
	 * @returns {Observable<any>}
	 * @memberof DataServiceService
	 */
	reopenJob(jobId: number): Observable<any> {
		return this.jobApi.reopenJob(jobId);
	}

	/**
	 * (Updates a jobs billing id but creates a new contact information)
	 *
	 * @param {number} [jobId=null]
	 * @param {*} contactInfo
	 * @returns {Observable<any>}
	 * @memberof DataServiceService
	 */
	updateBillingInfoJob(
		jobId: number = null,
		contactInfo: any
	): Observable<any> {
		// return this.jobApi.find({where: {id: jobId}});
		contactInfo = _.pick(
			contactInfo,
			'contactdetailsType',
			'contactdetailsId',
			'email',
			'line1',
			'line2',
			'phone',
			'fax',
			'city',
			'state',
			'zip'
		);

		if (jobId) {
			return this.contactInfoApi.create(contactInfo).pipe(
				mergeMap((newContactInfo) => {
					console.log(newContactInfo);
					return this.jobApi.updateAll(
						{ id: jobId },
						{ billinginfoid: newContactInfo.id }
					);
				})
			);
		} else {
			return this.contactInfoApi.create(contactInfo);
		}
	}

	/**
	 * (Updates a jobs contact id but creates a new contact information)
	 *
	 * @param {number} [jobId=null]
	 * @param {*} contactInfo
	 * @returns {Observable<any>}
	 * @memberof DataServiceService
	 */
	updateContactInfoJob(
		jobId: number = null,
		contactInfo: any
	): Observable<any> {
		contactInfo = _.pick(
			contactInfo,
			'contactdetailsType',
			'contactdetailsId',
			'email',
			'line1',
			'line2',
			'phone',
			'fax',
			'city',
			'state',
			'zip'
		);

		if (jobId) {
			return this.contactInfoApi.create(contactInfo).pipe(
				mergeMap((newContactInfo) => {
					return this.jobApi.updateAll(
						{ id: jobId },
						{ contactid: newContactInfo.id }
					);
				})
			);
		} else {
			return this.contactInfoApi.create(contactInfo);
		}
	}

	/**
	 * (Creates a new contact information)
	 *
	 * @param {*} contactInfo
	 * @returns {Observable<any>}
	 * @memberof DataServiceService
	 */
	createNewContactInfo(contactInfo: any): Observable<any> {
		return this.contactInfoApi.create(
			_.pick(
				contactInfo,
				'contactdetailsType',
				'contactdetailsId',
				'email',
				'line1',
				'line2',
				'phone',
				'fax',
				'city',
				'state',
				'zip'
			)
		);
	}

	updateJobAttributes(jobId: number, data: any): Observable<any> {
		return this.jobApi.updateAll({ id: jobId }, data);
	}

	/**
	 * (Swaps an exisiting billingId on an existing job)
	 *
	 * @param {number} jobId
	 * @param {*} billingId
	 * @returns {Observable<any>}
	 * @memberof DataServiceService
	 */
	swapBillingInfoIdJob(jobId: number, billingId: any): Observable<any> {
		return this.jobApi.updateAll({ id: jobId }, { billinginfoid: billingId });
	}

	/**
	 * (Swaps an existing contactId on an existing job)
	 *
	 * @param {number} jobId
	 * @param {*} contactId
	 * @returns {Observable<any>}
	 * @memberof DataServiceService
	 */
	swapContactIdJob(jobId: number, contactId: any): Observable<any> {
		return this.jobApi.updateAll({ id: jobId }, { contactid: contactId });
	}

	/**
	 * Get all employees or only regular users (exclude internal users: Admin or Cardinal staffs)
	 *
	 * @param {boolean} [includeInternal=true] False if don't want isInternal = true
	 * @returns {*} List of employees or empty list
	 * @memberof DataServiceService
	 */
	getEmployees(includeInternal = true): any {
		if (this.dataObj.users.list) {
			return includeInternal
				? this.dataObj.users.list
				: _.filter(this.dataObj.users.list, (user) => user.isInternal !== true);
		}
		// console.log(`User list is empty`);
		return [];
	}

	getEsriMapData(): Observable<any> {
		console.log(this.dataObj);
		return this.jobApi
			.find({
				where: { closed: false },
				fields: [
					'id',
					'parcelIds',
					'managingoffice',
					'mask',
					'created',
					'due',
					'completed',
				],
				include: ['parcels', 'office', 'flags', 'operations'],
			})
			.pipe(
				take(1),
				map((activeJobs) => {
					console.log(activeJobs);
					activeJobs = _.map(activeJobs, (job) => {
						job.created = moment(job.created).format(
							'dddd, MMMM Do YYYY, h:mm:ss a'
						);
						job.due = moment(job.due).format('dddd, MMMM Do YYYY, h:mm:ss a');
						job.parcelIdsLabel = job.parcels
							? _.pluck(job.parcels, 'parceltaxid').join(', ')
							: '[Not Provided]';
						job.legalDescription =
							job.parcels && job.parcels[0].legaldescription
								? job.parcels[0].legaldescription
								: '[Not Provided]';
						job.title = `${job.mask} (${job.office.shortname})`;
						job.coordinates = _.map(job.parcels, (parcel) => {
							return [parcel.polygon.centerlat, parcel.polygon.centerlon];
						});
						job.centerCoord =
							job.coordinates.length > 1
								? this.findCenterBetweenPoints(job.coordinates)
								: job.coordinates[0];
						job.officeName = job.office.shortname;
						job.flagsLabel = _.pluck(
							_.pluck(
								_.filter(job.flags, (flag) => flag.active),
								'badgedetails'
							),
							'banner'
						).join(', ');
						job.flagsLabel = job.flagsLabel ? job.flagsLabel : '[Not Provided]';
						job.fullAddress =
							job.parcels && job.parcels[0].legaldescription
								? job.parcels[0].fulladdress
								: '[Not Provided]';
						job.status = job.completed ? 'Inactive' : 'Active';

						// Get fieldwork, draft, billing, paid
						_.map(job.operations, (operation) => {
							operation.stage = _.find(
								this.dataObj.stages.list,
								(stage) => stage.id === operation.stageid
							);
							operation.assignedUser = _.find(
								this.dataObj.users.list,
								(user) => user.id === operation.assigneduser
							);
							operation.assignedUser = operation.assignedUser
								? `${operation.assignedUser.firstname} ${operation.assignedUser.lastname}`
								: '';
							operation.stageName = operation.stage.name;
							operation.status = operation.inprogress
								? 'Started'
								: 'Not Started';
							job[
								operation.stage.name
							] = `${operation.status} (${operation.assignedUser})`;
							return _.pick(operation, 'stageName', 'assignedUser', 'status');
						});
						return job;
					});
					const officeIds = [];
					const jobsByOffice = [];
					_.each(activeJobs, (job) => {
						if (!_.contains(officeIds, job.managingoffice)) {
							officeIds.push(job.managingoffice);
							jobsByOffice.push(new Array(1).fill(job));
						} else {
							jobsByOffice[
								_.findIndex(
									jobsByOffice,
									(jobByOffice) =>
										jobByOffice[0].managingoffice === job.managingoffice
								)
							].push(job);
						}
					});
					console.log(jobsByOffice);
					return jobsByOffice;
				})
			);
	}

	findCenterBetweenPoints(coordinates: Array<Array<number>>) {
		// https://stackoverflow.com/questions/6671183/calculate-the-center-point-of-multiple-latitude-longitude-coordinate-pairs
		let x = 0.0;
		let y = 0.0;
		let z = 0.0;

		for (let i = 0; i < coordinates.length; i++) {
			const lat = (coordinates[i][0] * Math.PI) / 180;
			const lon = (coordinates[i][1] * Math.PI) / 180;

			x += Math.cos(lat) * Math.cos(lon);
			y += Math.cos(lat) * Math.sin(lon);
			z += Math.sin(lat);
		}

		x /= coordinates.length;
		y /= coordinates.length;
		z /= coordinates.length;

		const lon = Math.atan2(y, x);
		const hyp = Math.sqrt(x * x + y * y);
		const lat = Math.atan2(z, hyp);

		return [(lat * 180) / Math.PI, (lon * 180) / Math.PI];
	}

	/**
	 * (Gets jobs based on a search query)
	 *
	 * @param {*} [skip=null]
	 * @param {*} [query=null]
	 * @param {number} [limit=10]
	 * @returns {Observable<any>}
	 * @memberof DataServiceService
	 */
	getAllJobs(
		skip: any = null,
		query: any = null,
		limit: number = 10
	): Observable<any> {
		return this.jobApi.find({
			where: query,
			skip: skip,
			limit: limit,
			include: ['parcels', 'client', 'managers', 'office', 'operations'],
			order: 'id DESC',
		});
	}

	/**
	 * (Allows you to find parcels based on a pre-defined query. Gives you the ability to define the query.)
	 *
	 * @param {*} [query=null] - ex: {where: {id: 5}, fields: ['id'], limit: 25}
	 * @returns {Observable<any>}
	 * @memberof DataServiceService
	 */
	getParcelIds(query: any = null): Observable<any> {
		return this.parcelApi.find(query);
	}

	/**
	 * (Allows you to find employees based on a pre-defined query. Gives you the ability to define the query.)
	 *
	 * @param {*} [query=null] - ex: {where: {id: 5}, fields: ['id'], limit: 25}
	 * @returns {Observable<any>}
	 * @memberof DataServiceService
	 */
	searchEmployees(query: any = null): Observable<any> {
		return this.employeeApi.find(query);
	}

	/* * Use to flexibly update payment table
	 * @param {LoopBackFilter} whereFilter Where statement
	 * @param {*} data Data to be updated
	 * @returns {Observable<any>}
	 * @memberof DataServiceService
	 */
	updatePayments(whereFilter: LoopBackFilter, data): Observable<any> {
		return this.paymentApi.updateAll(whereFilter, data);
	}

	getParcelLayer(layerText: string, offset?: number): Observable<any> {
		return this.parcelApi.find({
			where: { layer: layerText },
			fields: ['id', 'polygonid'],
			limit: 1000,
		});
	}

	/**
	 * (Updates the order number on a service)
	 *
	 * @param {number} id
	 * @param {number} orderNumber
	 * @returns
	 * @memberof DataServiceService
	 */
	updateOrderNumberService(id: number, orderNumber: number) {
		return this.serviceApi.updateAll({ id: id }, { orderNumber: orderNumber });
	}

	/**
	 * (Creates a custom parcel and returns full data so UI can be updated)
	 *
	 * @param {*} parcel
	 * @returns {Observable<any>}
	 * @memberof DataServiceService
	 */
	createCustomParcel(parcel: any): Observable<any> {
		parcel = _.pick(
			parcel,
			'fulladdress',
			'legaldescription',
			'parceltaxid',
			'polygonid',
			'layer'
		);
		parcel.fulladdress = parcel.fulladdress.toUpperCase();
		parcel.legaldescription = parcel.legaldescription.toUpperCase();
		parcel.modifiedorcustom = true;
		parcel.recordid = null;
		return this.parcelApi.create(parcel).pipe(
			mergeMap((newParcel: any) => {
				return this.parcelApi.findById(newParcel.id); // Need to return full data
			})
		);
	}

	/**
	 * (Updates a job's parcel id by replacing the old parcel id with a new one)
	 *
	 * @param {number} parcelId
	 * @param {number} jobId
	 * @param {number} oldParcelId
	 * @returns {Observable<any>}
	 * @memberof DataServiceService
	 */
	updateJobNewParcel(
		parcelId: number,
		jobId: number,
		oldParcelId: number
	): Observable<any> {
		return this.jobApi.findById(jobId).pipe(
			mergeMap((job: any) => {
				const parcelIds = `[${_.map(job.parcelIds, (pId) => {
					if (pId === oldParcelId) {
						return parcelId;
					}
					return pId;
				}).join(', ')}]`;
				// TODO: remove updateAll after remove parcelids from job
				return forkJoin([
					this.jobApi.updateAll({ id: jobId }, { parcelIds: parcelIds }),
					this.jobParcelApi.updateAll(
						{ jobid: jobId, parcelid: oldParcelId },
						{ parcelid: parcelId }
					),
				]);
			})
		);
	}

	/**
	 * Creates a company given a company name
	 *
	 * @param {string} companyName the name of the company to create
	 * @returns {Observable<any>}
	 * @memberof DataServiceService
	 */
	createCompany(companyName: string): Observable<any> {
		return this.companyApi.create({ clientIds: '[]', name: companyName });
	}
	/** Create Job Parcels (used after creating new job)
 *
 * @param {Number} jobId
 * @param {Array<Number>} parcelIds Array of parcelId
 * @returns {Observable<any>} Fork join of jobparcel create
 * @memberof DataServiceService
 */
	createJobParcels(jobId: Number, parcelIds: Array<Number>): Observable<any> {
		let obs$ = [];
		parcelIds.forEach((parcelId) => {
			const ob = this.jobParcelApi.create({ jobid: jobId, parcelid: parcelId });
			obs$.push(ob);
		});
		return forkJoin(obs$);
	}

	/**
	 * Gets the layers that have an esri feature service
	 *
	 * @returns {Observable<any>}
	 * @memberof DataServiceService
	 */
	getLayersWithFeatureService(): Observable<any> {
		return this.layerApi.find({
			where: {
				and: [
					{ featureserviceurl: { neq: null } },
					{ featureserviceurl: { neq: '' } }
				]
			}, fields: ['id', 'featureserviceurl', 'name']
		});
	}

	/**
	 * Get parcel based on where and limit (optional) arguments. Can be used to find existing parcels
	 *
	 * @param {*} where where clause (should not wrap it in {where: {}})
	 * @param {*} [limit=null] optional limit clause
	 * @returns {Observable<any>} from server parcelApi.find
	 * @memberof DataServiceService
	 */
	getParcel(where: any, limit = null): Observable<any> {
		let whereClause = {};
		whereClause['where'] = where;
		if (limit) {
			whereClause['limit'] = limit;
		}
		// console.log(whereClause);
		return this.parcelApi.find(whereClause);
	}

	/**
	 * Create new parcel row. If passing an esri feature as data, please pass the geoJson as well so the polygon can be created
	 *
	 * @param {*} data
	 * @returns {Observable<any>} should return new parcel data
	 * @memberof DataServiceService
	 */
	createParcel(data: any, geoJson?): Observable<any> {
		let newParcel = null;
		let parcelPolygon = null;

		// Esri parcel
		if (geoJson) {
			return this.createPolygonFromGeoJson(data.layer, JSON.stringify(geoJson)).pipe(
				mergeMap(polygon => {
					data.polygonid = polygon[0].id;
					parcelPolygon = polygon[0];
					return this.parcelApi.create(data);
				}),
				mergeMap(parcel => {
					newParcel = parcel;
					newParcel.polygon = parcelPolygon;
					return this.polygonApi.updateAll({ id: parcel.polygonid }, { parentid: parcel.id, parentlayerType: 'Parcel' });
					// return this.polygonApi.findById(parcel.polygonid);
				}),
				map(result => {
					return newParcel;
				})
			);
		} else {
			return this.parcelApi.create(data);
		}
	}

	/**
	 * Update one of more fields from an existing parcel row
	 * This can be used to update polygonid for new parcel
	 * @param {*} dataWithId Parcel object. Parcel id should not be null
	 * @returns {Observable<any>} number of rows updated
	 * @memberof DataServiceService
	 */
	updateParcelAttributes(dataWithId: any): Observable<any> {
		return this.parcelApi.updateAttributes(dataWithId.id, dataWithId);
	}

	/**
	 * Insert new polygon row from geojson string or a layerName
	 *
	 * @param {String} layerName layer name such as Galveston, Chambers, Brazoria (will be converted to upper case before persisting)
	 * @param {*} geoJson geoJSON string or object
	 * @returns {Observable<any>} observable will return new polygon row
	 * @memberof DataServiceService
	 */
	createPolygonFromGeoJson(layerName: String, geoJson: any): Observable<any> {
		const data = {
			parentlayer: layerName.toUpperCase().trim(),
			geomstring: geoJson
		}
		return this.polygonApi.createFromGeoJson(data);
	}

	/**
	 * Create an empty/default Client Contact info if an existing client does not have a contact info object
	 *
	 * @param {number} clientId Client id in client table
	 * @returns {Observable<ContactInfo>} Observable which returns a contactinfo object
	 * @memberof DataServiceService
	 */
	findOrCreateClientContactInfo(clientId: number): Observable<ContactInfo> {
		return this.contactInfoApi
			.find({
				where: {
					and: [
						{ contactdetailsId: clientId },
						{ contactdetailsType: 'Client' }
					]
				},
				limit: 1
			})
			.pipe(
				mergeMap((contactInfoRes: any) => {
					if (contactInfoRes.length === 0) {
						return this.contactInfoApi.create({
							contactdetailsId: clientId,
							contactdetailsType: 'Client',
							email: '',
							phone: '',
							active: false,
							line1: '',
							city: '',
							state: 'TX',
							zip: ''
						});
					} else return of(contactInfoRes);
				})
			);
	}

	searchIntersectingPolygons(geometry: any): Observable<any> {
		return this.polygonApi.searchWithinPolygon(geometry);
	}

	getJobsByParcelId(parcelIds: Array<number>): Observable<any> {
		return this.jobApi.searchArchivedJobsParcelIds(parcelIds);
	}

	/**
	 * Get company logo url from storage account
	 *
	 * @returns {Observable<any>}
	 * @memberof DataServiceService
	 */
	getLogo(): Observable<any> {
		return this.cardinalconfigApi.getLogo();
	}

	/**
	 * Global search bar on job.search method ons erver side. Will return results from the materialized view global_view in the db
	 *
	 * @param {string} input Search value, if contains multiple words, the server side will convert them into array of text
	 * @param {("AND"|"OR")} [operator="AND"] If input has multiple words, this will determine the search logic (default to AND)
	 * @param {*} [limit=null] default to null, may use this for pagination
	 * @param {*} [skip=0] default to 0, may use this for pagination (example: each page returns 10 result, second page = skip 10)
	 * @param {("ASC"|"DESC")} [order="DESC"] order by jobid (newest job first = DESC)
	 * @returns return observable contains array of results (global_view materialized view in pgsql)
	 * @memberof DataServiceService
	 */
	performGlobalSearch(input: string, operator: "AND" | "OR" = "AND", limit: any = null, skip: any = 0, order: "ASC" | "DESC" = "DESC"): Observable<any> {
		return this.jobApi.search(input, operator, limit, skip, order);
	}


	ngOnDestroy(): void {
		this.subscriptions.forEach((subscriptions) => subscriptions.unsubscribe());
	}
}
