import { NgModule, ErrorHandler } from '@angular/core';
import { EnvServiceProvider } from './services/env.service.provider';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, RouteReuseStrategy, Routes } from '@angular/router';

import {
	IonicModule,
	IonicRouteStrategy,
	ToastController,
} from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { SDKBrowserModule } from '../app/shared/sdk/index';

import { NewitemsPopoverComponent } from './components/newitems-popover/newitems-popover.component';
import { TaskpopoverComponent } from './components/taskpopover/taskpopover.component';
import { ClientbillingcardComponent } from './components/clientbillingcard/clientbillingcard.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgxUploaderModule } from 'ngx-uploader';
import { CustomErrorHandler } from './classes/custom-error-handler';
import { Ticket } from './classes/ticket';
import { CustomAlert } from './classes/custom-alert';
import { CardinalToast } from './classes/cardinal-toast';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './store/effects/auth.effects';
import { reducers } from './store/app.states';
import { StoreModule } from '@ngrx/store';
import { CalendarComponent } from './components/calendar/calendar.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { PdfExporter } from './classes/pdf-exporter';
import { SearchTable } from './classes/search-table';
import { JobAdvancedSearch } from './classes/job-advanced-search';
import { loadModules } from 'esri-loader';
import esri = __esri;
import { EsriMap } from './classes/esri-map';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@NgModule({
	declarations: [
		AppComponent,
		NewitemsPopoverComponent,
		TaskpopoverComponent,
		ClientbillingcardComponent,
		CalendarComponent,
	],
	entryComponents: [
		NewitemsPopoverComponent,
	],
	imports: [
		BrowserModule,
		IonicModule.forRoot(),
		AppRoutingModule,
		SDKBrowserModule.forRoot(),
		NgxFileDropModule,
		NgxUploaderModule,
		EffectsModule.forRoot([AuthEffects]),
		StoreModule.forRoot(reducers, {}),
		FullCalendarModule,
		BrowserAnimationsModule,
		NgxDatatableModule,
	],
	providers: [
		StatusBar,
		SplashScreen,
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		EnvServiceProvider,
		{ provide: ErrorHandler, useClass: CustomErrorHandler },
		CustomErrorHandler,
		Ticket,
		CustomAlert,
		ToastController,
		CardinalToast,
		PdfExporter,
		SearchTable,
		JobAdvancedSearch,
		EsriMap
		// WebWorkerService
	],
	bootstrap: [AppComponent],
})
export class AppModule { }
