/* tslint:disable */
import {
  MultiAccessToken,
  ContactInfo,
  BillingInfo,
  Company,
  Job
} from '../index';

declare var Object: any;
export interface ClientInterface {
  "comments"?: string;
  "companyIds"?: Array<any>;
  "tfa"?: boolean;
  "ipaddresses"?: Array<any>;
  "billinginfoid"?: number;
  "active"?: boolean;
  "firstname": string;
  "lastname": string;
  "realm"?: string;
  "username"?: string;
  "email": string;
  "emailVerified"?: boolean;
  "id"?: number;
  "created"?: Date;
  "updated"?: Date;
  "password"?: string;
  accessTokens?: MultiAccessToken[];
  contactinfo?: ContactInfo[];
  billingInfo?: BillingInfo;
  companies?: Company[];
  jobs?: Job[];
}

export class Client implements ClientInterface {
  "comments": string;
  "companyIds": Array<any>;
  "tfa": boolean;
  "ipaddresses": Array<any>;
  "billinginfoid": number;
  "active": boolean;
  "firstname": string;
  "lastname": string;
  "realm": string;
  "username": string;
  "email": string;
  "emailVerified": boolean;
  "id": number;
  "created": Date;
  "updated": Date;
  "password": string;
  accessTokens: MultiAccessToken[];
  contactinfo: ContactInfo[];
  billingInfo: BillingInfo;
  companies: Company[];
  jobs: Job[];
  constructor(data?: ClientInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Client`.
   */
  public static getModelName() {
    return "Client";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Client for dynamic purposes.
  **/
  public static factory(data: ClientInterface): Client{
    return new Client(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Client',
      plural: 'Clients',
      path: 'Clients',
      idName: 'id',
      properties: {
        "comments": {
          name: 'comments',
          type: 'string'
        },
        "companyIds": {
          name: 'companyIds',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "tfa": {
          name: 'tfa',
          type: 'boolean',
          default: false
        },
        "ipaddresses": {
          name: 'ipaddresses',
          type: 'Array&lt;any&gt;'
        },
        "billinginfoid": {
          name: 'billinginfoid',
          type: 'number'
        },
        "active": {
          name: 'active',
          type: 'boolean',
          default: true
        },
        "firstname": {
          name: 'firstname',
          type: 'string'
        },
        "lastname": {
          name: 'lastname',
          type: 'string'
        },
        "realm": {
          name: 'realm',
          type: 'string'
        },
        "username": {
          name: 'username',
          type: 'string'
        },
        "email": {
          name: 'email',
          type: 'string'
        },
        "emailVerified": {
          name: 'emailVerified',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "created": {
          name: 'created',
          type: 'Date'
        },
        "updated": {
          name: 'updated',
          type: 'Date'
        },
        "password": {
          name: 'password',
          type: 'string'
        },
      },
      relations: {
        accessTokens: {
          name: 'accessTokens',
          type: 'MultiAccessToken[]',
          model: 'MultiAccessToken',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'userId'
        },
        contactinfo: {
          name: 'contactinfo',
          type: 'ContactInfo[]',
          model: 'ContactInfo',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'contactdetailsId'
        },
        billingInfo: {
          name: 'billingInfo',
          type: 'BillingInfo',
          model: 'BillingInfo',
          relationType: 'belongsTo',
                  keyFrom: 'billinginfoid',
          keyTo: 'id'
        },
        companies: {
          name: 'companies',
          type: 'Company[]',
          model: 'Company',
          relationType: 'referencesMany',
                  keyFrom: 'companyIds',
          keyTo: 'id'
        },
        jobs: {
          name: 'jobs',
          type: 'Job[]',
          model: 'Job',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'clientid'
        },
      }
    }
  }
}
