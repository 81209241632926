import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate, PreloadAllModules } from '@angular/router';
import { OverviewPageRoutingModule } from "./pages/overview/overview-routing.module"

import { AuthguardService as AuthGuard } from "./services/authguard.service";

const routes: Routes = [
  { path: '', loadChildren: './pages/login/login.module#LoginPageModule' },
  { path: 'overview', loadChildren: './pages/overview/overview.module#OverviewPageModule', canActivate: [AuthGuard], canActivateChild: [AuthGuard] },
  { path: 'create-client', loadChildren: './pages/create-client/create-client.module#CreateClientPageModule', canActivate: [AuthGuard] },
  { path: 'create-job', loadChildren: './pages/create-job/create-job.module#CreateJobPageModule', canActivate: [AuthGuard] },
  { path: 'office-setup', loadChildren: './pages/office-setup/office-setup.module#OfficeSetupPageModule', canActivate: [AuthGuard] },
  { path: 'role-setup', loadChildren: './pages/role-setup/role-setup.module#RoleSetupPageModule', canActivate: [AuthGuard] },
  { path: 'stage-setup', loadChildren: './pages/stage-setup/stage-setup.module#StageSetupPageModule', canActivate: [AuthGuard] },
  { path: 'task-setup', loadChildren: './pages/task-setup/task-setup.module#TaskSetupPageModule', canActivate: [AuthGuard] },
  { path: 'employee-setup', loadChildren: './pages/employee-setup/employee-setup.module#EmployeeSetupPageModule', canActivate: [AuthGuard] },
  { path: 'feedback', loadChildren: './pages/feedback/feedback.module#FeedbackPageModule' },
  { path: 'redirect', loadChildren: './pages/redirect/redirect.module#RedirectPageModule' },
  { path: 'update-password', loadChildren: './pages/update-password/update-password.module#UpdatePasswordPageModule' },
  { path: 'client', loadChildren: './pages/home-client/home-client.module#HomeClientPageModule' },
  { path: 'manage-account', loadChildren: './pages/manage-account/manage-account.module#ManageAccountPageModule', canActivate: [AuthGuard] },
  { path: 'forgot-password', loadChildren: './pages/forgot-password/forgot-password.module#ForgotPasswordPageModule' },
  { path: 'verify', loadChildren: './pages/verify/verify.module#VerifyPageModule' },
  { path: 'create-employee', loadChildren: './pages/create-employee/create-employee.module#CreateEmployeePageModule', canActivate: [AuthGuard] },
  { path: 'edit-office', loadChildren: './pages/edit-office/edit-office.module#EditOfficePageModule', canActivate: [AuthGuard] },
  { path: 'manage-employee', loadChildren: './pages/manage-employee/manage-employee.module#ManageEmployeePageModule', canActivate: [AuthGuard] },
  { path: 'packet-details', loadChildren: './pages/packet-details/packet-details.module#PacketDetailsPageModule', canActivate: [AuthGuard] },
  { path: 'sales-tax-report', loadChildren: './pages/sales-tax-report/sales-tax-report.module#SalesTaxReportPageModule', canActivate: [AuthGuard] },
  { path: 'aged-accounts-receivables-report', loadChildren: './pages/aged-accounts-receivables-report/aged-accounts-receivables-report.module#AgedAccountsReceivablesReportPageModule', canActivate: [AuthGuard] },
  { path: 'edit-preferences', loadChildren: './pages/edit-preferences/edit-preferences.module#EditPreferencesPageModule', canActivate: [AuthGuard] },
  { path: 'payment-log-editor', loadChildren: './pages/payment-log-editor/payment-log-editor.module#PaymentLogEditorPageModule', canActivate: [AuthGuard] },
  { path: 'deposit-report', loadChildren: './pages/deposit-report/deposit-report.module#DepositReportPageModule', canActivate: [AuthGuard] },
  { path: 'payment-report', loadChildren: './pages/payment-report/payment-report.module#PaymentReportPageModule', canActivate: [AuthGuard] },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  // imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
