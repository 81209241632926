/* tslint:disable */

declare var Object: any;
export interface BillingInfoInterface {
  "email"?: string;
  "phone"?: string;
  "line1": string;
  "line2"?: string;
  "city": string;
  "state": string;
  "zip": string;
  "active": boolean;
  "id"?: number;
  "created"?: Date;
  "updated"?: Date;
}

export class BillingInfo implements BillingInfoInterface {
  "email": string;
  "phone": string;
  "line1": string;
  "line2": string;
  "city": string;
  "state": string;
  "zip": string;
  "active": boolean;
  "id": number;
  "created": Date;
  "updated": Date;
  constructor(data?: BillingInfoInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `BillingInfo`.
   */
  public static getModelName() {
    return "BillingInfo";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of BillingInfo for dynamic purposes.
  **/
  public static factory(data: BillingInfoInterface): BillingInfo{
    return new BillingInfo(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'BillingInfo',
      plural: 'BillingInfos',
      path: 'BillingInfos',
      idName: 'id',
      properties: {
        "email": {
          name: 'email',
          type: 'string'
        },
        "phone": {
          name: 'phone',
          type: 'string'
        },
        "line1": {
          name: 'line1',
          type: 'string'
        },
        "line2": {
          name: 'line2',
          type: 'string'
        },
        "city": {
          name: 'city',
          type: 'string'
        },
        "state": {
          name: 'state',
          type: 'string'
        },
        "zip": {
          name: 'zip',
          type: 'string'
        },
        "active": {
          name: 'active',
          type: 'boolean',
          default: true
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "created": {
          name: 'created',
          type: 'Date'
        },
        "updated": {
          name: 'updated',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
