/* tslint:disable */
import {
  MultiAccessToken,
  Office,
  ContactInfo,
  Position,
  Job,
  Operation
} from '../index';

declare var Object: any;
export interface EmployeeInterface {
  "officeIds"?: Array<any>;
  "positionIds"?: Array<any>;
  "managedjobIds"?: Array<any>;
  "tfa"?: boolean;
  "tfacode"?: string;
  "ipaddresses"?: Array<any>;
  "preferences"?: any;
  "isInternal"?: boolean;
  "firstname": string;
  "lastname": string;
  "realm"?: string;
  "username"?: string;
  "email": string;
  "emailVerified"?: boolean;
  "id"?: number;
  "created"?: Date;
  "updated"?: Date;
  "password"?: string;
  accessTokens?: MultiAccessToken[];
  offices?: Office[];
  contactinfo?: ContactInfo[];
  positions?: Position[];
  manages?: Job[];
  working?: Job[];
  assigned?: Operation[];
}

export class Employee implements EmployeeInterface {
  "officeIds": Array<any>;
  "positionIds": Array<any>;
  "managedjobIds": Array<any>;
  "tfa": boolean;
  "tfacode": string;
  "ipaddresses": Array<any>;
  "preferences": any;
  "isInternal": boolean;
  "firstname": string;
  "lastname": string;
  "realm": string;
  "username": string;
  "email": string;
  "emailVerified": boolean;
  "id": number;
  "created": Date;
  "updated": Date;
  "password": string;
  accessTokens: MultiAccessToken[];
  offices: Office[];
  contactinfo: ContactInfo[];
  positions: Position[];
  manages: Job[];
  working: Job[];
  assigned: Operation[];
  constructor(data?: EmployeeInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Employee`.
   */
  public static getModelName() {
    return "Employee";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Employee for dynamic purposes.
  **/
  public static factory(data: EmployeeInterface): Employee{
    return new Employee(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Employee',
      plural: 'Employees',
      path: 'Employees',
      idName: 'id',
      properties: {
        "officeIds": {
          name: 'officeIds',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "positionIds": {
          name: 'positionIds',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "managedjobIds": {
          name: 'managedjobIds',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "tfa": {
          name: 'tfa',
          type: 'boolean',
          default: false
        },
        "tfacode": {
          name: 'tfacode',
          type: 'string'
        },
        "ipaddresses": {
          name: 'ipaddresses',
          type: 'Array&lt;any&gt;'
        },
        "preferences": {
          name: 'preferences',
          type: 'any'
        },
        "isInternal": {
          name: 'isInternal',
          type: 'boolean',
          default: false
        },
        "firstname": {
          name: 'firstname',
          type: 'string'
        },
        "lastname": {
          name: 'lastname',
          type: 'string'
        },
        "realm": {
          name: 'realm',
          type: 'string'
        },
        "username": {
          name: 'username',
          type: 'string'
        },
        "email": {
          name: 'email',
          type: 'string'
        },
        "emailVerified": {
          name: 'emailVerified',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "created": {
          name: 'created',
          type: 'Date'
        },
        "updated": {
          name: 'updated',
          type: 'Date'
        },
        "password": {
          name: 'password',
          type: 'string'
        },
      },
      relations: {
        accessTokens: {
          name: 'accessTokens',
          type: 'MultiAccessToken[]',
          model: 'MultiAccessToken',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'userId'
        },
        offices: {
          name: 'offices',
          type: 'Office[]',
          model: 'Office',
          relationType: 'referencesMany',
                  keyFrom: 'officeIds',
          keyTo: 'id'
        },
        contactinfo: {
          name: 'contactinfo',
          type: 'ContactInfo[]',
          model: 'ContactInfo',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'contactdetailsId'
        },
        positions: {
          name: 'positions',
          type: 'Position[]',
          model: 'Position',
          relationType: 'referencesMany',
                  keyFrom: 'positionIds',
          keyTo: 'id'
        },
        manages: {
          name: 'manages',
          type: 'Job[]',
          model: 'Job',
          relationType: 'referencesMany',
                  keyFrom: 'managedjobIds',
          keyTo: 'id'
        },
        working: {
          name: 'working',
          type: 'Job[]',
          model: 'Job',
          relationType: 'hasMany',
          modelThrough: 'Operation',
          keyThrough: 'jobId',
          keyFrom: 'id',
          keyTo: 'assigneduser'
        },
        assigned: {
          name: 'assigned',
          type: 'Operation[]',
          model: 'Operation',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'assigneduser'
        },
      }
    }
  }
}
