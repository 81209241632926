/* tslint:disable */
import {
  Stage,
  Job,
  Employee
} from '../index';

declare var Object: any;
export interface OperationInterface {
  "id"?: number;
  "jobid": number;
  "stageid": number;
  "parcelid"?: number;
  "assigneduser"?: number;
  "assigned"?: Date;
  "start"?: Date;
  "end"?: Date;
  "inprogress"?: boolean;
  "complete"?: boolean;
  "comment"?: string;
  "previousstagecomplete"?: boolean;
  stagedetails?: Stage;
  job?: Job;
  assigneduserdetails?: Employee;
}

export class Operation implements OperationInterface {
  "id": number;
  "jobid": number;
  "stageid": number;
  "parcelid": number;
  "assigneduser": number;
  "assigned": Date;
  "start": Date;
  "end": Date;
  "inprogress": boolean;
  "complete": boolean;
  "comment": string;
  "previousstagecomplete": boolean;
  stagedetails: Stage;
  job: Job;
  assigneduserdetails: Employee;
  constructor(data?: OperationInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Operation`.
   */
  public static getModelName() {
    return "Operation";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Operation for dynamic purposes.
  **/
  public static factory(data: OperationInterface): Operation{
    return new Operation(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Operation',
      plural: 'Operations',
      path: 'Operations',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'number'
        },
        "jobid": {
          name: 'jobid',
          type: 'number'
        },
        "stageid": {
          name: 'stageid',
          type: 'number'
        },
        "parcelid": {
          name: 'parcelid',
          type: 'number'
        },
        "assigneduser": {
          name: 'assigneduser',
          type: 'number',
          default: 0
        },
        "assigned": {
          name: 'assigned',
          type: 'Date',
          default: new Date(0)
        },
        "start": {
          name: 'start',
          type: 'Date',
          default: new Date(0)
        },
        "end": {
          name: 'end',
          type: 'Date',
          default: new Date(0)
        },
        "inprogress": {
          name: 'inprogress',
          type: 'boolean',
          default: false
        },
        "complete": {
          name: 'complete',
          type: 'boolean',
          default: false
        },
        "comment": {
          name: 'comment',
          type: 'string'
        },
        "previousstagecomplete": {
          name: 'previousstagecomplete',
          type: 'boolean',
          default: false
        },
      },
      relations: {
        stagedetails: {
          name: 'stagedetails',
          type: 'Stage',
          model: 'Stage',
          relationType: 'hasOne',
                  keyFrom: 'stageid',
          keyTo: 'id'
        },
        job: {
          name: 'job',
          type: 'Job',
          model: 'Job',
          relationType: 'belongsTo',
                  keyFrom: 'jobid',
          keyTo: 'id'
        },
        assigneduserdetails: {
          name: 'assigneduserdetails',
          type: 'Employee',
          model: 'Employee',
          relationType: 'belongsTo',
                  keyFrom: 'assigneduser',
          keyTo: 'id'
        },
      }
    }
  }
}
