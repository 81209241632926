/* tslint:disable */

declare var Object: any;
export interface DeliverableInterface {
  "deliverableid"?: number;
  "jobid"?: number;
  "name": string;
  "url"?: string;
  "visible"?: boolean;
  "lockdate"?: Date;
  "downloadenabled"?: boolean;
  "uploadprogress"?: number;
  "id"?: number;
  "createdOn"?: Date;
  "lastModified"?: Date;
}

export class Deliverable implements DeliverableInterface {
  "deliverableid": number;
  "jobid": number;
  "name": string;
  "url": string;
  "visible": boolean;
  "lockdate": Date;
  "downloadenabled": boolean;
  "uploadprogress": number;
  "id": number;
  "createdOn": Date;
  "lastModified": Date;
  constructor(data?: DeliverableInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Deliverable`.
   */
  public static getModelName() {
    return "Deliverable";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Deliverable for dynamic purposes.
  **/
  public static factory(data: DeliverableInterface): Deliverable{
    return new Deliverable(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Deliverable',
      plural: 'Deliverables',
      path: 'Deliverables',
      idName: 'id',
      properties: {
        "deliverableid": {
          name: 'deliverableid',
          type: 'number'
        },
        "jobid": {
          name: 'jobid',
          type: 'number'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "url": {
          name: 'url',
          type: 'string'
        },
        "visible": {
          name: 'visible',
          type: 'boolean',
          default: true
        },
        "lockdate": {
          name: 'lockdate',
          type: 'Date'
        },
        "downloadenabled": {
          name: 'downloadenabled',
          type: 'boolean'
        },
        "uploadprogress": {
          name: 'uploadprogress',
          type: 'number',
          default: 0
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdOn": {
          name: 'createdOn',
          type: 'Date'
        },
        "lastModified": {
          name: 'lastModified',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
