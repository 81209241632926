
import { Injectable } from '@angular/core';
import { EnvService } from '../services/env.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, empty, of } from 'rxjs';
import _ from 'underscore';
import { stringify } from 'flatted';
@Injectable()
export class Ticket {

  static AZURE_TOKEN = 'yonrdknd4nbphcbaqsrj7tot32wmagn3pm433qtvjna2cqzmckhq';
  // login tracker will ignore these accounts for sandbox
  static readonly LOGIN_EXCLUSION = [
    'owner1@email.inc',
    'manager1@email.inc',
    'employee1@email.inc',
    'employee2@email.inc',
    'employee3@email.inc'];

  protected static apiVersion = '5.0';
  protected static apiBaseUrl = 'https://dev.azure.com/Conrad-Blucher-Institute/CardinalSupport/_apis/wit/workitems/';
  protected httpOptions: any;

  private title: string;
  private description: string;
  private userInfo: any;
  private error: any;
  private payload: any;

  constructor(
    private env: EnvService,
    private http: HttpClient
  ) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json-patch+json',
        'Authorization': 'Basic ' + btoa('' + ':' + Ticket.AZURE_TOKEN),
        'Access-Control-Allow-Origin': '*'
      })
    };
  }

  /**
 * Flatten nested Javascript Object
 *
 * @static
 * @param {*} obj
 * @returns
 * @memberof Ticket
 */
  static flattenObject(obj) {
    const flattened = {};
    Object.keys(obj).forEach((key) => {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        Object.assign(flattened, Ticket.flattenObject(obj[key]));
      } else {
        flattened[key] = obj[key];
      }
    });
    return flattened;
  }

  public setTitle(title: string) {
    this.title = title;
  }

  public getTitle() {
    return this.title;
  }

  public setDescription(description: string) {
    this.description = description;
  }

  public getDescription() {
    return this.description;
  }

  public setError(error: any) {
    this.error = error;
  }
  public getError() {
    return this.error;
  }

  public setUserInfo(userInfo: any = null) {
    if (_.isObject(userInfo)) {
      this.userInfo = _.pick(userInfo, 'id', 'firstname', 'lastname', 'email');
    } else {
      this.userInfo = userInfo;
    }
  }

  public getUserInfo() {
    return this.userInfo;
  }

  // payload needs to be different than any: array or object
  public setPayload(payload: any) {
    this.payload = payload;
  }
  public getPayLoad() {
    return this.payload;
  }

  public getHttpOptions() {
    return this.httpOptions;
  }

  public urlBuilder() {
    return Ticket.apiBaseUrl + '?api-version=' + Ticket.apiVersion + '&type=Issue';
  }
  /**
   * This functions sends error message to Azure DevOps Customer Support board
   *
   * @returns {Observable<any>}
   * @memberof Ticket
   */
  public sendPostRequest(): Observable<any> {
    const errorObj = this.getError() ? stringify(this.getError()) : 'No error message provided';
    const desc = this.getDescription() ? this.getDescription() : 'No description provided.';
    const userInfo = this.getUserInfo() ? JSON.stringify(Ticket.flattenObject(this.getUserInfo())) : 'No user info provided.';
    const additionalInfo = this.getPayLoad() ? stringify(Ticket.flattenObject(this.getPayLoad())) : 'No additional data provided';

    const body = [
      {
        'op': 'add',
        'path': '/fields/System.Title',
        'rev': 1,
        'from': null,
        'value': this.getTitle() ? this.getTitle() : 'User Error Report: Unknown Title',
      },
      {
        'op': 'add',
        'path': '/fields/System.Description',
        'rev': 1,
        'from': null,
        'value': '<b>'
          + desc +
          '</b><div><h3>Debugging info(s): </h3><div><h4>Error Object</h4>' + errorObj +
          '</div><div><h4>User Info</h4>' + userInfo +
          '</div><div><h4>Additional Info(s)</h4>' + additionalInfo + '</div></div>',
      },

    ];
    if (this.env.NODE_ENV === 'production') {
      return this.http.post(this.urlBuilder(), body, this.getHttpOptions());
    } else {
      console.log('Ticket will not be sent on development mode');
      return of(null);
    }
  }


  /**
   * This will send ticket to customer support AzureDevOps board when a demo user logins (internal testing users excluded)
   * @param {string} username
   * @returns {Observable<any>}
   * @memberof Ticket
   */
  public sendLoginInfo(username: string): Observable<any> {

    if (_.contains(Ticket.LOGIN_EXCLUSION, username) === true) {
      return new Observable<null>();
    }
    const body = [
      {
        'op': 'add',
        'path': '/fields/System.Title',
        'rev': 1,
        'from': null,
        'value': 'User Login: ' + username,
      },
      {
        'op': 'add',
        'path': '/fields/System.Description',
        'rev': 1,
        'from': null,
        'value': '',
      },
    ];
    return this.http.post(this.urlBuilder(), body, this.getHttpOptions());
  }
}
