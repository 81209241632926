/* tslint:disable */
import {
  Job,
  Parcel
} from '../index';

declare var Object: any;
export interface JobParcelInterface {
  "id"?: number;
  "jobid": number;
  "parcelid": number;
  "fulladdress"?: string;
  "parceltaxid"?: string;
  "created"?: Date;
  "updated"?: Date;
  job?: Job;
  parcel?: Parcel;
}

export class JobParcel implements JobParcelInterface {
  "id": number;
  "jobid": number;
  "parcelid": number;
  "fulladdress": string;
  "parceltaxid": string;
  "created": Date;
  "updated": Date;
  job: Job;
  parcel: Parcel;
  constructor(data?: JobParcelInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `JobParcel`.
   */
  public static getModelName() {
    return "JobParcel";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of JobParcel for dynamic purposes.
  **/
  public static factory(data: JobParcelInterface): JobParcel{
    return new JobParcel(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'JobParcel',
      plural: 'JobParcels',
      path: 'JobParcels',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'number'
        },
        "jobid": {
          name: 'jobid',
          type: 'number'
        },
        "parcelid": {
          name: 'parcelid',
          type: 'number'
        },
        "fulladdress": {
          name: 'fulladdress',
          type: 'string'
        },
        "parceltaxid": {
          name: 'parceltaxid',
          type: 'string'
        },
        "created": {
          name: 'created',
          type: 'Date'
        },
        "updated": {
          name: 'updated',
          type: 'Date'
        },
      },
      relations: {
        job: {
          name: 'job',
          type: 'Job',
          model: 'Job',
          relationType: 'belongsTo',
                  keyFrom: 'jobid',
          keyTo: 'id'
        },
        parcel: {
          name: 'parcel',
          type: 'Parcel',
          model: 'Parcel',
          relationType: 'belongsTo',
                  keyFrom: 'parcelid',
          keyTo: 'id'
        },
      }
    }
  }
}
