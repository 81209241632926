/* tslint:disable */

declare var Object: any;
export interface PermissionsInterface {
  "id": number;
  "banner": string;
  "key": string;
  "validroles": Array<any>;
}

export class Permissions implements PermissionsInterface {
  "id": number;
  "banner": string;
  "key": string;
  "validroles": Array<any>;
  constructor(data?: PermissionsInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Permissions`.
   */
  public static getModelName() {
    return "Permissions";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Permissions for dynamic purposes.
  **/
  public static factory(data: PermissionsInterface): Permissions{
    return new Permissions(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Permissions',
      plural: 'Permissions',
      path: 'Permissions',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'number'
        },
        "banner": {
          name: 'banner',
          type: 'string'
        },
        "key": {
          name: 'key',
          type: 'string'
        },
        "validroles": {
          name: 'validroles',
          type: 'Array&lt;any&gt;'
        },
      },
      relations: {
      }
    }
  }
}
